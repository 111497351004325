<div class="selector-container">

    <!-- Min field -->
    <mat-form-field>
        <span
            *ngIf="opts.showCurrencyChar"
            matPrefix
            class="field-prefix-char">
            {{currencyChar}} &nbsp;
        </span>
        <input
            matInput
            type="text"
            placeholder="Min"
            name="min-input"
            (input)="transform($event)"
            [formControl]="minControl" />
        <span
            *ngIf="opts.showCurrencyCode"
            matSuffix
            class="field-suffix-code">
            {{currencyCode}}
        </span>
        <button
            *ngIf="opts.showClearButton && (minControl.value || minControl.value === 0)"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            class="field-suffix-clear"
            [class.abs-pos-clear-button]="opts.absPosClearButton"
            (click)="clearMin(opts.applyOnClear)">
            <fa-icon [icon]="['far','times-circle']"></fa-icon>
        </button>
    </mat-form-field>

    <!-- Max field-->
    <mat-form-field>
        <span
            *ngIf="opts.showCurrencyChar"
            matPrefix
            class="field-prefix-char">
            {{currencyChar}} &nbsp;
        </span>
        <input
            matInput
            type="text"
            placeholder="Max"
            name="max-input"
            (input)="transform($event)"
            [formControl]="maxControl" />
        <span
            *ngIf="opts.showCurrencyCode"
            matSuffix
            class="field-suffix-code">
            {{currencyCode}}
        </span>
        <button
            *ngIf="opts.showClearButton && (maxControl.value || maxControl.value === 0)"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            class="field-suffix-clear"
            [class.abs-pos-clear-button]="opts.absPosClearButton"
            (click)="clearMax(opts.applyOnClear)">
            <fa-icon [icon]="['far','times-circle']"></fa-icon>
        </button>
    </mat-form-field>

    <div 
        *ngIf="errMessage"
        class="error-message">
        <fa-icon [icon]="['fas','exclamation-triangle']"></fa-icon>
        {{errMessage}}
    </div>

    <!-- Controls -->
    <div class="filter-actions-container">
        <button (click)="onApply()"
            mat-flat-button
            class="is-color-primary apply-filter-btn">
            APPLY
        </button>
    </div>
</div>
