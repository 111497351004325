import { Injectable } from '@angular/core'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private location: any | boolean
    private locationSubject = new BehaviorSubject<any | boolean>(undefined)
    get location$(): Observable<any | boolean> {
        return this.locationSubject.asObservable().pipe(filter(loc => loc !== undefined))
    }
    get(): any {
        if ( !this.location ) {
            return from(
                new Promise( ( resolve ) => {
                    window.navigator?.geolocation?.
                        getCurrentPosition(
                            location => {
                                this.location = location
                                this.locationSubject.next(location)
                                resolve( location )
                            },
                            () => resolve( true )
                        )
            }))
        }
        return this.location
    }
}
