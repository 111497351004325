import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { environment } from './../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class DevGuard  {

    constructor() {}

    canActivate(route: ActivatedRouteSnapshot) {
        return !environment.production
    }
}
