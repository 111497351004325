import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core'
import { Subject } from 'rxjs'
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { IModalConfiguration } from 'src/app/interfaces/components/component-modal.interface'
import { ModalService } from 'src/app/services/modal.service'
import { takeUntil } from 'rxjs/operators'
import { DomSanitizer } from '@angular/platform-browser'

export const ModalComponentDefaults: IModalConfiguration = {
    type: 'modal',
    display: true,
    modalId: 'new-modal',
    content: [],
    includeCloseButton: true,
    styles: {}
}

export const ModalComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ModalComponentDefaults,
    }
}

@Component({
    selector: 'ras-modal',
    styleUrls: ['./modal.component.scss'],
    templateUrl: './modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnDestroy {

    private $destroy = new Subject<void>()
    private params = {}

    @Input() configuration: IModalConfiguration
    content: [] = []

    active: boolean = false

    constructor(
        private ref: ChangeDetectorRef,
        private elRef: ElementRef,
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
        })

        this.modalService.$data.pipe(
            takeUntil(this.$destroy)
        ).subscribe((data) => {
            const modalData = data.find(d => d.modalId === this.configuration.modalId)
            if (!modalData) {
                return
            }
            this.content = modalData.content
            this.params = modalData.params
            this.active = true
            if (!!modalData.styles) {
                this.themeService.clearStylesOnElement(elRef)
                this.themeService.setStylesOnElement(modalData.styles, this.elRef)
            }
            this.ref.markForCheck()
        })
    }

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, ModalComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    modalBackgroundClicked(): void {
        this.closeModal()
    }

    modalContentClicked(event: Event): void {
        event.stopPropagation()
    }

    closeModal(): void {
        if (this.configuration.includeCloseButton) {
            if (confirm('Are you sure you want to close this modal?')) {
                this.active = false
            }
        } else {
            this.active = false
        }
    }

    prepHref(href: string): string {
        let str = href
        Object.entries(this.params).forEach(([key, value]: [string, string]) => {
            str = str.replace(`{{params.${key}}}`, value)
        })
        return str
    }

    getSanitizedUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }
}
