<ng-template #itemsTemplate let-items>
    <div
        *ngFor="let item of items"
        class="menu-builder__container">
        <div class="menu-builder__button">
            <!-- Remove -->
            <button
                (click)="processRemove(item.toolId)"
                class="menu-builder__remove-button"
                [attr.attr-toolId]="item.toolId">
                <i class="fa fa-remove"></i>
            </button>
        </div>
        <div class="menu-builder__reorder">
            <!-- Move Up -->
            <button
                (click)="processMoveUp(item.toolId)"
                class="menu-builder__up-button"
                [attr.attr-toolId]="item.toolId">
                <i class="fa fa-arrow-up"></i>
            </button>
            <!-- Move Down -->
            <button
                (click)="processMoveDown(item.toolId)"
                class="menu-builder__down-button"
                [attr.attr-toolId]="item.toolId">
                <i class="fa fa-arrow-down"></i>
            </button>
        </div>
        <div class="menu-builder__inputs">
            <!-- Type -->
            <select
                (change)="processTypeChange($event)"
                class="menu-builder__type"
                [attr.attr-toolId]="item.toolId">
                <option
                    *ngFor="let opt of menuItemTypes"
                    [value]="opt.value"
                    [selected]="opt.value === item.type">{{opt.display}}</option>
            </select>
            <!-- Content -->
            <input
                type="text"
                (change)="processDataChange($event, 'content')"
                [value]="item.content"
                placeholder="Content"
                class="menu-builder__content"
                [attr.attr-toolId]="item.toolId" />
            <!-- Router Link -->
            <input
                *ngIf="item.type === 'a-ng'"
                (change)="processDataChange($event, 'routerLink')"
                type="text"
                [value]="item.routerLink"
                placeholder="Internal Link (e.g. /home)"
                class="menu-builder__router-link"
                [attr.attr-toolId]="item.toolId" />
            <!-- Href -->
            <input
                *ngIf="item.type === 'a'"
                (change)="processDataChange($event, 'href')"
                type="text"
                [value]="item.href"
                placeholder="External Link (e.g. http://google.com)"
                class="menu-builder__external-link"
                [attr.attr-toolId]="item.toolId" />
            <!-- Children -->
            <div
                *ngIf="item.type === 'a-group'"
                class="menu-builder__children">
                <ng-container *ngTemplateOutlet="itemsTemplate; context: { $implicit: item.children }"></ng-container>
            </div>
        </div>
    </div>
    <!-- Add -->
    <div class="menu-builder__container">
        <div class="menu-builder__button">
            <button
                (click)="processAdd(items)"
                class="menu-builder__add-button">
                <i class="fa fa-plus"></i>
            </button>
        </div>
    </div>
</ng-template>

<div class="menu-builder">
    <ng-container *ngTemplateOutlet="itemsTemplate; context: { $implicit: navItems }"></ng-container>
</div>
