import { Pipe, PipeTransform } from '@angular/core'
import uniq from 'lodash-es/get'
import { UtilityService } from '../services/utility.service'

@Pipe({
    name: 'rasSearchHighlight',
})
export class RasSearchHighlightPipe implements PipeTransform {

    transform(searchText: string, option: string): string {
        if ( UtilityService.isNullBlankOrUndefined( searchText )) {
            return searchText
        }
        if ( option === null ) {
            return option
        }

        const trimmedText = searchText.replace(/\s+$/, '')
        const optionWords = option.replace(/\s+$/, '').split(' ')

        const replaceWords = []
        optionWords.forEach(ow => {
            ow = ow.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
            const searchRegEx: RegExp = new RegExp(`${ow}`, 'gi')
            // @ts-ignore
            replaceWords.push(...uniq(trimmedText.match(searchRegEx)))
        })
        // for(const r of replaceWords) {
        //     replaceWords[r] = replaceWords[r].replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
        // }
        const mappedReplaceWords = replaceWords.map(rw => rw.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'))
        const replaceRegEx = new RegExp(mappedReplaceWords.join('|'), 'g')
        return trimmedText.replace(replaceRegEx, (x) => `<span class="search-highlight">${x}</span>`)
    }
}
