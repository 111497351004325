
<p class="filter-range-title" [class.is-loading]="isLoading | async">
    <span>{{ ( minValueFinancingSelected || minValueFinancing) | rasCurrency : {} : false : true }}</span>
    -
    <span>
        {{ ( maxValueFinancingSelected || maxValueFinancing ) * 1 | rasCurrency : {} : false : true }}
        <i *ngIf="maxValueFinancingSelected === configuration.financingMaxValue">+</i>
    </span>
</p>

<ras-filter-range-slider [selectedMax]="maxValueFinancingSelected"
                            [selectedMin]="minValueFinancingSelected"
                            [max]="maxValueFinancing"
                            [min]="minValueFinancing"
                            [increment]="configuration.financingIncrement"
                            (change)="onChangeFinancing( $event )">
</ras-filter-range-slider>

<mat-checkbox color="primary" [(ngModel)]="isFinancing" (ngModelChange)="onChangeFinancingToggle( $event )">
    Financing Available
</mat-checkbox>


