<div
    #headerTop
    *ngIf="configuration.includeHeaderTop"
    class="header-top">
    <ul class="header-top-items">
        <li *ngIf="configuration.email?.href && configuration.email?.content">
            <a href="mailto:{{configuration.email.href}}">
                <fa-icon [icon]="['far','envelope']"></fa-icon>
                {{configuration.email.content}}
            </a>
        </li>
        <li *ngIf="configuration.phone?.href && configuration.phone?.content">
            <a href="tel:{{configuration.phone.href}}">
                <fa-icon [icon]="['fas','phone']"></fa-icon>
                {{configuration.phone.content}}
            </a>
        </li>
    </ul>
</div>
<div
    #headerMain
    class="header-main">
    <div 
        *ngIf="configuration.logo?.src"
        class="header-logo">
        <a
            *ngIf="!!configuration.logo.href"
            href="{{configuration.logo.href}}">
            <img
                src="{{configuration.logo.src}}"
                alt="{{configuration.logo.alt}}" />
        </a>
        <img
            *ngIf="!configuration.logo.href"
            src="{{configuration.logo.src}}"
            alt="{{configuration.logo.alt}}" />
    </div>
    <ul
        *ngIf="checkIncludeNavItemSection('main')"
        class="header-nav-items">
        <li
            *ngFor="let item of configuration.navItems">
            <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
        </li>
    </ul>
    <ul
        *ngIf="checkIncludeExtraNavSection()"
        class="header-extra-nav-items">
        <li
            *ngFor="let item of configuration.extraNavItems">
            <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
        </li>
    </ul>
    <ul
        class="header-extra">
        <li
            *ngIf="configuration.includeCurrencySelector">
            <ras-currency-selector-v2
                [configuration]="configuration.childConfigs?.currency_selector">
            </ras-currency-selector-v2>
        </li>
        <li class="burger-menu-li">
            <ras-burger-menu
                [configuration]="{
                    menuSelector: '.header-mobile'
                }">
            </ras-burger-menu>
        </li>
    </ul>
</div>
<div
    #headerBottom
    class="header-bottom">
    <ul
        *ngIf="checkIncludeNavItemSection('bottom')"
        class="header-nav-items">
        <li
            *ngFor="let item of configuration.navItems">
            <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
        </li>
    </ul>
</div>
<div
    #headerMobile
    class="header-mobile">
    <ul
        *ngIf="configuration.navItems?.length > 0"
        class="header-mobile-items">
        <li *ngFor="let item of configuration.navItems">
            <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
        </li>
        <li
            *ngFor="let item of configuration.extraNavItems">
            <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
        </li>
    </ul>
</div>

<ng-template #navItem let-item>
    <ng-container
        [ngSwitch]="item.type">
        <a 
            *ngSwitchCase="'a'"
            class="header-nav-item"
            href="{{item.href}}">
            {{item.content}}
        </a>
        <a 
            *ngSwitchCase="'a-ng'"
            class="header-nav-item"
            [routerLink]="item.routerLink">
            {{item.content}}
        </a>
        <div
            *ngSwitchCase="'a-group'"
            (mouseenter)="mouseEnterGroup(item)"
            (mouseleave)="mouseLeaveGroup(item)"
            class="header-nav-item header-nav-group">
            {{item.content}}
            <div
                class="header-nav-group-items"
                [class.hide]="checkHideGroupItem(item)">
                <ng-container
                    *ngFor="let nestedItem of item.children"
                    class="header-nav-group-item">
                    <ng-container *ngTemplateOutlet="navItem; context: { $implicit: nestedItem }"></ng-container>
                </ng-container>
            </div>
        </div>
        <span
            *ngSwitchDefault
            class="header-nav-item">
            {{item.content}}
        </span>
    </ng-container>
</ng-template>
