import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import set from 'lodash-es/set'

@Component({
    selector: 'tool-boolean-selector',
    templateUrl: './boolean-selector.html',
    styleUrls: ['./boolean-selector.scss', '../control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanSelectorToolComponent implements OnDestroy {
    private $destroy = new Subject<void>()

    @Input() configPath: string
    @Input() key: string
    @Input() label: string
    @Input() value: boolean
    @Input() options: any

    get fullConfigPath(): string {
        return `${this.configPath}.${this.key}`
    }

    constructor(
        private configurationService: ConfigurationService,
    ) {}

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    toggleBoolean(event: any): void {
        const value = event.target.checked
        set(this.configurationService.configuration, this.fullConfigPath, value)
        this.configurationService.$configuration.next(this.configurationService.configuration)
    }
}
