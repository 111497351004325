import { ActivatedRoute, Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, publishReplay, refCount, switchMap, takeUntil, tap } from 'rxjs/operators'
import isEmpty from 'lodash-es/isEmpty'

import { QueryService } from 'src/app/services/query.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { LocationService } from 'src/app/services/location.service'
import { SeoService } from './seo.service'
import { UtilityService } from './utility.service'

import { environment } from 'src/environments/environment'

interface IDataRequestOptions {
    dataGroup: string
    endpointMethod: string
    defaultData: any
    redirectOnError: string
    redirectOnEmpty: string
}

@Injectable({ providedIn: 'root' })
export class SearchService {
    private DEBOUNCE_TIME = 400
    // For components to retrieve data, they need to subscribe to a common data store, so they all retrieve from it and can handle concurrency
    // If no data store is set, we will be making N number of backend requests per component that needs data
    // New data is retrieved by a key, and each key points to a Subject that is then populated after a successfull call
    private data: Record<string, BehaviorSubject<any>> = {}
    // Same as the data, we need to be able to subscribe to loading events by data type request
    // Otherwise we don't know which data is being loaded
    private loading: Record<string, Subject<any>> = {}
    /**
     * Acts as the belt for when data has changed
     * Every time a new data was added, the belt rings
     * Every time the belt rings, the data state is puleed back to the data state by key (this.data[key])
     */
    // TODO: make the pull data unique by data group, so not all the data is being pulled at one single change
    private pullData = new Subject<void>()
    // Global loading, used to know when there is something currently loading
    public isLoadingGlobal =  new BehaviorSubject( false )
    // Acts a record holder, if a data group key is added, then it means data will be pulled for that specific
    // TODO: change it to an object with a flag that holds if any new change was requested
    private dataGroupRequested: any = {}
    // Some data groups might needs extra params to be used in the endpoint url that are dynamic
    // Any component can register any parameter and build a custom param to request
    private endpointParams: Record<string, Record<string, string>> = {}

    constructor( private http: HttpClient,
                 private queryService: QueryService,
                 private configurationService: ConfigurationService,
                 private locationService: LocationService,
                 private route: ActivatedRoute,
                 private seo: SeoService,
                 private router: Router ) {
        this.defaultDataGroupState()

        // The search services subscribes to the query params changes and sets the data
        this.route.queryParams
            .pipe(
                debounceTime( this.DEBOUNCE_TIME ),
                distinctUntilChanged()
            )
            .subscribe( params => {
                this.queryService.replaceFromParams(params)
                this.pullData.next()
            })

        // Main event to trigger the API request
        // If any component needs data trigger it gets triggered
        // If a query params has changed, it gets triggered
        this.pullData
            .pipe(
                debounceTime( this.DEBOUNCE_TIME ),
            )
            .subscribe(() => {
                let count = 0
                Object.keys(this.dataGroupRequested).forEach( key => {
                    count++
                    this.getDataGroup( key, count === Object.keys(this.dataGroupRequested).length ).subscribe()
                })
            })
    }

    private getDataGroup( dataGroup: string, updateSeo: boolean = false ): Observable<any> {
        // Trigger the loadings
        this.loading[ dataGroup ].next( true )
        this.loading[ dataGroup + ':filters' ].next( true )
        this.isLoadingGlobal.next( true )

        // Get's the pagination for the dataGroup if it is available
        const pagination = this.queryService.getParamsGroupPagination( dataGroup )
        // Get's the filters
        const filters = this.queryService.getParamsAsJsonLogicForFilters( dataGroup )
        // Get the endpoint fields if they are set
        const fields = this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.endpointFields`, [] )
        // Create asset listing body request
        const body = { filters: filters.filters, pagination, fields }
        // Set's the default endpoint as the dataGroup or, if it is available a global data_group configuration use it
        const endpointMethod = this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.method`, 'POST' )
        const defaultData = this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.default_data`, {} )
        const queryParams = this.getQueryParams( this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.query_params`, {} ) )
        const queryParamsFilter = this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.query_params_filter`, {} )
        // Set's the latitude or longitude for the
        if (this.queryService.hasParam('equipment', 'postal_code')){
            queryParams.postal_code = this.queryService.getParamValue('equipment', 'postal_code')
            if( this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.use_location_filters`, false ) ) {
                queryParamsFilter.postal_code = queryParams.postal_code
            }
        } else {
            if( this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.use_location`, false ) ) {
                const location = this.locationService.get()
                if( location.coords ) {
                    queryParams.lat = location.coords.latitude
                    queryParams.long = location.coords.longitude
                }
            }
            if( this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.use_location_filters`, false ) ) {
                const location = this.locationService.get()
                if( location.coords ) {
                    queryParamsFilter.lat = location.coords.latitude
                    queryParamsFilter.long = location.coords.longitude
                }
            }
        }

        // Building the endpoint with proper query params
        const queryParamsFlatten = Object.keys( queryParams ).map( key => `${ key }=${ queryParams[ key ] }`).join( '&' )
        const queryParamsFilterFlatten = Object.keys( queryParamsFilter ).map( key => `${ key }=${ queryParamsFilter[ key ] }`).join( '&' )
        const endpoint = this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.endpoint`, dataGroup ) + `?${ queryParamsFlatten }`
        const endpointFilters =  this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.endpointFilters`, dataGroup ) + `?${ queryParamsFilterFlatten }`
        const redirectOnError =  this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.redirect_on_error`, '' )
        const redirectOnEmpty =  this.configurationService.getConfig( `filter.data_groups.${ dataGroup }.redirect_on_empty`, '' )

        // There are different types of request, but we still need to keep an unified way to acces them
        // so any component can subscribe to the data and we can still handle concurrency
        // GET or POST request, POST request with filter and without filters
        // { request } serves us as the request composition, basically we are composing data
        const request = new BehaviorSubject([])
        // If fields are available then there we make the filter request to the endpoint
        if ( filters.fields.length ) {
            request.next([
                this.getFilterData( dataGroup, filters, endpointFilters ).pipe( catchError( () => of( null ) ) )
            ])
        }
        // Adds the data group request, the endpoint method retrieves the correct Observable
        request.next([
            ...request.getValue(),
            this.getData(  endpoint, body, { endpointMethod, defaultData, dataGroup, redirectOnError, redirectOnEmpty  } ).pipe( catchError( () => of( null ) ) )
        ])

        return request.pipe(
            switchMap( arr => merge(...arr)),
            tap( () => {
                if (updateSeo) {
                    this.seo.update( this.router.url, this.data )
                }
            }),
            publishReplay( 1000, 1 ),
            refCount(),
            debounceTime( this.DEBOUNCE_TIME ),
            distinctUntilChanged(),
            takeUntil( this.pullData )
        )
    }

    /**
     * Retrieves an observable of the data, so anytime the data changes, it notify
     * @param keyToRequest key string to request the data
     * @param urlParameter params to be added on the endpoint request, will replace the key for the value
     * @returns the Subject pointing to the specific data that was requested
     */
    requestData( keyToRequest: string ) {
        // In case there is no configuration set , we need to notify, at this point, endpoint configuration is mandatory
        if ( !this.data[ keyToRequest ] ) {
            throw new Error(`Filter configuration missing, make sure you add ${ keyToRequest } configuration under the configuration {filter.${ keyToRequest }}`)
        }

        if (typeof performance !== 'undefined') {
            this.dataGroupRequested[ keyToRequest ] = { requestedAt: performance.now() }
        }

        this.pullData.next()

        return this.data[ keyToRequest ]
            .pipe(
                filter(data => !!data )
            )
    }

    /**
     * Sets the value for a specific endpoint within a data group
     * @param keyToRequest key string to request the data
     * @param urlParameter params to be added on the endpoint request, will replace the key for the value
     */
    setEndpointParam( dataGroup: string, urlParameter: { key: string, value: string } = null ) {
        if ( urlParameter ) {
            this.endpointParams[ dataGroup ] = { [ urlParameter.key ]: urlParameter.value }
        }
    }

    /**
     * Retrieves the Subject to subscribe when a specific data group is loading data
     * @param keyToRequest key string of the data group
     */
    isLoading( keyToRequest: string ) {
        return this.loading[ keyToRequest ]
    }

    triggerData() {
        this.pullData.next()
    }
    /**
     * Set's the state to the initial one
     * @example ng-onDestroy of each main module page
     */
    unsubscribeData() {
        this.defaultDataGroupState()
    }

    search( term: string, limit: number ) {
        return this.http.get( `${ environment.data.api }search/?term=${ encodeURIComponent(term) }&limit=${ limit }`, {
            headers: {
                'trace-id': UtilityService.getUUID(`cat.******-******-******.search`),
            }
        } )
    }

    private getFilterData( dataGroup: string, filters: any = { count: false, fields: [] , filters: {} }, endpoint: string ): Observable<any> {
        return this.http.post(`${ environment.data.api }${ endpoint }`, filters, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.filters`)}})
            .pipe(
                tap( ( data: any)  => {
                    this.queryService.paramsData.next( data )
                    this.loading[ dataGroup + ':filters' ].next( false )
                }),
            )
    }

    private getData( endpoint: string, body: any,  options: IDataRequestOptions  ) {
        // If Url params were registered before, it means we need to compose the URL with this URLS params
        // The endpoint if it was properly configured on the configuration should be a template literal
        // Any expression within the template ({endpoint}) should be replaced by the value in { urlParams }
        if ( this.endpointParams[ options.dataGroup ] ) {
            endpoint = this.replaceUrlParams( endpoint, this.endpointParams[ options.dataGroup ] )
        }

        switch ( options.endpointMethod ) {
            case 'POST':
                // Returns individual POST request
                return this.pipeData( this.http.post(`${ environment.data.api }${ endpoint }`, body, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.data`)}} ), options)
            default:
                // Returns individual GET request
                return this.pipeData( this.http.get(`${ environment.data.api }${ endpoint }`, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.data`)}} ), options )
        }
    }

    // Global logic to persist data into the store
    private pipeData( request: Observable<any>, options: IDataRequestOptions ): Observable<any> {
        return request.pipe(
            tap( ( data: any ) => {
                // Set the global data subject
                this.data[ options.dataGroup ].next( {...data} )
                // Redirect if no results
                if( options.redirectOnEmpty && isEmpty(data) ) {
                    this.router.navigate([ options.redirectOnEmpty ])
                }
            }),
            catchError( () => {
                this.data[ options.dataGroup ].next( options.defaultData )
                return of()
            }),
            finalize(() => {
                this.loading[ options.dataGroup ].next( false )
                this.isLoadingGlobal.next( false )
            })
        )
    }

    private defaultDataGroupState() {
        this.dataGroupRequested = {}

        const dataGroups = this.configurationService.getConfig( 'filter.data_groups' )

        if ( !dataGroups ) {
            throw new Error('Data groups must be provided in the configuration {{ filter.data_groups }}')
        }

        // Default values for the data groups
        Object.keys( dataGroups ).forEach( key => {
            if ( !this.data[ key ] ) {
                this.data[ key ] = new BehaviorSubject(null)
                this.loading[ key + ':filters' ] = new Subject<void>()
                this.loading[ key ] = new Subject<void>()

            } else {
                this.data[ key ].next( null )
                this.loading[ key + ':filters' ] .next( false )
                this.loading[ key ].next(false)
            }

            if ( !this.data[ `${ key }:pagination` ] ) {
                this.data[ `${ key }:pagination` ] = new BehaviorSubject(null)
            } else {
                this.data[ `${ key }:pagination` ].next( null )
            }

            this.endpointParams[ key ] = {}

        })
    }

    /**
     * Helper to replace the params from an URL
     * @param url url as a template literal all {{expresion}} will be replaced by the params value
     * @param params key value object with the key to search and the value to replace
     */
    private replaceUrlParams( url: string, params: any ) {
        Object.keys( params ).forEach( key => {
            url = url.replace(`{{${key}}}`, encodeURIComponent(params[key]))
        })
        return url
    }

    /**
     * Helper function to retrieve query params prepopulated with data from stores
     * @param params key value object with the params to be pop
     */
    private getQueryParams( params: any ): any {
        const paramsPopulated = {}
        Object.keys( params ).forEach( key => {
            if( typeof params[key] === 'string' && params[key].indexOf('{{') >= 0) {
                const [ store, keyVar ] = params[key].split('.')
                paramsPopulated[key] = this.getValueFromStore(store, keyVar)
            } else {
                paramsPopulated[key] = params[key]
            }
        })
        return paramsPopulated
    }

    /**
     *
     * @param store name of the store to retrieve the value
     * @param key name of the key that will reference the value
     * @returns processed value from the store
     */
    private getValueFromStore( store: string, key: string) {
        if( store === 'catalog' ) {
            return this.configurationService.getConfig(`${store}.${key}`)
        }
    }
}
