import { Directive, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { ControlTypes, ISection, IComponentControl, IControlOptions } from '../controls/config-controls.component'
import get from 'lodash-es/get'
import { IBaseComponentConfiguration } from 'src/app/interfaces/components/component-base.interface'

/**
 * Use this as a base class for any component config tool
 */
@Directive()
export abstract class ConfigDirective implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    abstract basePath: string
    @Input() configPath: string
    get fullConfigPath(): string {
        if (!!this.basePath) {
            return [this.basePath, this.configPath].join('.')
        } else {
            return this.configPath
        }
    }

    configuration: IBaseComponentConfiguration
    sections: Array<ISection> = []
    controls: Array<IComponentControl> = []
    cssFileData: string
    stylePath: string = 'styles'

    constructor(
        protected configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.configuration = this.configurationService.getConfig(this.fullConfigPath)
        this.buildControls()
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    setCssFileData(data: string): void {
        this.cssFileData = data
    }

    setStylePath(path: string): void {
        this.stylePath = path
    }

    addSection(title: string, controls: Array<IComponentControl>, advancedControls?: Array<IComponentControl>): void {
        this.sections.push({
            title,
            controls,
            advancedControls,
        })
    }

    addControl(key: string, label: string, type: ControlTypes, options?: IControlOptions): IComponentControl {
        return {
            key,
            label,
            type,
            value: get(this.configuration, key, undefined),
            options,
        }
    }

    addStyle(key: string, label: string, type: ControlTypes, options?: IControlOptions): IComponentControl {
        const path = `${this.stylePath}.${key}`
        return {
            key: path,
            label,
            type,
            value: get(this.configuration, path, this.getStyleDefault(key)),
            options,
        }
    }

    addComment(content: string, options?: IControlOptions): IComponentControl {
        return {
            type: ControlTypes.comment,
            value: content,
            options: {
                fullWidth: true,
                ...options,
            }
        }
    }

    getStyleDefault(key: string): string {
        if (!this.cssFileData) {
            return
        }
        const re = new RegExp(`--${key}:(.*?);`) // --css-var: (value we care about);
        const matches = this.cssFileData.match(re)
        return matches ? matches[1].trim() : undefined
    }

    abstract buildControls(): void | Promise<void>
}
