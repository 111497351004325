import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from './../../environments/environment'
import { UtilityService } from './utility.service'

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor( private http: HttpClient ) { }

    sendContact( data: any ) {
        return this.http.post( `${ environment.data.api }form/submit`, data, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.contact`)}} )
    }

    sendFinanceQuote(data: any) {
        return this.http.post( `${ environment.data.api }financing/`, data, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.quote`)}} )
    }
}
