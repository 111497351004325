import { OnChanges } from '@angular/core'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'
import { Options } from '@angular-slider/ngx-slider/options'

// Pipes
import { RasCurrencyPipe } from 'src/app/pipes/ras-currency.pipe'

@Component({
    selector: 'ras-filter-range-slider',
    styleUrls: ['./range-slider.component.scss'],
    templateUrl: './range-slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderFilterComponent implements OnInit, OnDestroy, OnChanges {
    private readonly DEBOUNCE_TIME = 800
    private $destroy = new Subject<void>()
    private $values = new BehaviorSubject(null)

    @Input() max: number
    @Input() min: number

    @Input() selectedMax: number
    @Input() selectedMin: number

    @Input() increment: number

    @Output() change = new EventEmitter(null)

    constructor( private ref: ChangeDetectorRef,
                 private currencyPipe: RasCurrencyPipe) {}

    public options: Options = {
        floor: 0,
        ceil: 0,
        step: 0,
        draggableRange: true,
        translate: ( value ) => this.formatValue( value ) + (value === this.options.ceil ? '<i>  +</i>' : '')
    }

    ngOnInit(): void {
        this.$values.pipe(
            takeUntil( this.$destroy ),
            distinctUntilChanged(),
            debounceTime( this.DEBOUNCE_TIME ),
            filter( data => !!data)
        ).subscribe( (data: { min: number, max: number }) => {
            this.change.emit(data)
        })
    }

    ngOnChanges( changes: SimpleChanges ): void {

        const options: Options = Object.assign( {}, this.options )
        const min = ( changes.selectedMin ) ? this.min : ( changes.min ) ? changes.min.currentValue : this.min
        const max = ( changes.selectedMax ) ? this.max : ( changes.max ) ? changes.max.currentValue : this.max

        this.selectedMin = ( changes.selectedMin && changes.selectedMin.currentValue ) ? changes.selectedMin.currentValue : ( this.selectedMin && changes.selectedMax ) ? this.selectedMin : min
        this.selectedMax = ( changes.selectedMax && changes.selectedMax.currentValue ) ? changes.selectedMax.currentValue : ( this.selectedMax && changes.selectedMin ) ? this.selectedMax : max

        options.step = this.increment
        options.disabled = false
        options.ceil = max
        options.floor = min

        if ( min === max ) {
            options.disabled = true
        }

        this.options = options
        this.ref.markForCheck()
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChange() {
        this.$values.next({
            min: this.selectedMin,
            max: this.selectedMax
        })
    }

    private formatValue( value: number ): string {
        return this.currencyPipe.transform(value, {}, false, true)
    }
}
