import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

/**
 * This Guard helps redirect some existing Herc urls to their new urls
 */
@Injectable({
    providedIn: 'root'
})
export class HercRedirectGuard  {

    constructor(
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const arg1 = route.url[0].path
        const arg2 = route.url[1].path
        const arg3 = route.url[2]?.path || ''
        if (arg1 === 'Sales' && route.url.length === 2) {
            // matches e.g. Sales/Ready-Finance-USA
            const page = arg2 === 'Ready-Finance-USA' ? 'usa' : 'can'
            return this.router.navigate(['finance', page])
        } else if (arg1 === 'Sales' && arg2 === 'Equipment') {
            // matches e.g. Sales/Equipment/Aerial-Equipment
            let filterPieces: Array<string>
            switch (arg3) {
                case 'Compaction-Paving-Equipment':
                    filterPieces = ['Compaction', '&', 'Paving', 'Equipment']
                    break
                case 'Power-Lighting-HVAC':
                    filterPieces = ['Power,', 'Lighting', '&', 'HVAC']
                    break
                case 'Vehicles-Trucks-Trailers':
                    filterPieces = ['Vehicles,', 'Trucks', '&', 'Trailers']
                    break
                default:
                    // most can just have their dashes converted to spaces
                    filterPieces = arg3.split('-')
                    break
            }
            return this.router.navigate(['equipment/search'], {
                queryParams: {
                    'equipment.filter_type': filterPieces.join(' '),
                }
            })
        } else if (arg1 === 'Classifications') {
            // matches e.g. Classifications/Aerial-Equipment/Articulating-Boom-Lifts
            let filterPieces: Array<string>
            switch (arg3) {
                case 'Carry-Deck-And-Pick-And-Carry-Cranes':
                    filterPieces = ['Carry', 'Deck', 'And', 'Pick-And-Carry', 'Cranes']
                    break
                default:
                    // most can just have their dashes converted to spaces
                    filterPieces = arg3.split('-')
                    break
            }
            return this.router.navigate(['equipment/search'], {
                queryParams: {
                    'equipment.filter_category': filterPieces.join(' '),
                }
            })
        }
        return false
    }
}
