import { DatePipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

import { DATA_CONFIG } from '../config/data.config'

@Pipe({
    name: 'rasDate',
})
export class RasDatePipe extends DatePipe implements PipeTransform {

    transform(value: Date, args?: any): any {
        return !!value ? super.transform(value, args || 'longDate') : DATA_CONFIG.nullString
    }
}
