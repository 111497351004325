import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding,
    Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { SwiperContainer } from 'swiper/element'

// Services
import { SearchService } from './../../../services/search.service'
import { UtilityService } from '../../../services/utility.service'

// Directives
import { DEFAULT_NO_IMAGE } from 'src/app/directives/image/image.directive'

// Interfaces
import { IDetailCarousel } from 'src/app/interfaces/components/component-detail-carousel.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const DetailCarouselComponentDefaults: IDetailCarousel = {
    type: 'detail_carousel',
    display: true,
    showThumbs: true,
    defaultImage: null,
    isLoading: false,
    swiperId: 'detail-carousel',
}

export const DetailCarouselComponentDevOpts: IComponentDevOptions = {
    config: {
        ...DetailCarouselComponentDefaults,
    },
    data: {
        images: [
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
        ]
    }
}

@Component({
    selector: 'app-detail-carousel',
    templateUrl: './detail-carousel.component.html',
    styleUrls: ['./detail-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailCarouselComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    public readonly isIE: boolean = UtilityService.isIE()
    private $destroy = new Subject<void>()
    private tip: HTMLDivElement

    @Input() configuration: IDetailCarousel
    @Input() data: any

    @ViewChild('imagesSwiper', { static: false }) imagesSwiper: ElementRef<SwiperContainer>
    @ViewChild('thumbsSwiper', { static: false }) thumbsSwiper: ElementRef<SwiperContainer>
    @HostBinding('class') get cssClass() { return this.isIE ? 'is-ie' : '' }

    public title = ''
    public slides = []
    public slideIndex = 0
    public slideThumbIndex = 0
    public isZoomed: boolean = false
    public defaultNoImage = DEFAULT_NO_IMAGE

    constructor(private searchService: SearchService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, DetailCarouselComponentDefaults)
    }

    ngAfterViewInit() {
        this.initConfiguration()
        this.subscribeToEvents()
        if (this.configuration.dataGroup) {
            this.configuration.isLoading = true
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(
                    takeUntil(this.$destroy)
                )
                .subscribe(data => {
                    if (data) {
                        this.configuration.isLoading = false
                        this.data = data
                        this.initWithData(data)
                    }
                })
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !changes.configuration.isFirstChange()
            && (
                changes.configuration.currentValue?.display !== changes.configuration.previousValue?.display
                || changes.configuration.currentValue?.isLoading !== changes.configuration.previousValue?.isLoading
            )
        ) {
            // This component needs to do some separate intialization when everything is showing
            // the first time.  So if you hide things and show them again, it needs to reinitialize
            // some things
            if (
                changes.configuration.currentValue.display
                && !changes.configuration.currentValue.isLoading
            ) {
                this.initWithData(this.data)
            }
            this.ref.markForCheck()
        } else if (
            !changes.configuration.isFirstChange()
        ) {
            this.ref.markForCheck()
        }

        if (!!changes.data) {
            this.configuration.isLoading = false
            this.initWithData(changes.data.currentValue)
            this.ref.markForCheck()
        }
    }

    initWithData(data: any): void {
        this.slides = (data.images && data.images.length) ? data.images : ['none']
        this.title = UtilityService.getImgAltText(data)

        this.slideIndex = 0
        this.slideThumbIndex = 0

        this.ref.detectChanges()

        this.thumbsSwiper.nativeElement.swiper.update()

        Object.assign(this.imagesSwiper.nativeElement, {
            thumbs: {
                swiper: this.thumbsSwiper.nativeElement.swiper,
                autoScrollOffset: 1
            },
        })

        this.imagesSwiper.nativeElement.swiper.update()

        this.ref.detectChanges()
    }

    subscribeToEvents(): void {
        this.tip = document.querySelector('#tip')
        this.imagesSwiper.nativeElement.addEventListener('click', () => {
            this.imagesSwiper.nativeElement.swiper.zoom.toggle()
            this.isZoomed = this.imagesSwiper.nativeElement.swiper.zoom.scale !== 1
            this.ref.detectChanges()
        })
        this.imagesSwiper.nativeElement.addEventListener('mouseover', () => this.tip.style.display = 'block')
        this.imagesSwiper.nativeElement.addEventListener('mouseout', () => this.tip.style.display = 'none')
    }

    getAltText(i: number, type: string) {
        switch (type) {
            case 'gallery':
                return `${this.title} | image ${i}`
            case 'thumbnail':
                return `Gallery Thumbnail - ${this.title} | image ${i}`
        }
    }
    private initConfiguration() {
        const imagesConfiguration = {
            spaceBetween: 10,
            autoHeight: false,
            navigation: {
                nextEl: '.swiper-button-next-gallery',
                prevEl: '.swiper-button-prev-gallery',
            },
            lazy: true,
            loop: false,
            keyboard: true,
            zoom: true,
            observer: true
        }
        const thumbsConfiguration = {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            shortSwipes: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            scrollbar: {
                draggable: false
            },
            navigation: {
                nextEl: '.swiper-button-next-thumbs',
                prevEl: '.swiper-button-prev-thumbs',
            },
            observer: true
        }

        Object.assign(this.thumbsSwiper.nativeElement, thumbsConfiguration)
        Object.assign(this.imagesSwiper.nativeElement, imagesConfiguration)

        this.thumbsSwiper.nativeElement.initialize()
        this.imagesSwiper.nativeElement.initialize()
    }
}
