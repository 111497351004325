<div *ngIf="configuration.display">
    <!-- Results header -->
    <div #anchorTop></div>
    <div
        #header
        *ngIf="configuration.showHeader"
        class="search-result-header"
        [class.hide]="!configuration.listLink && configuration.displayOptions.length <= 1 && !sort.length && !configuration.listTitle && !configuration.showResultsCount">
        <!-- Results header count -->
        <div class="search-result-header-left-container">
            <h2 *ngIf="configuration.listTitle" class="is-font-h1 is-font-bold m-0">
                {{ configuration.listTitle }}
            </h2>
            <div class="search-result-header-total" *ngIf="configuration.showResultsCount">
                <strong [class.is-loading]="isLoading">{{ pagination.totalItems }}</strong>
                <span> {{configuration.resultsCountLabel}}</span>
            </div>
        </div>
        <!-- Results sort, display type and link -->
        <div class="search-result-header-right-container">
            <!-- Results header sort -->
            <span class="search-result-label" *ngIf="!!configuration.labelTopPagination" >{{ configuration.labelTopPagination }}</span>
            <mat-select class="is-rounded-small"
                        placeholder="Sort by"
                        hideSingleSelectionIndicator
                        *ngIf="configuration.showSort && sort.length"
                        (ngModelChange)="onPaginationSort($event)"
                        [(ngModel)]="pagination.sort">
                <mat-option *ngFor="let sortOption of sort" [value]="sortOption">
                    {{configuration.sortOptions[sortOption].label}}
                </mat-option>
            </mat-select>
            <mat-select class="is-rounded-small"
                        hideSingleSelectionIndicator
                        [(ngModel)]="pagination.size"
                        (ngModelChange)="onPaginationSize($event)"
                        *ngIf="data && configuration.showPaginationSizeAtTop">
                <mat-option *ngFor="let size of pagination.sizesAvailable" [value]="size">{{size}} per page</mat-option>
            </mat-select>
            <!-- Results header display type -->
            <div
                class="search-result-header-options"
                *ngIf="configuration.displayOptions.length > 1">
                <fa-icon
                    *ngFor="let item of configuration.displayOptions"
                    [icon]="['fas', item.icon]"
                    [class.active]="configuration.displayType === item.value"
                    (click)="onChangeDisplayType(item.value)"></fa-icon>
            </div>

            <!-- List -->
            <div class="search-result-header-link" *ngIf="configuration.listLink">
                <a [routerLink]="configuration.listLink" [queryParams]="getParamsListLink()"> {{ configuration.listLinkLabel }} </a>
            </div>
        </div>
    </div>

    <!-- Results Items -->
    <div class="ras-items-container {{displayType}}">
        <ng-container *ngIf="!isLoading; else loadingTemplate">
            <ng-container *ngFor="let item of data">
                <a class="is-rounded ras-item-list"
                matRipple
                ras-list-item
                rel="follow"
                (click)="onUnsetNavigation()"
                [routerLink]="getLink( item )"
                [queryParams]="getParams( item )"
                [isLoading]="isLoading"
                [data]="item"
                [configuration]="configuration.childConfigs?.list_item">
                </a>
            </ng-container>
            <!-- Optional Extra item -->
            <div *ngIf="configuration.addLastItem && data?.length"
                    class="is-rounded ras-item-list"
                    matRipple
                    ras-render-element
                    (click)="onUnsetNavigation()"
                    [routerLink]="configuration.addLastItem.link"
                    [content]="configuration.addLastItem.content">
            </div>
        </ng-container>

        <!-- Loading boilerplate -->
        <ng-template #loadingTemplate>
            <div class="is-rounded is-loading ras-item-list">
                <figure></figure>
                <div class="content">
                    <div class="content-header">
                        <div class="title"></div>
                        <div class="price"></div>
                    </div>
                </div>
            </div>
            <div class="is-rounded is-loading ras-item-list">
                <figure></figure>
                <div class="content">
                    <div class="content-header">
                        <div class="title"></div>
                        <div class="price"></div>
                    </div>
                </div>
            </div>
            <div class="is-rounded is-loading ras-item-list">
                <figure></figure>
                <div class="content">
                    <div class="content-header">
                        <div class="title"></div>
                        <div class="price"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>


    <!-- Not found template -->
    <div
        *ngIf="!isLoading && data?.length === 0"
        [style]="configuration.noResults?.styles"
        class="search-no-results-container">
        <h1 [innerHTML]="configuration.noResults?.title" [style]="configuration.noResults?.title_styles"></h1>
        <p [innerHTML]="configuration.noResults?.subtitle" [style]="configuration.noResults?.subtitle_styles"></p>
    </div>

    <!-- Results Pagination -->
    <div
        *ngIf="data && configuration.showPagination"
        class="search-pagination">
        <div class="search-pagination-inner">
            <div class="pagination-navigation">
                <button class="is-background-primary" mat-stroked-button [disabled]="canGoPrevious" (click)="onPaginationNavigation(-1)">{{configuration.listPreviousLabel}}</button>
                <button class="is-background-primary" mat-stroked-button [disabled]="canGoNext" (click)="onPaginationNavigation(1)">{{configuration.listNextLabel}}</button>
            </div>
            <div class="pagination-items">
                <ul>
                    <li *ngFor="let item of pagination.pages"
                        [class.active]="( item ) === pagination.index"
                        (click)="onPaginationPage( item )">
                        {{ item + 1 }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="pagination-size" *ngIf="data && configuration.showPaginationSize">
            Results
            <mat-select class="is-rounded-small"
                        hideSingleSelectionIndicator
                        [(ngModel)]="pagination.size"
                        (ngModelChange)="onPaginationSize($event)">
                <mat-option *ngFor="let size of pagination.sizesAvailable" [value]="size">{{size}}</mat-option>
            </mat-select>
        </div>
    </div>
</div>