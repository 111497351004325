<div class="featured-badges-container" *ngIf="availableTags && availableTags.length && configuration.display">
    <ng-container *ngFor="let tag of getTagsLimited( data )">
        <ras-badge [configuration]="tag"></ras-badge>
    </ng-container>
    <ras-badge
        *ngIf="getTags(data).length > configuration.limitTags"
        [configuration]="{
            key: 'more',
            label: getAdditionalTagLabel(data),
            styles: configuration.badgeStyles
        }"></ras-badge>
</div>
