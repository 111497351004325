import { SearchService } from './services/search.service'
import { QueryService } from 'src/app/services/query.service'
import { LocationService } from './services/location.service'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

// Modules
import { CoreModule } from './core.module'
import { AppRoutingModule } from './app-routing.module'

// Base App Necessities
import { AppComponent } from './app.component'

// Services
import { LogService } from './services/log.service'

// Configs
import { logConfig } from './config/log.config'
import { environment } from '../environments/environment'

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
import { LoadingBarModule } from '@ngx-loading-bar/core'
import { AngularFireModule } from '@angular/fire/compat'
import * as Sentry from '@sentry/angular'
import { Router } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({ declarations: [
        // Base App Necessities
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        CoreModule.forRoot(),
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        FontAwesomeModule], providers: [
        {
            provide: ErrorHandler,
            useFactory: LogService.initialize(logConfig, '', environment.releaseStage)
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
                logErrors: true,
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: Window,
            useValue: window
        },
        SearchService,
        LocationService,
        QueryService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
