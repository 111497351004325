<div *ngIf="configuration.display" class="featured-detail-container">
    <ng-container *ngFor="let item of configuration.items">
        <div *ngIf="data && data[item.key]" class="featured-detail-item is-bordered is-rounded">
            <div class="featured-detail-items-inner">
                <img [src]="item.image_src" [alt]="getImgAltText(item)" loading="lazy">
                <div class="featured-detail-content">
                    <p class="featured-detail-title" *ngIf="item.title">
                        {{ item.title | rasTemplate:data }}
                    </p>
                    <p class="featured-detail-subtitle is-color-opacity-secondary" *ngIf="item.subtitle && !item.subtitle_link; else subtitleLink">
                        {{ item.subtitle| rasTemplate:data }}
                    </p>
                    <ng-template #subtitleLink>
                        <p class="featured-detail-subtitle featured-detail-link is-color-primary"
                           [routerLink]="item.subtitle_link"
                           [queryParams]="getParams(item.subtitle_link_params)">
                            {{ item.subtitle | rasTemplate:data }}
                        </p>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
</div>
