import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core'
import { Subject } from 'rxjs'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

// Services
import { UtilityService } from 'src/app/services/utility.service'
import { FormService } from 'src/app/services/form.service'
import { ThemeService } from 'src/app/services/theme.service'

// Interfaces
import { IFooterBasic1Configuration } from './footer-basic-1.interface'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { finalize, takeUntil } from 'rxjs/operators'

export const FooterBasic1ComponentDefaults: IFooterBasic1Configuration = {
    type: 'footer_basic_1',
    display: true,
    breakpointMedium: 600,
    breakpointLarge: 800,
    logo: {
        src: '',
        alt: '',
        href: '',
    },
    newsletterDisplay: false,
    newsletterLabel: 'Subscribe to our newsletter',
    newsletterPlaceholder: 'Email Address',
    newsletterSuccessMessage: 'Thank you for signing up!',
    newsletterErrorMessage: 'Something went wrong.',
    navItems: [],
    addressItems: [],
    phone: {
        href: '',
        content: ''
    },
    socialMediaItems: [],
    subNavItems: [],
    copyright: '',
    poweredByLogo: '',
    poweredByLink: '',
    styles: {},
    childConfigs: {},
}

export const FooterBasic1ComponentDevOpts: IComponentDevOptions = {
    config: {
        ...FooterBasic1ComponentDefaults,
    },
}

@Component({
    selector: 'ras-footer-basic-1',
    templateUrl: './footer-basic-1.component.html',
    styleUrls: ['./footer-basic-1.component.scss'],
})
export class FooterBasic1Component implements OnInit, OnDestroy, AfterViewInit {
    private $destroy = new Subject<void>()

    @HostBinding('class') hostClass: string

    @Input() configuration: IFooterBasic1Configuration

    mode: 'small' | 'medium' | 'large'
    form = new UntypedFormGroup({
        email: new UntypedFormControl('', [
            Validators.required,
            Validators.email
        ])
    })
    formError: string = ''
    formSuccess: string = ''

    constructor(
        private elRef: ElementRef,
        @SkipSelf() private ref: ChangeDetectorRef, // SkipSelf allows hostClass change to be detected on first load
        private themeService: ThemeService,
        private formService: FormService,
    ) {}

    @HostListener('window:resize', [])
    onResize() {
        this.setBreakpoints()
    }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, FooterBasic1ComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        this.ref.markForCheck()
    }

    ngAfterViewInit(): void {
        this.setBreakpoints()
        this.ref.detectChanges()
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    setBreakpoints(): void {
        const width = document.body.clientWidth
        if (width >= this.configuration.breakpointLarge) {
            if (this.hostClass !== 'breakpoint-large') {
                this.setMode('large')
            }
            return
        }
        if (width >= this.configuration.breakpointMedium) {
            if (this.hostClass !== 'breakpoint-medium') {
                this.setMode('medium')
            }
            return
        }
        if (this.hostClass !== 'breakpoint-small') {
            this.setMode('small')
        }
    }

    getCopyright(): string {
        const date = new Date()
        const year = date.getFullYear()
        return this.configuration.copyright ? this.configuration.copyright.replace('{{year}}', year.toString()) : ''
    }

    setMode(mode: 'small' | 'medium' | 'large'): void {
        this.mode = mode
        this.hostClass = `breakpoint-${mode}`
        this.ref.markForCheck()
    }

    submitNewsletter(): void {
        if (!!this.form.get('email').errors) {
            this.formError = 'Invalid email.'
            this.ref.markForCheck()
            return
        }
        const data = {
            form_type: 'newsletter',
            email: this.form.get('email').value,
        }
        this.formService
            .sendContact(data)
            .pipe(
                takeUntil(this.$destroy),
                finalize( () => {
                    this.ref.markForCheck()
                })
            )
            .subscribe((response: any) => {
                if (!!response.success) {
                    this.formSuccess = this.configuration.newsletterSuccessMessage
                } else {
                    this.formError = this.configuration.newsletterErrorMessage
                }
                this.ref.markForCheck()
            }, error => {
                console.error({message: 'Error while sending the contact form', error: error})
                this.formError = this.configuration.newsletterErrorMessage
                this.ref.detectChanges()
                this.ref.markForCheck()
            })
    }
}
