import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Injectable({
    providedIn: 'root'
})
export class TagManagerService {

    constructor(@Inject(Window) private window,
                @Inject(DOCUMENT) private document: HTMLDocument) { }

    init(id: string) {
        this.createTag(this.window, this.document, 'script', 'dataLayer', id)
    }

    private createTag(w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
    }

    /**
     * Push custom event trigger.  Just a wrapper for dataLayer.push().
     * See: https://developers.google.com/tag-platform/tag-manager/web/datalayer
     * @param data data to be sent to GoogleTagManager
     * @returns {void}
     */
    push(data: any) {
        this.window.dataLayer?.push(data)
    }
}
