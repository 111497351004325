import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { QueryService } from 'src/app/services/query.service'
import { LogService } from 'src/app/services/log.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IFilterDrilldownConfiguration } from 'src/app/interfaces/filters/filter-drilldown.interface'
import { ISmartConsoleFunctions } from 'src/app/interfaces'

@Component({
    selector: 'ras-filter-drilldown',
    styleUrls: ['./filter-drilldown.component.scss'],
    templateUrl: './filter-drilldown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDrilldownComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy =  new Subject<void>()
    private readonly debug: ISmartConsoleFunctions

    @Input() configuration: IFilterDrilldownConfiguration
    @Input() parentCollapsableComponent

    public items: Array<{ name: string, selected: boolean, key: string }> = []
    public isFullView: boolean = false

    steps = []
    data = {}
    show: string = null

    constructor(private queryService: QueryService, private ref: ChangeDetectorRef) {
        this.debug = LogService.startDebug('general', 'FilterDrilldown')
    }

    ngOnInit(): void {
        this.queryService.addParamToFetch(this.configuration.dataGroup, this.configuration.steps.map((step) => step.key))
        this.queryService.paramsData
            .pipe(
                takeUntil( this.$destroy )
            )
            .subscribe(data => {
                if (!data) {
                    return
                }
                this.steps = this.configuration.steps
                this.steps.forEach((step, index) => {
                    const { key, type } = step
                    if (!(key in data)) {
                        return
                    }
                    const hasParam = this.queryService.hasParam(this.configuration.dataGroup, key)
                    let hasPrevParam = false
                    if (index > 0) {
                        hasPrevParam = this.queryService.hasParam(this.configuration.dataGroup, this.steps[index - 1].key)
                    }
                    const hasLastParam = this.queryService.hasParam(this.configuration.dataGroup, this.steps[this.steps.length - 1].key)
                    switch (index) {
                        case 0: // first step
                            if (!hasParam && !hasLastParam) {
                                this.show = key
                            }
                            break
                        case this.steps.length - 1: // last step
                            if (hasParam || hasPrevParam) {
                                this.show = key
                            }
                            break
                        default: // any step inbetween
                            if (!hasParam && hasPrevParam && !hasLastParam) {
                                this.show = key
                            }
                            break
                    }
                    if (this.show === key && 'collapsableTitle' in step) {
                        this.parentCollapsableComponent.updateTitle(step.collapsableTitle)
                    }
                    switch (type) {
                        case 'links':
                            this.data[key] = data[key]
                            break
                        case 'checkboxes':
                            this.data[key] = data[key].map((item: string) => ({
                                name: this.getName(item),
                                selected: this.queryService.hasParam(this.configuration.dataGroup, key),
                                key,
                                label: key
                            }))
                            break
                    }
                })
                this.ref.markForCheck()
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    linkClicked(item: string, key: string): void {
        this.queryService.add(
            this.configuration.dataGroup,
            key,
            this.cleanFilterItem(item),
        )
    }

    onCheckboxesApply(key): void {
        const selectedItems = this.data[key].filter(item => item.selected)
        if (!selectedItems.length) {
            this.queryService.removeParamKey(this.configuration.dataGroup, key)
            return
        }
        this.queryService.add(
            this.configuration.dataGroup,
            key,
            selectedItems.map(item => this.cleanFilterItem(item.name))
        )
    }

    cleanFilterItem(item: string): string {
        return item.replace(/\s\(\d+\)/, '')
    }

    /**
     * If the name label is set on the configuration, we use that one
     */
    getName( key: string ) {
        return this.queryService.getParamLabel(key)
    }
}
