import { Injectable } from '@angular/core'

import { take } from 'rxjs/operators'
import { CurrencyService } from '../services/currency.service'

@Injectable({
    providedIn: 'root'
})
export class CurrencyResolver  {

    constructor(
        private currencyService: CurrencyService,
    ) {}

    resolve() {
        this.currencyService.populateCurrencyDataFromConfig()
        return this.currencyService.init().pipe(take(1))
    }
}
