<div
    class="item-container"
    *ngIf="configuration.display">
    <figure *ngIf="configuration?.showImage && data">

        <!-- Item featured flags -->
        <ras-badges-container
            [data]="data"
            [configuration]="configuration?.childConfigs?.badges"></ras-badges-container>

        <!-- Item primary image -->
        <img *ngIf="data.image; else defaultImage" ras-image [transparent]="true" [id]="data.image" [alt]="getImgAltText(data)">

        <ng-template #defaultImage>
            <img [src]="configuration?.defaultImage" [alt]="getImgAltText(data)" class="default-image"/>
        </ng-template>

    </figure>

    <div class="content" *ngIf="data">

        <!-- Header -->
        <div class="content-header">
            <div class="content-header-inner">
                <!-- Title -->
                <div class="title" *ngIf="configuration?.showTitle">
                    <ng-container *ngIf="configuration?.itemTitle; else rawTitle">
                        {{ replaceTemplate(configuration.itemTitle, data) }}
                        <!-- Quantity -->
                        <span class="quantity-header"
                                *ngIf="configuration?.showQuantityAtHeader && data.quantity && !isLimitedSupply( data )">
                            {{ data.quantity | number }}
                        </span>
                    </ng-container>
                    <ng-template #rawTitle>
                        {{ data.year }}  {{ data.make }} {{ data.model }}
                    </ng-template>
                </div>
                <!-- Location -->
                <div *ngIf="configuration?.showLocation && ( data.branch_stat || data.branch_city )" class="location is-color-opacity-secondary">
                    {{ data.branch_city | lowercase }}, {{ data.branch_state }}
                </div>
            </div>
            <!-- Price container -->
            <div class="price" *ngIf="configuration?.showPrice">
                <div *ngIf="data.is_list_price_hidden || data.list_price === null" class="is-color-primary is-font-600">
                    <span>Contact for pricing</span>
                </div>

                <ng-container *ngIf="!data.is_list_price_hidden && data.list_price">
                    <div *ngIf="!configuration.showDiscountedPrice || data.compare_at_price_multiplier <= 1" class="is-color-primary is-font-600">
                        <span>{{  data.list_price | rasCurrency : data }}</span>
                    </div>

                    <div *ngIf="configuration.showDiscountedPrice && data.compare_at_price_multiplier > 1" class="is-color-primary is-font-600">
                        <span class="price-discount">
                            {{ getFormatted( (data.list_price * data.compare_at_price_multiplier).toString() , ' - ', '', 'money') }}
                        </span>
                        <span class="price-raw">
                            {{ getFormatted( data.list_price, ' - ', '', 'money') }}
                        </span>
                    </div>

                    <div class="monthly-payment" *ngIf="data.lowest_monthly_payment && configuration?.showMonthlyPayment">
                        <strong>{{ data.lowest_monthly_payment | rasCurrency : data : false : true }} / mo</strong>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Details -->
        <div *ngIf="configuration?.showDescription" class="details"> {{ data.detailed_description | ellipsis: 100 }} </div>

        <!-- Footer -->
        <div class="content-footer">
            <!-- Meter -->
            <div *ngIf="configuration?.showMeter && !!( data | rasMeter )" class="meter is-color-opacity-secondary mr-2">
                <span>{{ data | rasMeter }}</span>
            </div>
            <!-- data number -->
            <div *ngIf="configuration?.showdataNumber" class="data-id">
                <strong>data # </strong>
                <span>{{ data.data_number }}</span>
            </div>
            <!-- data serial -->
            <div *ngIf="configuration?.showdataSerial" class="serial-id">
                <strong>Serial # </strong>
                <span>{{ data.serial_number }}</span>
            </div>

            <!-- Quantity -->
            <div class="quantity is-font-500"
                    *ngIf="configuration?.showQuantity && data.quantity && !isLimitedSupply( data )"
                    [class.low-items]="configuration?.showQuantityLow ? data.quantity <= configuration?.showQuantityLow : false ">
                {{ data.quantity | number }} Available
            </div>

            <!-- Price container -->
            <div class="price price-footer-container" *ngIf="configuration?.showPriceFooter" [class.limited-supply]="isLimitedSupply( data )">
                <ng-container *ngIf="configuration?.labelPriceOnLowQuantity && isLimitedSupply( data ); else priceContainer">
                    <div class="is-color-primary is-font-600 label-price-low-quantity" [innerHtml]="configuration?.labelPriceOnLowQuantity"></div>
                </ng-container>
                <ng-template #priceContainer>
                    <div *ngIf="data.is_list_price_hidden || data.list_price === null" class="is-color-primary is-font-600">
                        <span>Contact for pricing</span>
                    </div>

                    <ng-container *ngIf="!data.is_list_price_hidden && data.list_price">
                        <!-- Without discount -->
                        <div  *ngIf="!configuration.showDiscountedPrice || data.compare_at_price_multiplier <= 1">
                            <div *ngIf="data.list_price" class="is-color-primary is-font-600">
                                <span class="price-raw">{{ data.list_price | rasCurrency : data : true }}</span>
                            </div>
                            <div class="monthly-payment" *ngIf="data.lowest_monthly_payment && configuration?.showMonthlyPayment">
                                <strong>{{ data.lowest_monthly_payment | rasCurrency : data : false : true }} /mo</strong>
                            </div>
                        </div>

                        <!-- With discount -->
                        <div *ngIf="configuration.showDiscountedPrice && data.compare_at_price_multiplier > 1">
                            <span class="price-discount">
                                {{ getFormatted( (data.list_price * data.compare_at_price_multiplier).toString() , ' - ', '', 'money') }}
                            </span>
                            <span class="price-raw">
                                {{ getFormatted( data.list_price, ' - ', '', 'money') }}
                            </span>
                            <div class="monthly-payment" *ngIf="data.lowest_monthly_payment && configuration?.showMonthlyPayment">
                                <strong>{{ data.lowest_monthly_payment | rasCurrency : data : false : true }} /mo</strong>
                            </div>
                        </div>
                    </ng-container>

                </ng-template>
            </div>

            <!-- Likeable button -->
            <fa-icon *ngIf="configuration?.showLikeable"
                        [class]=" configuration?.isLiked ? 'fas' : 'far'"
                        [icon]="[ configuration?.isLiked ? 'fas' : 'far', 'heart' ]"
                        (click)="configuration.isLiked = onLike(!configuration?.isLiked)">
            </fa-icon>
        </div>
    </div>
</div>