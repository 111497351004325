<h2 class="is-font-h2 is-font-bold mt-0"> {{ data?.label }}</h2>

<div *ngIf="data?.fields; else flatText" class="list-details-item-column">
    <ng-container *ngFor="let item of data.fields">
        <div class="mb-2" *ngIf="item.value" [class]="item.class">
            <h3 class="is-color-opacity-secondary mr-1 item-label">
                {{ item.label }}
            </h3>
            <span class="is-font-500">
                {{ item.value }}
            </span>
        </div>
    </ng-container>
</div>

<ng-template #flatText>
   <div class="is-color-opacity-secondary" [innerHTML]="data.value"></div>
</ng-template>
