<ng-container *ngFor="let data of content">
    <ng-container *ngTemplateOutlet="builderTemplate; context: { $implicit: data }" ></ng-container>
</ng-container>

<!-- Builder template -->
<ng-template #builderTemplate let-data>
    <ng-container *ngTemplateOutlet="getTemplateRef( data.element ); context: { $implicit: data }" ></ng-container>
</ng-template>

<!-- Template to loop within childs -->
<ng-template #builderLoopTemplate let-parent>
    <ng-container *ngFor="let data of parent.childs">
        <ng-container *ngTemplateOutlet="builderTemplate; context: { $implicit: data }" ></ng-container>
    </ng-container>
</ng-template>

<!-- Template <div> Div container -->
<ng-template #divTemplate let-data>
    <div [class]="data.class" [style]="data.styles" (click)="onOpen( data, divItemRef )" [attr.id]="data.id" #divItemRef>
        {{ data.content }}
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </div>
</ng-template>

<!-- Template <a> Anchor -->
<ng-template #anchorTemplate let-data>
    <a [href]="data.href" [style]="data.styles" [class]="data.class" *ngIf="data.href">
        {{ data.content }}
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </a>
</ng-template>

<!-- Template <a-ng> Anchor Angular -->
<ng-template #anchorNgTemplate let-data>
    <a [style]="data.styles" [class]="data.class" [routerLink]="data.link">
        {{ data.content }}
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </a>
</ng-template>

<!-- Template <page-jump> Page jump -->
<ng-template #pageJumpTemplate let-data>
    <button class="hero-button"
        (click)="onPageJump(data.link)"
        [style]="data.styles"
        [innerHTML]="data.content">
    </button>
</ng-template>

<!-- Template <ul> List -->
<ng-template #listTemplate let-data>
    <ul [class]="data.class" [style]="data.styles" [attr.id]="data.id">
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </ul>
</ng-template>

<!-- Template <li> list item -->
<ng-template #listItemTemplate let-data>
    <li [class]="data.class" [style]="data.styles" [attr.id]="data.id" (click)="onOpen( data, listItemRef )" #listItemRef>
        {{ data.content }}
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </li>
</ng-template>

<!-- Template <img> image -->
<ng-template #imageTemplate let-data>
    <img [src]="data.src" [style]="data.styles" [class]="data.class" [attr.alt]="data.alt"/>
</ng-template>

<!-- Template <span> span -->
<ng-template #spanTemplate let-data>
    <span [style]="data.styles" [class]="data.class" (click)="onOpen( data, spanItemRef )" #spanItemRef>
        {{ data.content }}
        <ng-container *ngIf="data.childs?.length">
            <ng-container *ngTemplateOutlet="builderLoopTemplate; context: { $implicit: data }"></ng-container>
        </ng-container>
    </span>
</ng-template>

<!-- Template <fa-icon> icon -->
<ng-template #iconTemplate let-data>
    <fa-icon [style]="data.styles" [icon]="data.icon" [class]="data.class">
        {{ data.content }}
    </fa-icon>
</ng-template>

<!-- Template <button> button -->
<ng-template #buttonTemplate let-data>
    <button [style]="data.styles" [class]="data.class">
        {{ data.content }}
    </button>
</ng-template>

<!-- Template <p> paragraph  -->
<ng-template #paragraphTemplate let-data>
    <p [style]="data.styles" [class]="data.class" [innerHTML]=" data.content"></p>
</ng-template>

<!-- Template raw, raw html -->
<ng-template #rawTemplate let-data>
    <div [innerHTML]="data.content | safe: 'html'"></div>
</ng-template>

<!-- Template currency selector -->
<ng-template #currencySelectorTemplate let-data>
    <ras-currency-selector></ras-currency-selector>
</ng-template>

<!-- Template modal -->
<ng-template #modalLinkTemplate let-data>
    <ras-modal-link [configuration]="data"></ras-modal-link>
</ng-template>
