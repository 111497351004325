import { ErrorHandler } from '@angular/core'

// Interfaces
import { ILogConfig, TLoggerServiceLevel, IRasLoggedUser } from '../interfaces'

const initialize: (apiKey: string, app: string) => void = (apiKey: string, app: string): void => {
    // Implement new log service
}

const factory: () => ErrorHandler = (): ErrorHandler => (undefined)

const logger: (
    message: string, severity: TLoggerServiceLevel, callback?: (err: any, report: any) => void
) => void = (message: string, severity: 'info' | 'error' | 'warning', callback?: (err: any, report: any) => void): void => {
    switch (severity) {
        case 'info':
            console.log(message)
            break
        case 'error':
            console.error(message)
            break
        case 'warning':
            console.warn(message)
            break
        default:
            console.log(message)
    }
}

const setUser: (user: IRasLoggedUser) => void = (user: IRasLoggedUser): void => {
}

export const logConfig: ILogConfig = {
    logger: logger,
    factory: factory,
    initialize: initialize,
    setUser: setUser,
}
