import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { SearchService } from '../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IIframeConfiguration } from 'src/app/interfaces/components/component-iframe.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { DomSanitizer } from '@angular/platform-browser'

export const IframeComponentDefaults: IIframeConfiguration = {
    iframeHeight: '100%',
    iframeUrl: '',
    type: 'iframe',
    display: true,
}

export const IframeComponentDevOpts: IComponentDevOptions = {
    config: {
        ...IframeComponentDefaults,
    },
}

@Component({
    selector: '.ras-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()

    @Input() configuration: IIframeConfiguration
    @Input() data: any

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, IframeComponentDefaults)
        if (this.configuration.dataGroup) {
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(takeUntil(this.$destroy))
                .subscribe(data => {
                    this.data = { ...data }
                    this.ref.markForCheck()
                })
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    getSanitizedUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }
}
