import { Inject, Injectable } from '@angular/core'
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router'
import { LogService } from './log.service'
import { distinctUntilChanged, filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor( @Inject( Window ) private window, private router: Router) {}

    init( codes: Array<{ name: string, id: string  }> ) {
        LogService.log('AnalyticsService.init()', 'info')

        // GA4 : Setup for Google Analytics
        this.window.dataLayer = this.window.dataLayer || []
        this.window.ga_debug = {trace: true}

        LogService.log(`Adding GA code: ${codes[0].id}`, 'info')

        Promise.all([
            new Promise((resolve) => {
                const script = document.createElement('script')
                script.src = `https://www.googletagmanager.com/gtag/js?id=${codes[0].id}`
                script.async = true
                document.body.appendChild(script)
                resolve(1)
            }).then(() => {
                codes.forEach( key => {
                    this.window.gtag('config', key.id)
                    this.window.gtag('js', new Date())
                })
            })
        ])

        this.router.events
            .pipe(
                filter( ( event: Event|RouterEvent ) => event instanceof NavigationEnd ),
                distinctUntilChanged( ( event: RouterEvent, event2: RouterEvent ) => event.url === event2.url )
            )
            .subscribe( () => {
                // GA4 Suggests NOT manually tracking page views, but wanted to keep parody to what we'd been doing ideally
                // const url = this.router.url
                // if ( !!url ) {
                //     this.window.gtag('event', 'page_view', { page_location: url })
                // }
            })
    }

}
