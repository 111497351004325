import { ChangeDetectorRef, Component, ComponentFactoryResolver, HostBinding, Input, OnInit, ViewChild } from '@angular/core'

// Services
import { ConfigurationService } from 'src/app/services/configuration.service'
import { LogService } from 'src/app/services/log.service'
import { UtilityService } from 'src/app/services/utility.service'

// Components
import { ZoneDirective } from 'src/app/components/page/core/zone.directive'
import { BlankComponent } from 'src/app/components/page/blank/blank.component'
import { BaseComponentMapping } from 'src/app/components/page/core/base-component.mapping'
import { BaseComponent } from 'src/app/components/page/core/base.component'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { ICollapsableConfiguration } from 'src/app/interfaces/components/component-collapsable.interface'

@Component({
    selector: '[ras-collapsable]',
    template: `
        <mat-expansion-panel [expanded]="state"
                             (opened)="state = true"
                             hideToggle="true"
                             (closed)="state = false">
           <mat-expansion-panel-header>
                <mat-panel-description class="material-collapsable-button is-font-sm">
                    <fa-icon [icon]="['fas','plus']" *ngIf="!state"></fa-icon>
                    <fa-icon [icon]="['fas','minus']" *ngIf="state"></fa-icon>
                </mat-panel-description>
                <mat-panel-title *ngIf="title || configuration.collapsableSubtitle">

                    <p class="collapsable-header is-font-sm">
                        <strong *ngIf="configuration.colapsableTitle">
                            {{ title }}
                        </strong>
                        <span *ngIf="configuration.collapsableSubtitle">
                            {{ configuration.collapsableSubtitle }}
                        </span>
                    </p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template zoneHost></ng-template>
        </mat-expansion-panel>

    `,
    styles: [`
        &:host { box-sizing: none }
        .collapsable-header { margin: 0; }
        .collapsable-header > * { display: block; }
        .collapsable-header span { opacity: .6; line-height: 1rem; }
        mat-expansion-panel { box-shadow: none!important }
        mat-expansion-panel-header { padding : 5px; }
        mat-expansion-panel-header:hover { background: inherit!important }
    `],
})
export class CollapsableComponent implements OnInit, IBaseComponent {
    @Input() configuration: ICollapsableConfiguration
    @Input() state: boolean = false

    @ViewChild(ZoneDirective, { static: true }) zoneHost: ZoneDirective
    @HostBinding('class') customClass: string = ''

    public title: string

    constructor( private componentFactoryResolver: ComponentFactoryResolver,
                 private componentMap: BaseComponentMapping,
                 private configurationService: ConfigurationService,
                 private ref: ChangeDetectorRef, ) { }

    ngOnInit(): void {
        this.state = !!this.configuration.isOpen
        this.customClass = this.configuration.collapsableCssClass ? this.configuration.collapsableCssClass : ''
        this.title = this.configuration.colapsableTitle
        this.renderComponent( this.configuration.componentKey, this.zoneHost )
    }

    private renderComponent( componentKey: string, zoneHost: ZoneDirective ) {
        try {

            const componentsAvailable = this.configurationService.configuration.components
            const baseComponent: any = UtilityService.objectToCamelCase( componentsAvailable[ componentKey ] )

            if ( this.componentMap.map[ baseComponent.type ] ) {
                const componentInstance = new BaseComponent( this.componentMap.map[ baseComponent.type ] )
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory( componentInstance.component )
                const viewContainerRef = zoneHost.viewContainerRef
                const componentRef = viewContainerRef.createComponent<IBaseComponent>( componentFactory )
                componentRef.instance.configuration = this.getConfiguration( componentsAvailable, baseComponent )
                componentRef.instance.parentCollapsableComponent = {
                    updateTitle: this.updateTitle.bind(this)
                }
            } else {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory( BlankComponent )
                const viewContainerRef = zoneHost.viewContainerRef
                const componentRef = viewContainerRef.createComponent<BlankComponent>( componentFactory )
                componentRef.instance.message = `The component ${ baseComponent.type } is currently not supported`
            }

        } catch( error ) { LogService.log( `[Collapsable(C)] Component not available: ${componentKey}`, 'info' ) }
    }

    private getConfiguration( componentsAvailable, baseComponent ) {
        if ( baseComponent.extends && componentsAvailable[ baseComponent.extends ] ) {
            return {
                ...UtilityService.objectToCamelCase( componentsAvailable[ baseComponent.extends ] ),
                ...baseComponent
            }
        }
        return baseComponent
    }

    public updateTitle(newTitle) {
        this.title = newTitle
        this.ref.markForCheck()
    }
}
