import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

// Services
import { StorageService } from './storage.service'

// Interfaces
import { ICurrency, ICurrencyRatesStorage, ICurrencyResponse } from '../interfaces/rouse-types/currency.interface'
import { environment } from 'src/environments/environment'
import { LogService } from './log.service'
import { ConfigurationService } from './configuration.service'
import { UtilityService } from './utility.service'


@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    private readonly SELECTED_KEY = 'ras_currency_selected'
    private readonly RATES_KEY = 'ras_currency_rates'
    private defaultCurrencyCode: string
    private configCurrencies: Array<ICurrency>
    private $selectedCurrency = new BehaviorSubject<ICurrency>(null)
    private $availableCurrencies = new BehaviorSubject<Array<ICurrency>>(null)

    public get selectedCurrency(): BehaviorSubject<ICurrency> { return this.$selectedCurrency }
    public get availableCurrencies(): BehaviorSubject<Array<ICurrency>> { return this.$availableCurrencies }

    public USD_CURRENCY: ICurrency = {
        label: 'United States dollar',
        char: '$',
        code: 'usd',
        flag: 'us',
        rate: 1,
    }

    constructor(
        private storage: StorageService,
        private http: HttpClient,
        private configurationService: ConfigurationService,
    ) {}

    private storeSelectedCurrency(currency: ICurrency): void {
        this.storage.set(this.SELECTED_KEY, currency)
    }

    private getSelectedCurrency(): ICurrency {
        return this.storage.get(this.SELECTED_KEY)
    }

    private clearSelectedCurrency(): void {
        this.storage.remove(this.SELECTED_KEY)
    }

    private storeRates(ratesData: ICurrencyRatesStorage): void {
        this.storage.set(this.RATES_KEY, ratesData)
    }

    private getRates(): ICurrencyRatesStorage {
        return this.storage.get(this.RATES_KEY)
    }

    private clearRates(): void {
        this.storage.remove(this.RATES_KEY)
    }

    private isCacheExpired(): boolean {
        const ratesData = this.getRates()
        const thisMonth = new Date().getMonth()
        const thisYear = new Date().getFullYear()
        const created = new Date()
        created.setTime(ratesData.created)
        return thisMonth !== created.getMonth() || thisYear !== created.getFullYear()
    }

    setConfigCurrencies(currencies: Array<ICurrency>): void {
        this.configCurrencies = currencies
    }

    setDefaultCurrency(currencyCode: string): void {
        this.defaultCurrencyCode = currencyCode
    }

    selectCurrency(currency: ICurrency): void {
        this.$selectedCurrency.next(currency)
        this.storeSelectedCurrency(currency)
    }

    selectCurrencyByCode(currencyCode: string): void {
        const currency = this.$availableCurrencies.getValue().find((c) => c.code === currencyCode)
        this.selectCurrency(currency)
    }

    compileAvailableCurrencies(rates: Array<ICurrencyResponse>): Array<ICurrency> {
        const currencies = rates.filter(
            data => this.configCurrencies.find(curr => curr.code.toLowerCase() === data.currency.toLowerCase()),
        ).map(data => ({
            ...this.configCurrencies.find(curr => curr.code.toLowerCase() === data.currency.toLowerCase()),
            rate: data.rate,
        }))
        this.$availableCurrencies.next(currencies)
        return currencies
    }

    init(force: boolean = false): Observable<any> {
        const ratesData = this.getRates()
        const selectedCurrency = this.getSelectedCurrency()
        const hasRateDataForConfigCurrencies = this.configCurrencies?.every(currency => ratesData?.rates?.find(r => r.currency.toUpperCase() === currency.code.toUpperCase()))
        const isSelectedCurrencyAvailable = ratesData?.rates?.find(r => r.currency.toLowerCase() === selectedCurrency?.code.toLowerCase())
        if (
            !ratesData
            || !selectedCurrency
            || !hasRateDataForConfigCurrencies
            || !isSelectedCurrencyAvailable
            || this.isCacheExpired()
            || force
        ) {
            return this.load()
        }
        this.compileAvailableCurrencies(ratesData.rates)
        this.$selectedCurrency.next(selectedCurrency)
        return this.$selectedCurrency.asObservable()
    }

    load(): Observable<any> {
        return this.http.post(`${environment.data.api}currency`, {
            currency: this.configCurrencies.map(c => c.code)
        }, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.currency`)}})
        .pipe(
            map((response: Array<ICurrencyResponse>) => {
                const rates = response.filter(r => r.rate !== null)
                this.storeRates({
                    rates,
                    created: (new Date()).getTime()
                })
                return rates
            }),
            map((rates: Array<ICurrencyResponse>) => this.compileAvailableCurrencies(rates)),
            map((currencies: Array<ICurrency>) => {
                const currency = currencies.find(c => c.code === this.defaultCurrencyCode)
                this.selectCurrency(currency)
            }),
            catchError((err) => {
                LogService.log(`Error getting currency data: ${err}`, 'error')
                this.clearRates()
                this.clearSelectedCurrency()
                this.$selectedCurrency.next(this.USD_CURRENCY)
                this.$availableCurrencies.next([this.USD_CURRENCY])
                return of(true)
            })
        )
    }

    populateCurrencyDataFromConfig(): void {
        this.setDefaultCurrency(
            this.configurationService.getConfig(
                'catalog.currency_base',
                this.USD_CURRENCY.code,
            )
        )
        const currencies: Array<ICurrency> = this.configurationService.getConfig(
            'catalog.currency_list',
            [],
        )
        if (!currencies.find(c => c?.code.toLowerCase() === this.USD_CURRENCY.code)) {
            currencies.unshift(this.USD_CURRENCY)
        }
        this.setConfigCurrencies(currencies)
    }
}
