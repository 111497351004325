import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { Subject } from 'rxjs'

export enum ControlTypes {
    comment,
    string,
    boolean,
    menuBuilder,
    colorPicker,
    currencyOptions,
}

export interface ISection {
    title?: string
    controls?: Array<IComponentControl>
    advancedControls?: Array<IComponentControl>
}

export interface IComponentControl {
    key?: string
    label?: string
    type: ControlTypes
    value: any
    options: IControlOptions
}

export interface IControlOptions {
    fullWidth?: boolean
    help?: string
}

@Component({
    selector: 'config-controls',
    templateUrl: './config-controls.component.html',
    styleUrls: ['./config-controls.component.scss', './control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigControlsComponent implements OnDestroy {
    private $destroy = new Subject<void>()

    @Input() sections: Array<ISection> = []
    @Input() fullConfigPath: string

    controlTypes: any = ControlTypes

    constructor(
        private ref: ChangeDetectorRef,
    ) {}

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    toggleSection(event: any): void {
        const section = event.target.parentNode
        if (section.classList.contains('section--collapsed')) {
            section.classList.remove('section--collapsed')
        } else {
            section.classList.add('section--collapsed')
        }
    }

    toggleAdvancedSection(event: any): void {
        const section = event.target.parentNode.parentNode
        if (section.classList.contains('section-advanced--collapsed')) {
            section.classList.remove('section-advanced--collapsed')
        } else {
            section.classList.add('section-advanced--collapsed')
        }
    }

    refresh(): void {
        this.ref.markForCheck()
    }
}
