import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Observable, } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storePrefix: string = 'ngx'
    private storeMethod: string
    private observers: Record<string, BehaviorSubject<any>> = {}

    set method(storeMethod) {
        if (storeMethod === 'sessionStorage' ) {
            this.storeMethod = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
                ? 'cookieStorage'
                : 'sessionStorage'
        } else if (storeMethod === 'localStorage') {
            this.storeMethod = this.isLocalAvailable() ? 'localStorage' : 'cookieStorage'
        } else {
            this.storeMethod = storeMethod
        }
    }

    constructor() {
        this.storeMethod = this.isLocalAvailable() ? 'localStorage' : 'cookieStorage'
    }

    set(key: string, value: any): void {
        if (this.observers[key]) {
            this.observers[key].next(value)
        }
        switch (this.storeMethod) {
            case 'sessionStorage':
                sessionStorage.setItem(this.storePrefix + '-' + key, JSON.stringify(value))
                return
            case 'localStorage':
                localStorage.setItem(this.storePrefix + '-' + key, JSON.stringify(value))
                return
            case 'cookiesStorage':
                const oneYearFromNow = new Date()
                oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
                document.cookie = `${this.storePrefix + '-' + key}=${JSON.stringify(value)}; Path=/; Expires=${oneYearFromNow.toUTCString()};`
                return
        }
    }

    get(key: string): any {
        switch (this.storeMethod) {
            case 'sessionStorage':
                return JSON.parse(sessionStorage.getItem(this.storePrefix + '-' + key))
            case 'localStorage':
                return JSON.parse(localStorage.getItem(this.storePrefix + '-' + key))
            case 'cookiesStorage':
                return JSON.parse(this.cookiesGetAllItems().get(this.storePrefix + '-' + key))
        }
    }

    observe(key: string): Observable<any> {
        if (!this.observers[key]) {
            this.observers[key] = new BehaviorSubject<any>(this.get(key))
        }
        return this.observers[key]
    }

    remove(key: string): any {
        switch (this.storeMethod) {
            case 'sessionStorage':
                sessionStorage.removeItem(this.storePrefix + '-' + key)
                return
            case 'localStorage':
                localStorage.removeItem(this.storePrefix + '-' + key)
                return
            case 'cookiesStorage':
                document.cookie = this.storePrefix + '-' + key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                return
        }
    }

    clear(): void {
        switch (this.storeMethod) {
            case 'sessionStorage':
                sessionStorage.clear()
                return
            case 'localStorage':
                localStorage.clear()
                return
            case 'cookiesStorage':
                document.cookie.split(";").forEach((c) => {
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
                })
                return
        }
    }

    private isLocalAvailable(): boolean {
        try {
            const x = '__storage_test__'
            localStorage.setItem(x, x)
            localStorage.removeItem(x)
            return true
        } catch (e) {
            return false
        }
    }

    private cookiesGetAllItems() {
        const map = new Map()
        const cookies = document.cookie.split(';')

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            const eqPos = cookie.indexOf('=')
            const key = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
            const value = eqPos > -1 ? cookie.substring(eqPos + 1, eqPos + 1 + cookie.length) : cookie
            map.set(key, value)
        }
        return map
    }
}
