<mat-grid-list 
    [cols]="configuration.cols"
    [gutterSize]="configuration.gutterSize"
    [rowHeight]="configuration.rowHeight"
    *ngIf="!listFormat && configuration.display">
    <mat-grid-tile #grid
        *ngFor="let item of data"
        [defaultSource]="item.imageUrl"
        ras-lazy-background
    >
        <div class="item">
            <div class="item-content">
                <a [href]="getSearchHref(item.filter, item.name)" class="title">
                    <span class="cat-title">{{item.name}}</span> 
                    <span class="cat-quantity">{{item.quantity}}</span>
                </a>
                <div class="extra-content">
                    <hr />
                    <ul>
                        <li *ngIf="configuration.includeParentInChildren">
                            <a [href]="getSearchHref(item.filter, item.name)">
                                <span class="subcat-title">All {{item.name}}</span>
                                <span class="subcat-quantity">{{item.quantity}}</span>
                            </a>
                        </li>
                        <li *ngFor="let child of item.children">
                            <a [href]="getSearchHref(child.filter, child.name)">
                                <span class="subcat-title">{{child.name}}</span>
                                <span class="subcat-quantity">{{child.quantity}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-grid-tile>
    <mat-grid-tile
        *ngIf="configuration.showFootnote"
        [colspan]="getFootnoteColspan()"
        class="no-hover"
        [style.background-image]="'url('+ configuration.viewAllBgUrl +')'">
        <div class="footnote-container">
            <div class="footnote-header">{{configuration.footnote?.header}}</div>
            <div class="footnote-content">{{configuration.footnote?.content}}</div>
            <div class="footnote-button">
                <button 
                    class="blue-btn"
                    (click)="viewAll()">View All</button>
            </div>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<mat-accordion *ngIf="listFormat && configuration.display" multi>
    <mat-expansion-panel hideToggle
        *ngFor="let item of data"
        [style.background-image]="getBackgroundImageForMobile(item.imageUrl)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{item.name}}
            </mat-panel-title>
            <mat-panel-description>
                {{item.quantity}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
            <li>
                <a [href]="getSearchHref(item.filter, item.name)">
                    <span class="cat-title">All {{item.name}}</span>
                    <span class="cat-quantity">{{item.quantity}}</span>
                </a>
            </li>
            <li *ngFor="let child of item.children">
                <a [href]="getSearchHref(child.filter, child.name)">
                    <span class="subcat-title">{{child.name}}</span>
                    <span class="subcat-quantity">{{child.quantity}}</span>
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
</mat-accordion>