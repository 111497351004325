
<mat-expansion-panel [expanded]="state"
                     class="is-bordered-desktop is-rounded"
                     hideToggle="true"
                     (opened)="state = true"
                     (closed)="state = false"
                     *ngIf="storedParams.length">
    <mat-expansion-panel-header>
        <mat-panel-description class="material-collapsable-button is-font-sm">
            <fa-icon [icon]="['fas','plus']" *ngIf="!state"></fa-icon>
            <fa-icon [icon]="['fas','minus']" *ngIf="state"></fa-icon>
        </mat-panel-description>
        <mat-panel-title *ngIf="configuration.colapsableTitle || configuration.collapsableSubtitle">
            <p class="collapsable-header mt-0 is-font-sm">
                <strong *ngIf="configuration.colapsableTitle">
                    {{ configuration.colapsableTitle }}
                </strong>
                <span *ngIf="configuration.collapsableSubtitle">
                    {{ configuration.collapsableSubtitle }}
                </span>
            </p>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="filter-saved-list-container pl-2"  role="listbox">
        <a *ngFor="let param of storedParams"
           (click)="onApplyFilter( param )"
           role="option"
           tabindex="0"
           [attr.aria-label]="'Apply filters saved ' + param">
           - {{ param }}
        </a>
    </div>
</mat-expansion-panel>

