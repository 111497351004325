<div
    *ngIf="configuration.addressGroups?.length > 0"
    class="footer-location">
    <div class="footer-section-container">
        <a [attr.href]="group.href" *ngFor="let group of configuration.addressGroups">
            <ul class="footer-address">
                <li style="font-weight: bold">{{group.location}}</li>
                <li
                    *ngFor="let item of group.items">
                    {{item}}
                </li>
            </ul>
        </a>
    </div>
</div>
<div
    class="footer-content">
    <div class="footer-section-container">
        <div
            *ngIf="configuration.logo?.src"
            class="footer-logo">
            <a
                *ngIf="!!configuration.logo.href"
                href="{{configuration.logo.href}}">
                <img
                    src="{{configuration.logo.src}}"
                    alt="{{configuration.logo.alt}}" />
            </a>
            <img
                *ngIf="!configuration.logo.href"
                src="{{configuration.logo.src}}"
                alt="{{configuration.logo.alt}}" />
        </div>

        <div class="nav-groups">
            <div
                *ngFor="let group of configuration.navGroups"
                class="footer-nav">
                <div class="title">{{group.name}}</div>
                <ul>
                    <li
                        *ngFor="let item of group.items;">
                        <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="footer-bottom-container">
    <div class="footer-section-container">
        <div class="footer-copyright-powered-by">
            <span class="copyright" *ngIf="configuration.copyright" [innerHtml]="getCopyright()"></span>
            <a
                *ngIf="configuration.poweredByLink"
                href="{{configuration.poweredByLink}}">Powered By
                <img src="{{configuration.poweredByLogo}}" />
            </a>
            <img
                *ngIf="configuration.poweredByLogo && !configuration.poweredByLink"
                src="{{configuration.poweredByLogo}}" />
        </div>
        <div class="space"></div>
        <ul
            *ngIf="configuration.subNavItems?.length > 0"
            class="footer-sub-nav">
            <li
                *ngFor="let item of configuration.subNavItems;">
                <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
            </li>
        </ul>
    </div>
</div>

<ng-template #navItem let-item>
    <ng-container
        [ngSwitch]="item.type">
        <a
            *ngSwitchCase="'a'"
            class="footer-nav-item"
            href="{{item.href}}">
            <span>{{item.content}}</span>
        </a>
        <a
            *ngSwitchCase="'a-ng'"
            class="footer-nav-item"
            [routerLink]="item.routerLink">
            <span>{{item.content}}</span>
        </a>
        <span
            *ngSwitchCase="'a-icons'"
            class="footer-nav-item">
            <span>{{item.content}}</span>
            <ul
                class="footer-social-media">
                <li
                    *ngFor="let icon of item.icons">
                    <a href="{{icon.href}}">
                        <i class="fa {{icon.icon}}"></i>
                    </a>
                </li>
            </ul>
        </span>
        <span
            *ngSwitchDefault
            class="footer-nav-item">
            {{item.content}}
        </span>
    </ng-container>
</ng-template>