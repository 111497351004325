import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core'
import { DOCUMENT, ViewportScroller } from '@angular/common'

// Services
import { CurrencyService } from './../../../services/currency.service'
import { ConfigurationService } from 'src/app/services/configuration.service'

interface IHeaderItem {
    element: string
    content?: string
    class?: string
    href?: string
    childs?: Array<IHeaderItem>
    styles?: any
    open?: boolean
    open_mobile_menu?: boolean
}

@Component({
    selector: '#ras-header',
    styleUrls: ['header.component.scss'],
    templateUrl: 'header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit  {
    private readonly menuActivateClass = 'active'
    private readonly mobileActivateClass = 'mobile-menu-open'

    public logoUrl: string
    public clientName: string
    public content: Array<IHeaderItem>
    public navigationItemOpen: Element

    @HostListener('document:click', ['$event'])
    documentClick( event: Event ) {
        if( this.navigationItemOpen && event.target !== this.navigationItemOpen ) {
            this.resetMenu()
        }
    }

    constructor(
        private configurationService: ConfigurationService,
        private currencyService: CurrencyService,
        private renderer: Renderer2,
        @Inject( DOCUMENT ) private document,
        private viewport: ViewportScroller,
    ) {}

    ngOnInit(): void {
        this.logoUrl = this.configurationService.getConfig( 'catalog.logo', null )
        this.clientName = this.configurationService.getConfig( 'catalog.clientName', null)
        this.content = this.configurationService.getConfig( 'catalog.header.content', [] )
    }

    onOpen( event: { data: any, element: Element } ) {
        // Open menu under the element
        if( event.data.open ) {
            this.resetMenu( event.element )
            this.toggleMenu( event.element )
        }
        // Open menu on mobile
        if( event.data.open_mobile_menu ) {
            this.toggleMenuMobile()
        }
    }

    onPageJump(link: string) {
        if (!link) {
            return
        }

        const element = this.document.querySelector(link)

        if(element) {
            this.viewport.scrollToPosition([0, element.offsetTop - 30 ])
        }
    }

    private resetMenu( element?: Element ) {
          if ( this.navigationItemOpen && (this.navigationItemOpen !== element || !element) ) {
            this.renderer.removeClass( this.navigationItemOpen, this.menuActivateClass )
        }
    }

    private toggleMenu( element: Element ) {
        this.navigationItemOpen = element

        if( this.navigationItemOpen.className && this.navigationItemOpen.className.includes( this.menuActivateClass ) ) {
            this.renderer.removeClass( this.navigationItemOpen, this.menuActivateClass )
            this.navigationItemOpen = null
        } else {
            this.renderer.addClass( this.navigationItemOpen, this.menuActivateClass )
        }
    }

    private toggleMenuMobile() {
        if( this.document.body.className && this.document.body.className.includes( this.mobileActivateClass ) ) {
            this.renderer.removeClass( this.document.body, this.mobileActivateClass)
        } else {
            this.renderer.addClass( this.document.body, this.mobileActivateClass)
        }
    }
}
