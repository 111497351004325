<ul class="ras-breadcrumb-container" *ngIf="configuration.display">
    <ng-container *ngIf="!configuration.isLoading && data; else loadingTemplate">
        <ng-container *ngFor="let link of configuration.links; first as isFirst">
            <li [class.has-link]="!!link.link" *ngIf="!isItemDisabled(link)">
                <a [routerLink]="link.link?.path" [queryParams]="getParams( link.link?.params)">
                    <i class="fa" [ngClass]="link.link?.icon" aria-hidden="true" *ngIf="isFirst"></i>
                    {{ replaceTemplate( link.label ) }}
                </a>
            </li>
        </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
        <li class="is-loading"></li>
        <li class="is-loading"></li>
        <li class="is-loading"></li>
    </ng-template>
</ul>
