import { Pipe, PipeTransform } from '@angular/core'

import { DATA_CONFIG } from '../config/data.config'

// this pipe is specific - to assets scale factors
@Pipe({
    name: 'percent'
})
export class PercentPipe implements PipeTransform {
    transform(value: number): any {
        if (value !== undefined && value !== null) {
            const val = Math.round(value*1000) / 10
            return !!(val % 1) ? val + '%' : val + '.0%'
        }
        return DATA_CONFIG.nullString
    }
}
