import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { of, Subject } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'
import get from 'lodash-es/get'
import { environment } from './../../environments/environment'
import { StorageService } from './storage.service'
import { UtilityService } from './utility.service'

interface IGLobalConfiguration {
    catalog: any
    components: any
    filter: any
    structures: any
    localization: any
    custom: any
    headers: any
    footers: any
}

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private readonly TEMP_CONFIG_KEY = 'temp_config'

    public configuration: IGLobalConfiguration
    public $configuration = new Subject<IGLobalConfiguration>()
    private TTL = environment.ttl

    constructor(
        private http: HttpClient,
        private storage: StorageService,
    ) {}

    getConfig(keyPath: string, defaultValue = null) {
        return get(this.configuration, keyPath, defaultValue)
    }

    get(isConfigTool: boolean = false) {
        if(!this.configuration) {
            if (isConfigTool && this.checkBrowserStorageHasConfig()) {
                // config tool should use the local config if it exists
                const tempConfig = this.getBrowserStorageConfig()
                this.configuration = tempConfig
                this.$configuration.next(tempConfig)
                return of(this.configuration)
            }

            // if a local config didn't exist, or we're not in config tool, load config from cloud via API
            const options: any = {
                'trace-id': UtilityService.getUUID(`cat.******-******-******.config`),
            }

            if( environment.client ) {
                options.Client = environment.client
                options.Environment = environment.production ? 'production' : 'develop'
            }

            return this.http.get(`${environment.data.apiLocal ? environment.data.apiLocal : environment.data.api}configuration/${!!environment.data.apiEnvironment ? `?env=${environment.data.apiEnvironment}` : ''}`, { headers: new HttpHeaders(options) })
                .pipe(
                    tap((data: IGLobalConfiguration) => {
                        this.configuration = data
                        this.$configuration.next(data)
                    }),
                    debounceTime(this.TTL),
                )
        }
        return of(this.configuration)
    }

    hasConfig(keyPath: string): boolean {
        return !!get(this.configuration, keyPath)
    }

    saveToBrowserStorage(config: any): void{
        this.storage.set(this.TEMP_CONFIG_KEY, config)
    }

    clearBrowserStorage(): void {
        this.storage.remove(this.TEMP_CONFIG_KEY)
    }

    getBrowserStorageConfig(): any {
        return this.storage.get(this.TEMP_CONFIG_KEY)
    }

    checkBrowserStorageHasConfig(): boolean {
        return !!this.getBrowserStorageConfig()
    }
}
