import { DOCUMENT, ViewportScroller } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import { union } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import isArray from 'lodash-es/isArray'

// Services
import { ConfigurationService } from '../../../services/configuration.service'
import { SearchService } from './../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Pipes
import { RasCurrencyPipe } from './../../../pipes/ras-currency.pipe'
import { NumberPipe } from './../../../pipes/number.pipe'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { ISummaryComponentConfiguration } from 'src/app/interfaces/components/component-summary.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ModalService } from 'src/app/services/modal.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

export const SummaryComponentDefaults: ISummaryComponentConfiguration = {
    type: 'detail_summary',
    display: true,
    devMode: false,
    fixedOnScroll: true,
    showTitle: false,
    showMake: false,
    showModelYear: false,
    showLastUpdate: false,
    showLocation: false,
    showFullLocation: false,
    showHours: false,
    showEmail: false,
    showEquipmentNumber: false,
    showEquipmentSerial: false,
    showMonthlyPayment: false,
    showPrice: false,
    showDiscountedPrice: false,
    labelLastUpdate: 'Listing updated: {{listing_date}}',
    labelMake: 'Available Manufacturers',
    labelMonthlyPayment: 'Finance for as low as ${{lowest_monthly_payment}} / month',
    labelMonthlyPaymentApply: 'Apply now',
    labelModelYear: 'Available Model Years',
    labelPriceOnLowQuantity: 'Supply Limited, Act Now',
    labelStickySubtitle: '{{display_equipment_number}} | {{sub_category}}',
    labelStickyMeterMiles: '{{meter_miles}} miles',
    labelStickyMeterHours: '{{meter_hours}} hours',
    labelSubTitle: '{{sub_category}}',
    linkMonthlyPaymentApply: '',
    srcFinancingAvailable: '',
    financingAvailableText: '',
    fieldTitle: '',
    stickyHeaderClassLink: 'form-header',
    urlMonthlyPaymentApply: '',
    limitMake: 2,
    quantityPriceOnLowQuantity: 5,
    linkGoogleMaps: false,
    iframeUrl: '',
    iframeHeight: '0',
}

export const SummaryComponentDevOpts: IComponentDevOptions = {
    note: 'Copy/paste an asset ID from any equipment details page to load data here.  E.g. "A43108709" found in the url https://xyz-catalog.develop.rouseservices.com/equipment/detail/A43108709',
    config: {
        ...SummaryComponentDefaults,
        devMode: true,
        dataGroup: 'detail-equipment',
        assetId: '',
        showTitle: true,
        showMake: true,
        showModelYear: true,
        showLastUpdate: true,
        showLocation: true,
        showFullLocation: true,
        showHours: true,
        showEmail: true,
        showEquipmentNumber: true,
        showEquipmentSerial: true,
        showMonthlyPayment: true,
        showPrice: true,
        showDiscountedPrice: true,
    },
}

@Component({
    selector: 'ras-detail-summary',
    styleUrls: ['./summary.component.scss'],
    templateUrl: './summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent implements OnInit, OnDestroy, OnChanges, IBaseComponent {
    private $destroy = new Subject<void>()
    private $scroll = new Subject<void>()
    private isVisible: boolean

    public isLoading: boolean
    public isFixed = false
    public isMakeFullView = false
    public email
    public sanitizedSource: SafeResourceUrl

    @Input() configuration: ISummaryComponentConfiguration
    @Input() data: any

    @HostListener( 'window:scroll' )
    onScroll() {
        if( this.isVisible && this.configuration.fixedOnScroll ) {
            this.$scroll.next()
        }
    }
    @HostListener( 'window:resize' )
    onResize() {
        this.isVisible = this.elementRef.nativeElement.offsetHeight > 0
    }

    constructor( private searchService: SearchService,
                 private ref: ChangeDetectorRef,
                 private numberPipe: NumberPipe,
                 private currencyPipe: RasCurrencyPipe,
                 private viewportScroller: ViewportScroller,
                 private elementRef: ElementRef,
                 private configurationService: ConfigurationService,
                 private modalService: ModalService,
                 private sanitizer: DomSanitizer,
                 @Inject(DOCUMENT) private document: Document ) {}

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, SummaryComponentDefaults)

        this.loadData()

        this.$scroll
            .pipe(
                takeUntil( this.$destroy ),
                debounceTime( 50 )
            ).subscribe( () => this.onScrollChange() )

        if (this.configuration.srcFinancingAvailable) {
            this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.configuration.srcFinancingAvailable}&lnotes=${encodeURIComponent(window.location.href)}`)
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.configuration?.currentValue?.dataGroup !== changes.configuration?.previousValue?.dataGroup
            || changes.configuration?.currentValue?.assetId !== changes.configuration?.previousValue?.assetId
        ) {
            this.loadData()
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    loadData(): void {
        this.isLoading = true
        if (this.configuration.dataGroup) {
            if (this.configuration.devMode) {
                if (isEmpty(this.configuration.dataGroup) || isEmpty(this.configuration.assetId)) {
                    return
                }
                this.searchService.setEndpointParam(this.configuration.dataGroup, {
                    key: 'assetId',
                    value: this.configuration.assetId,
                })
            }
            this.searchService.requestData( this.configuration.dataGroup )
                .pipe(
                    takeUntil( this.$destroy )
                )
                .subscribe( data =>  {
                    this.isLoading = false
                    this.data = data ? data : {}
                    this.email = this.configurationService.getConfig('catalog.flyer_email')
                    this.isMakeFullView = false
                    this.isVisible = this.elementRef.nativeElement.offsetHeight > 0
                    this.ref.markForCheck()
                })
        }
    }

    onScrollChange(): void {
        if ( this.elementRef.nativeElement.getBoundingClientRect().top <= -70 ) {
            if( !this.isFixed ) {
                this.isFixed = true
                this.ref.detectChanges()
            }
        } else {
            if ( this.isFixed ) {
                this.isFixed = false
                this.ref.detectChanges()
            }
        }

    }

    onScrollToForm() {
        const elements = this.document.querySelectorAll( `.${ this.configuration.stickyHeaderClassLink }` )
        const element: Array<any>  = Array.from(elements).filter( (el: any) => el.offsetParent !== null)
        this.viewportScroller.scrollToPosition( [0, element[0].offsetTop - 30 ] )
    }

    onOpenModal() {
        this.modalService.addModal({
            modalId: 'app-modal',
            content: [
                {
                    type: 'iframe',
                    iframeUrl: this.getSourceURL(),
                  }
            ],
            styles: {
                'content__border-radius': '10px',
                content__width: '50%',
                content__height: '70%',
            }
        } )
    }

    getSourceURL(): SafeResourceUrl {
        return this.sanitizedSource
    }

    replaceTemplate( template: string, type: string = '' ) {
        const splitted = template.split(/({{)(.*)(}})/)
        return splitted[ 0 ] + ( !!this.data[splitted[2]] ? this.formatData( this.data[ splitted[ 2 ] ], type ) : '' ) + (!!splitted[ 4 ] ? splitted[ 4 ] : '')
    }

    parseTemplate( template: string ) {
        return UtilityService.replaceTemplate(template, this.data)
    }

    getFormatted( data: string | Array<string>, unionChar: string = '', prefixChar: string = '', type: string = '') {
        switch( typeof data ) {
            case 'string':
            case 'number':
                return `${ prefixChar }${ this.formatData( data, type ) }`
            case 'object':
                return union( data.map( item => prefixChar + this.formatData( item, type ) )).join( `${ unionChar }` )
        }
    }

    isLimitedSupply( equipment: any ) {
        return this.configuration.labelPriceOnLowQuantity && equipment.quantity && equipment.quantity <= this.configuration.quantityPriceOnLowQuantity
    }

    buildGoogleMapsHref(data) {
        const addr = []
        if (data.branch_address) {
            addr.push(`${data.branch_address}`)
        }
        if (data.branch_city) {
            addr.push(`,${data.branch_city.toLowerCase()}`)
        }
        if (data.branch_state) {
            addr.push(`,${data.branch_state}`)
        }
        if (data.branch_zipcode) {
            addr.push(`,${data.branch_zipcode}`)
        }
        // format the address to work as a maps query
        const query = addr.join('') // join into 1 string
            .split(',').join('%2C') // replace all commas with %2C
            .split(' ').join('+') // replace all spaces with +
        return `https://www.google.com/maps/search/?api=1&query=${query}`
    }

    buildAddress(data) {
        const useFullLocation = !!this.configuration.showFullLocation
        const addr = []
        if (data.branch_address && useFullLocation) {
            addr.push(`${data.branch_address}<br />`)
        }
        if (data.branch_city) {
            addr.push(`${data.branch_city.toLowerCase()},`)
        }
        if (data.branch_state) {
            addr.push(`${data.branch_state}`)
        }
        if (data.branch_zipcode && useFullLocation) {
            addr.push(` ${data.branch_zipcode}`)
        }
        return addr.join(' ')
    }

    buildHours(hoursData) {
        return hoursData.split(',').join('<br />')
    }

    private formatData( data: string | number, type: string ) {
        if ( !type ) {
            return data
        }

        switch ( type ) {
            case 'money':
                return this.currencyPipe.transform( parseInt(data as string, 10), this.data, false, true )
            case 'number':
                return this.numberPipe.transform( data as number )
            default:
                return data
        }
    }

    isArray(data): boolean {
        return isArray(data)
    }

}
