import { Directive, Optional, HostListener } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

@Directive({ selector: '[ac-keydown]' })
export class AutocompleteDirective {
    constructor(@Optional() private trigger: MatAutocompleteTrigger) { }

    @HostListener('keydown.arrowdown', [ '$event.target' ])
    @HostListener('keydown.arrowup', [ '$event.target' ])
    onBlur(thing: any) {
        if (this.trigger.activeOption) {
            this.trigger.writeValue(this.trigger.activeOption.value)
        }
    }
}
