<div class="control__container">
    <div class="control__header">
        <label
            class="control__label"
            [for]="labelId">{{label ? label : key}}</label>
        <tool-help
            [key]="key"
            [content]="options?.help"></tool-help>
    </div>
    <div class="control__inputs">
        <input
            #enabledButton
            class="control__enable-button"
            type="checkbox"
            [checked]="enabled"
            (change)="processEnable($event)" />
        <input
            #colorPicker
            *ngIf="!overrideEnabled"
            class="color-picker"
            type="color"
            [value]="currentValue"
            [id]="labelId"
            (input)="processChange($event)" />
        <input
            #overrideInput
            *ngIf="overrideEnabled"
            class="control__override-field"
            type="text"
            [value]="currentValue"
            [id]="labelId"
            (change)="processOverride($event)" />
        <i
            class="fa fa-cog control__override-button"
            (click)="toggleOverride()"></i>
    </div>
</div>