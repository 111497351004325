<p class="filter-range-title" [class.is-loading]="isLoading | async">
    <span>{{ (minValueSelected || minValue) | rasCurrency: {} : false: true}} </span>
    -
    <span>
        {{ (maxValueSelected || maxValue) | rasCurrency: {} : false : true}}
        <i *ngIf="maxValueSelected === configuration.pricingMaxValue">+</i>
    </span>
</p>

<ras-filter-range-slider [selectedMax]="maxValueSelected"
                         [selectedMin]="minValueSelected"
                         [max]="maxValue"
                         [min]="minValue"
                         [increment]="configuration.pricingIncrement"
                         (change)="onChange($event)">
</ras-filter-range-slider>

