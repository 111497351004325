import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'
import { filter, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { CurrencyService } from './../services/currency.service'

@Pipe({
    name: 'rasCurrency',
    pure: false
})
export class RasCurrencyPipe implements PipeTransform, OnDestroy {
    private $destroy = new Subject<void>()
    private currentCurrency: string
    private currentCurrencyChar: string
    private curentCurrencyRate: number
    private skipNativePrice: boolean
    private value: number | Array<number>
    private initialValue: number | Array<number>
    private data: any

    constructor(private currencyPipe: CurrencyPipe,
                private currencyService: CurrencyService,
                private ref: ChangeDetectorRef) {
        this.currencyService.selectedCurrency
            .pipe(
                takeUntil(this.$destroy),
                filter(d => !!d)
            )
            .subscribe(data => {
                this.currentCurrency = data.code
                this.currentCurrencyChar = data.char
                this.curentCurrencyRate = data.rate
                this.value = this.applyLogic(this.initialValue, this.data, this.currentCurrency, this.skipNativePrice)
                this.ref.markForCheck()
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    transform(value: number | Array<number>, data: any, showCurrencyCode?: boolean, skipNativePrice?: boolean): string {
        if (!this.value) {
            this.initialValue = value
            this.data = data
        }

        this.value = this.applyLogic(value, data, this.currentCurrency, skipNativePrice)
        this.skipNativePrice = skipNativePrice

        if (Array.isArray(value)) {
            return (this.value as Array<number>).map(v => this.currencyPipe.transform(
                    v as number,
                    this.currentCurrency.toUpperCase(),
                    this.currentCurrencyChar, '1.0-0'
                )).join(' - ') + (showCurrencyCode ? ` ${this.currentCurrency.toUpperCase()}` : '')
        }
        return this.currencyPipe.transform(
            this.value as number,
            this.currentCurrency.toUpperCase(),
            this.currentCurrencyChar, '1.0-0'
        ) + (showCurrencyCode ? ` ${this.currentCurrency.toUpperCase()}` : '')
    }

    private applyLogic(value: number | Array<number>, data: any, currentCurrency: string, skipNativePrice: boolean) {
        if (Array.isArray(value)) {
            return value.map(v => this.ap(v, data, currentCurrency, skipNativePrice))
        }
        return this.ap(value as number, data, currentCurrency, skipNativePrice)
    }

    private ap(value: number, data: any, currentCurrency: string, skipNativePrice: boolean) {
        if (data && !skipNativePrice) {
            if (data.list_price_native_currency?.toLowerCase() === currentCurrency.toLowerCase()) {
                return data.list_price_native ? data.list_price_native : value
            } else {
                return value * this.curentCurrencyRate
            }
        }

        return value * this.curentCurrencyRate
    }
}
