import { Pipe, PipeTransform } from '@angular/core'

// Pipes
import { NumberPipe } from './number.pipe'

/**
 * Transforms equipment miles and hours to the required format.
 *
 * @usageNotes
 *
 * The following example defines a view that allows the user to enter
 * an equipment object, meter or miles field and then uses the pipe
 * to convert the input with the correct format, miles formatting or
 * only show the correct meter.
 *
 * @example
 * !!(equipment | rasMeter)
 * {{ data.meter_miles | rasMeter:"miles" }}
 * {{ data.meter_hours | rasMeter:"type:hours" }}
 * {{ data | rasMeter }}
 *
 */
@Pipe({
    name: 'rasMeter',
})
export class RasMeterPipe implements PipeTransform {
    private optSplit = new RegExp('(:[\s]?)')

    constructor(private numberPipe: NumberPipe) {}

    transform(equipment: any, args?: string): any {
        const isObject: boolean = typeof equipment === 'object'
        let isMiles: boolean = false
        let isHours: boolean = false

        if (!!args) {
            args.split(',').forEach(opt => {
                const [key, value] = opt.split(this.optSplit)
                switch (key) {
                    case 'type':
                        isHours = value.startsWith('hour')
                        isMiles = value.startsWith('mile')
                        break
                }
            })
        }

        if (!isObject && (isMiles || isHours)) {
            equipment = {
                meter_hours: isHours ? equipment : 0,
                meter_miles: isMiles ? equipment : 0,
            }
        } else if (!isObject && !isMiles && !isHours) {
            throw new Error('Meter Pipe: Invalid input.')
        }

        if (isHours || ('meter_hours' in equipment && equipment.meter_hours > 0)) {
            return this.numberPipe.transform(equipment.meter_hours) + ' hour' + (equipment.meter_hours > 1 ? 's' : '')
        } else if (isMiles || ('meter_miles' in equipment && equipment.meter_miles > 0)) {
            return this.numberPipe.transform(equipment.meter_miles) + ' mile' + (equipment.meter_miles > 1 ? 's' : '')
        }
    }
}
