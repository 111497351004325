import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { SearchService } from 'src/app/services/search.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { UtilityService } from 'src/app/services/utility.service'

// Components
import { Clipboard } from '@angular/cdk/clipboard'
import { MatSnackBar } from '@angular/material/snack-bar'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IShareConfiguration } from 'src/app/interfaces/components/component-share.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const ShareComponentDefaults: IShareConfiguration = {
    type: 'share',
    display: true,
    messageCopy: 'Url copied!',
    download: true,
    shareTitle: 'Share it',
    downloadTitle: 'Download the Flyer',
}

export const ShareComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ShareComponentDefaults,
    },
}

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()

    @Input() configuration: IShareConfiguration
    @Input() data: any

    constructor( private clipboard: Clipboard,
                 private notify: MatSnackBar,
                 private searchService: SearchService,
                 private configurationService: ConfigurationService,
                 private ref: ChangeDetectorRef,
                 @Inject( Window ) private window  ) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, ShareComponentDefaults)
        if( this.configuration.dataGroup ) {
            this.searchService
                .requestData( this.configuration.dataGroup )
                .pipe(
                    takeUntil( this.$destroy )
                )
                .subscribe( (data: any) =>  {
                    this.data = {...data}
                    this.data.branch_phone = this.data.branch_phone ? this.data.branch_phone : this.configurationService.getConfig('catalog.phone')
                    this.ref.markForCheck()
                })
        }
    }

    ngOnDestroy() {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onShare() {
        const url = this.window.location.href
        if ( navigator.share ) {
            navigator.share( { url } )
        } else {
            this.clipboard.copy( url )
            this.notify.open(
                    this.configuration.messageCopy,
                    'Share',
                    {
                        duration: 1000
                    }
                )
        }
    }
}
