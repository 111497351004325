import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import get from 'lodash-es/get'
import flatten from 'lodash-es/flatten'
import isEmpty from 'lodash-es/isEmpty'
import { ConfigurationService } from './configuration.service'
import { StorageService } from './storage.service'
import { RasCurrencyPipe } from '../pipes/ras-currency.pipe'
import { DatePastPipe } from '../pipes/datePast.pipe'

type TParamQueryValue = string | Array<string> | null

interface IPaginationSortField {
    name: string
    type: string
}

interface IPaginationSort {
    direction: 'desc' | 'asc'
    field: IPaginationSortField
}

interface IPagination {
    index: number
    size: number
    direction: IPaginationSort | string
    field: string
}

type IRequestFilterLogic = Record<string, [{var: string}, string | Array<string> ]>

interface IRequestFilter {
    and: Array<IRequestFilterLogic>
}

interface IRequest {
    filters: IRequestFilter
    pagination: IPagination
}

interface IParamQueryValue {
    operator: string
    key: string
    value: TParamQueryValue
    label: string
    logic?: any
    excluded?: boolean
}

type IParamsQuery = Record<string, IParamQueryValue>

type IParamQueryGroup = Record<string, IParamsQuery>

interface IParamsChangedData {
    onAction?: string
    forKey?: string
}

interface IOnChangeResponse {
    onAction: string
    forKey: string
    execute: Array<IOnChangeExecute>
}

interface IOnChangeExecute {
    action: string
    data_group: string
    key: string
    value?: any
}

@Injectable({ providedIn: 'root' })
export class QueryService {
    private readonly storageKeyName = 'ras-params'
    private readonly timeToDebounce = 600
    /**
     * Holds params under data groups
     */
    private params: Record<string, BehaviorSubject<IParamQueryValue>> = {}
    /**
     * Holds flat params
     */
    private flatParams: Record<string, string> = {}
    /**
     * Holds the default params, in some cases default params are needed for certain components
     * The default params will be added to specific data group request
     * If there is a param with the same data group and key under the global params, it gets override
     * Basically, only get's used if there is no param set, it uses this default one
     */
    private paramsDefault: Record<string, IParamQueryValue> = {}
    /**
     * Holds the array of keys needed to be retrieved to the backend
     * Add a key to retrieve specific data for a specific filter
     */
    private paramsToFetch: Record<string, BehaviorSubject<Array<string>>> = {}
    /**
     * Holds the data needed for each filter
     * TODO: implement the paramsData as an Object of Subjects, same as this.params and this.paramsToFetch
     */
    public paramsData = new BehaviorSubject( null )
    /**
     * Holds the loading state of the filters
     * TODO: implement the isLoading as an Object of Subjects, same as this.params and this.paramsToFetch
     */
    public isLoading = new BehaviorSubject( false )
    /**
     * Keeps track of the amount of params availables
     * TODO: implement the isLoading as an Object of Subjects, same as this.params and this.paramsToFetch
     */
    public paramsCount = new BehaviorSubject( 0 )
    /**
     * Acts as the belt for when params changed
     * Every time a params was added, the belt rings
     * Every time the belt rings, the URL state will change (only if a new state is set, the Angular router handles this)
     */
    private paramsChanged = new BehaviorSubject( null )

    private storedParams = new BehaviorSubject( {} )

    constructor(
        private configurationService: ConfigurationService,
        private route: ActivatedRoute,
        private router: Router,
        private storage: StorageService,
        private currencyPipe: RasCurrencyPipe,
        private datePastPipe: DatePastPipe,
    ) {
        // The search services subscribes to the query params changes and sets the data
        // This is the initial entry point to feed the query logic
        this.route.queryParams
            .pipe(
                debounceTime( this.timeToDebounce ),
                distinctUntilChanged()
            )
            .subscribe( params => {
                this.replaceFromParams( params )
            })

        // When params were added or removed, trigger the write back to the url
        // This is the the output to feed the query logic from the URL query params
        this.paramsChanged
            .pipe(
                debounceTime( this.timeToDebounce )
            )
            .subscribe( ( data: IParamsChangedData ) => {
                if (data) {
                    this.processParamsChangedActions(data)
                }

                this.router.navigate([], {
                    queryParams: this.getAllParamsAsQueryParams()
                })
            })

        // Observes and sets the params form the storage
        this.storedParams.next( this.storage.get( this.storageKeyName ) )
        this.storage.observe( this.storageKeyName )
            .subscribe( ( value ) => {
                this.storedParams.next( value )
            })
     }

     processParamsChangedActions(data: IParamsChangedData) {
        const { onAction, forKey } = data
        const onChange: Array<IOnChangeResponse> = this.configurationService.getConfig(`filter.on_change`)
        if (!!onChange) {
            onChange
                .filter((res: IOnChangeResponse) => res.onAction === onAction && res.forKey === forKey)
                .forEach((res: IOnChangeResponse) => {
                    const { execute } = res
                    if (!!execute) {
                        execute.forEach((exe: IOnChangeExecute) => {
                            const { action, data_group, key, value } = exe
                            switch (action) {
                                case 'add':
                                    this.add(data_group, key, value)
                                    break
                                case 'remove':
                                    this.removeParamKey(data_group, key)
                                    break
                            }
                        })
                    }
                })
        }
     }

    /**
     * Adds a single query to the params object
     * Performs the assignation of the logic and operators, based on the global configuration {filter.operators}
     * Performs the assignation of the labels, based on the global configuration {filter.labels}
     * @param dataGroup identifier of the group of data that the query affects
     * @param key identifier of the query param key
     * @param value string or array of string in the case of a multiselect
     */
    add( dataGroup: string, key: string, value: string | Array<string> ) {
        if (dataGroup) {
            if ( !this.params[ dataGroup ] ) {
                this.params[ dataGroup ] = new BehaviorSubject( null )
            }

            const params: IParamQueryValue = {
                ...this.params[ dataGroup ].getValue(),
                [ key ]: this.createParam( key, value )
            }

            this.updateLabelsWithAdditionalParamData(params)

            // Pagination of page needs to be reseted if any other filter is applied, otherwise the request is wrong
            if( key !== 'page' && this.hasParam(`${ dataGroup }:pagination`, 'page') ) {
                this.removeParamKey(`${dataGroup}:pagination`, 'page')
            }
            // Set the new data group state
            this.params[ dataGroup ].next( params )
            // Trigger the bells and update the counter
            this.paramsCount.next( this.getParamsCount( dataGroup ) )
            this.paramsChanged.next({
                onAction: 'add',
                forKey: key,
            })
        } else {
            this.flatParams[ key ] = value as string
        }
    }

    /**
     * This allows param labels to reference other param values.  Because the labels are
     * added during creation, there may be a param not yet added to the array that a label
     * wants to reference.  To solve for this, we can run this each time a new param
     * is added to check for any labels that are trying to reference other params.
     * @param params the current params assigned to this.params
     */
    updateLabelsWithAdditionalParamData(params: any) {
        Object.entries(params).forEach(([key, param]: [string, IParamQueryValue]) => {
            try {
                const matches = param.label?.matchAll(/{{(.+?)}}/g)
                for (const match of matches) {
                    const replaceStr = match[0]
                    const otherParamData = params[match[1]]?.value
                    if (!!otherParamData) {
                        param.label = param.label.replace(replaceStr, otherParamData)
                    }
                }
            } catch {}
        })
    }

    /**
     * Removes an entire parameter key, no matter if it multi value or single value
     * @param dataGroup name of the data group
     * @param key name of they to be removed
     */
    removeParamKey( dataGroup: string, key: string ) {
        if (dataGroup) {
            this.params[dataGroup].next(
                {
                    ...this.params[dataGroup].getValue(),
                    [ key ]: null
                }
            )
            this.paramsCount.next( this.getParamsCount(dataGroup) )
            this.paramsChanged.next({
                onAction: 'remove',
                forKey: key
            })
        } else {
            delete this.flatParams[key]
        }
    }

    /**
     * Removes query parameters from the store
     * @param key identifier of the parameter to be removed
     * @param value in the case of multi selection values, we can remove only one specific value
     */
    remove( dataGroup: string, key: string, value: string ) {
        let paramValue = null

        // If a param is flat, just delete it
        if (!dataGroup) {
            delete this.flatParams[key]
            return
        }

        const paramGroup = this.params[ dataGroup ]

        // Return if the group does not exists
        if ( !paramGroup ) {
            return
        }

        const param = paramGroup.getValue()[ key ]

        // Return if the param does not exists
        if( !param ){
            return
        }

        // If the param is multi value, we need to pop only the value
        if( typeof param.value !== 'string' ) {
            const filtered = param.value.filter( item => item !== value )
            // If there is any value, otherwise we leave it null
            if ( filtered.length ) {
                paramValue = { operator: param.operator, key: param.key, value: filtered }
            }
        }

        this.params[ dataGroup ].next(
            {
                ...this.params[ dataGroup ].getValue(),
                [ key ]: paramValue
            }
        )

        this.paramsCount.next( this.getParamsCount( dataGroup ) )
        this.paramsChanged.next({
            onAction: 'remove',
            forKey: key,
        })
    }

    /**
     * Subscribe to getParams to apply any logic whenever the parameters changed, it has a debounced time applied already
     */
    getParamValue( groupData: string, key: string ): TParamQueryValue {
        if (!groupData) {
            return this.flatParams[key]
        }
        const params = this.params[ groupData ].getValue()

        if ( !params || !params[ key ] ) {
            return null
        }
        return params[ key ].value ? params[ key ].value : null
    }

    /**
     * Subscribe to getParams to apply any logic whenever the parameters changed, it has a debounced time applied already
     */
    getParams( dataGroup: string ): Observable<any> {
        if (!this.params[ dataGroup ]) {
            this.params[ dataGroup ] = new BehaviorSubject(null)
        }

        return this.params[dataGroup].pipe( debounceTime(this.timeToDebounce) )
    }

    /**
     * Method to get params that are not nested
     */
    getCustomParams(params: Record<string, string>): Record<string, string> {
        return { ...params, ...this.flatParams }
    }

    /**
     * Checks if a specific value is under the selected params
     * @param key the key to search in the params subject
     * @param value if we are looking for a specific value within a key, useful for multi selectors
     * @returns true or false
     */
    hasParam( groupData: string, key: string, value?: string ): boolean {
        if (!groupData) {
            return !!value ? this.flatParams[key] === value : !!this.flatParams[key]
        }
        // If the groupData does not exists or is not yet initialized, retrieve as false
        if( !this.params[groupData] ) {
            return false
        }
        const param = this.params[groupData].getValue()
        //  No params so return
        if (!param) {
            return false
        }

        if (!param[key]) {
            return false
        }

        const paramValueWithType = this.getParamValueWithType( param[ key ] )

        // In the case we are searching for a specific value in an array of values within a key
        if ( !!value && typeof paramValueWithType === 'object' ) {
            return paramValueWithType.includes( value )
        }

        // In case we are searching for a value within a key
        if ( !!value ) {
            return paramValueWithType === value
        }

        if( !value ) {
            return param[key]
        }

        // In the case we are looking only for the key, return the value formated
        return paramValueWithType
    }

    /**
     * Replaces the entire params based on a change of the query params
     * @param params object with the params to be replaced
     */
    replaceFromParams( params: any ) {
        if (!params) {
            return
        }

        // Clear the object first
        Object.keys( this.params ).forEach( key => {
            this.params[ key ].next(null)
        })

        Object.keys( params ).forEach( key => {
            const keyParam = key.split('.')
            const dataGroup = keyParam[1] ? keyParam[0] : undefined
            const querykey = keyParam[1] ? keyParam[1] : key
            const value  = params[key]

            const dependency = this.configurationService.getConfig( `filter.dependency.${ querykey }`)

            if( dependency && !params[ dependency ] ) {
                this.addParamToFetch( dataGroup, dependency )
            }

            this.add( dataGroup, querykey, value )
        })
    }

    /**
     * Ad
     */
    addDefaultParam( dataGroup: string, key: string, value: string | Array<string> ) {
        if ( !this.paramsDefault[ dataGroup ] ) {
            this.paramsDefault[ dataGroup ] = null
        }

        const params: IParamQueryValue = {
            ...this.paramsDefault[ dataGroup ],
            [ key ]: this.createParam( key, value )
        }

        // Set the new data group state
        this.paramsDefault[ dataGroup ] =  params
    }

    getDefaultParamsGroup( dataGroup: string ) {
        if ( !this.paramsDefault[ dataGroup ] ) {
            return {}
        }
        return this.paramsDefault[ dataGroup ]
    }


    /**
     * Removes a specific group of params by the Data group Key
     * @param dataGroup key string of the Data group to be removed
     */
    removeGroup( dataGroup: string ) {
        this.params[ dataGroup ].next( null )
        this.paramsCount.next( 0 )
        this.paramsChanged.next( null )
    }

    /**
     * Retrieves all the params available for a specific data group
     * @param dataGroup key of the data group
     */
    getParamsGroup( dataGroup: string ) {
        if ( !this.params[ dataGroup ] || !this.params[ dataGroup ].getValue() ) {
            return {}
        }

        return  this.params[ dataGroup ].getValue() ? this.params[ dataGroup ].getValue() : null
    }

    /**
     * Retrieves if the group data is available
     * @param groupData key string of the data group to search if it exists
     */
    hasGroupData( groupData: string ): boolean {
        return !!this.params[ groupData  ]
    }

    /**
     * Retrieves the pagination object needed by the backend to perform pagination and sorting
     * @param dataGroup key of the data group
     */
    getParamsGroupPagination( dataGroup: string ): IPagination {
        // Retrieve the params from the global
        const pagination = this.getParamsGroup( `${ dataGroup }:pagination` )
        // Retrieves the params from the default value
        const paginationDefault = this.getDefaultParamsGroup( `${ dataGroup }:pagination` )
        // Need to merge the default pagination set by the component, and the one from the user or query parameter from the URL
        // Since the { pagination } obejct is the last on the spread operator, it will override any default value
        const mergedPagination = {...paginationDefault, ...pagination}

        const paginationForBackend: IPagination = {
            size: parseInt( get( mergedPagination, 'size.value', "30" ), 10),
            index: parseInt( get( mergedPagination, 'page.value', "0" ), 10),
            direction: get( mergedPagination, 'direction.value', 'asc' ),
            field: get( mergedPagination, 'field.value', '' )
        }

        return paginationForBackend
    }

    /**
     * Adds the param to the filters field request
     * @param dataGroup key of the data group it belongs
     * @param param field key to be added at the filters fields request
     */
    addParamToFetch( dataGroup: string, param: string | Array<string> ) {
        if ( !this.paramsToFetch [ dataGroup ] ) {
            this.paramsToFetch[ dataGroup ] = new BehaviorSubject( [] )
        }
        if( !this.paramsToFetch[ dataGroup ].getValue().includes(param as string)) {
            this.paramsToFetch[ dataGroup ].next( flatten( [ ...this.paramsToFetch[ dataGroup ].getValue(), param ] ) )
        }
    }

    /**
     * Removes the param from the filters field request
     * @param dataGroup key of the data group it belongs
     * @param param field key to be added at the filters fields request
     */
    removeParamToFetch( dataGroup: string, param: string ) {
        this.paramsToFetch [ dataGroup ].next( this.paramsToFetch[ dataGroup ].getValue().filter( key => key !== param ) )
    }

    /**
     * Retrieves a set of fields registered for a specific data group
     * @param dataGroup key of the data group to request the filter fields
     */
    getParamToFetch( dataGroup: string ): Array<string> {
        if ( this.paramsToFetch[ dataGroup ] ) {
            return this.paramsToFetch[ dataGroup ].getValue()
        }
        return []
    }

    /**
     * Retrieves
     * @param params object of parameters to be flattened
     */
    getParamsGroupAsQueryParams( dataGroup: string ) {
        const paramGroup = this.params[ dataGroup ]

        // Return if the group does not exists
        if ( !paramGroup ) {
            return
        }
        const params = paramGroup.getValue()

        const flattenParams = {}

        if( !isEmpty( params ) ) {
            Object.keys( params ).forEach( key =>  {
                if( params[ key ] ) {
                    // Checks if the value needs to be transformed
                    if( this.configurationService.hasConfig(`filter.transform_value.${key}`) ) {
                        params[ key ].value = this.getTransformedValue(key, params[ key ].value)
                    }
                    // Need to force single value arrays to be strings as query params
                    // Otherwise the router get called again with a different value type of array triggering again the changes
                    flattenParams[ `${ dataGroup }.${ key }` ] = ( Array.isArray(params[ key ].value) && params[ key ].value.length === 1 )
                        ? params[ key ].value[0]
                        : params[ key ].value
                }
            })
        }
        return flattenParams
    }

    /**
     * Retrieves all params available in all the data groups in a flattened object
     */
    getAllParamsAsQueryParams() {
        let flattenParams = { ...this.flatParams }

        Object.keys( this.params ).forEach( paramKey => {
            const paramsGroup = this.getParamsGroupAsQueryParams( paramKey )
            flattenParams = {...flattenParams, ...paramsGroup }
        })

        return flattenParams
    }

    /**
     * Retrieves the current params as JSON logic
     * Applies all logic to exclude and map certain keys to others
     * Applies the logic to set the default params and the user ones
     * It is sent to the backend so it can apply the conditionals
     * @param groupData key string of the data group to retrieve the JSON logic
     */
    getParamsAsJsonLogic( groupData: string ) {
        if (!this.params[ groupData ]) {
            this.params[ groupData ] = new BehaviorSubject( null )
        }

        const params = this.params[ groupData ].getValue()
        const paramsDefault = this.paramsDefault[ groupData ]

        const paramsMerged = { ...paramsDefault, ...params }
        const paramsWithLogic = []

        if( paramsMerged ) {
            Object.keys( paramsMerged ).forEach( key =>
                {
                  if ( paramsMerged[ key ] ) {
                        // If the param is excluded, we don't add it to the Json Logic
                        const isExcluded = this.configurationService.getConfig( `filter.exclude.${ paramsMerged[ key ].key }` )
                        if ( !!isExcluded ) {
                            return
                        }
                        // If we need to map a specific queryParam to another Key, for instance price_max and price_min to price
                        const keyToReplaceFromConfig = this.configurationService.getConfig( `filter.keys.${ paramsMerged[ key ].key }` )
                        paramsMerged[ key ].key = keyToReplaceFromConfig ? keyToReplaceFromConfig : paramsMerged [key ].key

                        const validKeys = [
                            ...Object.keys({
                                ...this.configurationService.getConfig( `filter.label_values` ),
                                ...this.configurationService.getConfig( `filter.labels` ),
                                ...this.configurationService.getConfig( `filter.operators` ),
                                ...this.configurationService.getConfig( `filter.types` ),
                                ...this.configurationService.getConfig( `components.searchbox.labels` ),
                            }),
                            ...this.configurationService.getConfig( `filter.data_groups.equipment.endpointFields` ),
                        ]

                        // Check if the param is a valid one for our filters
                        if (!validKeys.includes(key) && !validKeys.includes(paramsMerged[ key ].key)) {
                            this.removeParamKey( groupData, key )
                            return
                        }

                        paramsWithLogic.push(
                            {
                                [ paramsMerged[ key ].operator]: [
                                    {var: paramsMerged[ key ].key},
                                    this.getParamValueWithType ( paramsMerged[ key ] )
                                ]
                            }
                         )
                    }
                }
            )
        }

        return paramsWithLogic.length ? { filters: { and: paramsWithLogic } } : { filters: {}}
    }

    /**
     * Retrieves the Object needed to perform a request to a filters endpoint
     * @param groupData key string of the data group
     * @returns schema needed for the backend to retrieve filters data
     */
    getParamsAsJsonLogicForFilters( groupData: string ): { fields: Array<string>, count: boolean, filters: any } {
        const fields =  this.getParamToFetch( groupData )
        const filters = this.getParamsAsJsonLogic( groupData )
        const count = false
        return {
            ...filters,
            fields,
            count
        }
    }

    /**
     * Retrieves the custom label from the { configuration.filters.labels }
     * @param key value of the key to search the label
     * @returns label value in the configuration
     */
    getParamLabel( key: string ): string {
        return this.configurationService.configuration.filter.labels[key]
            ? this.configurationService.configuration.filter.labels[key]
            : key
    }

    /**
     * Retrieves the custom value formatted from the { configuration.filters.label_values }
     * @param key value of the key to search the value format
     * @returns the label with the format replaced with the value
     */
    getParamLabelValue( key: string, value: string | Array<string> ): string {
        return this.configurationService.configuration.filter.label_values[key]
            ? this.configurationService.configuration.filter.label_values[key]
                .replace(
                    '{{value}}',
                    this.getTransformedLabel(key, value as string)
                )
            : value
    }

    /**
     * Removes all paramters
     * @example ng-onDestroy of each main module page
     */
    unsubscribeData() {
        Object.keys( this.params ).forEach( key => this.params[ key ].next( null ))
        Object.keys( this.paramsToFetch ).forEach( key => this.paramsToFetch[ key ].next( [] ))
        this.paramsDefault = {}
        this.paramsChanged.next( null )
        this.paramsCount.next( 0 )
    }

    saveParams( name: string, dataGroup: string ) {
        const storageValues = this.storage.get( this.storageKeyName )
        const group = ( storageValues && storageValues[ dataGroup ] ) ? storageValues[ dataGroup ] : {}

        const formattedParams = {
            ...storageValues,
            [ dataGroup ]: {
                ...group,
                [ name ] : this.getAllParamsAsQueryParams()
            }
        }

        this.storage.set( this.storageKeyName, formattedParams )
    }

    getParamsStored() {
        return this.storedParams
    }

    getParamStoredValue( key: string, dataGroup: string ) {
        const params = this.storedParams.getValue()[ dataGroup ]
        return params[ key ] ? params[ key ] : null
    }

    /**
     * Retrieves a custom param object to be used in any param state
     * @param key string of the param/field key
     * @param value value to be set
     * @returns a param object needed for the backend request and general logic of the app
     */
    private createParam( key: string, value: string | Array<string> ) {
        // Key and value only needed
        // const currentValues = this.getParamsGroup( dataGroup )

        // Retrieve the label if there is one defined in the configuration
        let label = this.getParamLabelValue( key, value )

        // Add the default opreator, if no {filter.operators.default} then == will be
        let operator = this.configurationService.getConfig(`filter.operators.default`, '==')

        // In case we defined an operator in the configuration, we use that
        const operatorFromConfig = this.configurationService.getConfig(`filter.operators.${ key }`)
        operator = operatorFromConfig ? operatorFromConfig : operator

        // In case it is multiple, from the params needs to enforce an array it comes as string
        if ( operator === 'in' && typeof value === 'string' ) {
            value = [ value ]
        }

        // In case we defined the parameter to be excluded in the configuration, we set the flag
        const excluded = !!this.configurationService.getConfig(`filter.exclude.${ key }`)

        // If it is a true|false value, we set the label as the key instead of the value
        if ( label === 'true' || label === 'false' ) {
            label = this.getParamLabel( key ) ? this.getParamLabel( key ) : key
        }

        return {
                operator,
                key,
                value,
                label,
                excluded
            }
    }

    /**
     * Retrieves the count of the ammount of parameters within a specific data group
     * @param groupData key string of the data group
     */
    private getParamsCount( groupData: string ): number {
        // Exclude pagination from counting
        if( groupData.includes(':pagination') ) {
            return this.paramsCount.getValue()
        }

        const params = this.params[ groupData ].getValue()
        let count = 0

        Object.keys( params ).forEach( paramKey => {
            if( !params[ paramKey ] ) {
                return
            }
            else if(params[paramKey].value && Array.isArray(params[paramKey].value)) {
                count+= params[ paramKey ].value.length
            } else {
                count++
            }
        })

        return count
    }

    /**
     * Retrieves the value with the format specified on the configuration.filter.types
     * @param param of type { key, value, operator }
     */
    private getParamValueWithType( param ) {
        const type = this.configurationService.configuration.filter.types[ param.key ]

        switch ( typeof param.value ) {
            default:
                return this.formatValueWithType( param.value, type )
            case 'object':
                return param.value.map( item => this.formatValueWithType( item, type ))
        }
    }

    /**
     * Retrieves the value with the type formated
     * @param value a string
     * @param type the type to be converted ex: number
     */
    private formatValueWithType( value: any, type: string ): boolean | string | number {
       switch( type ) {
            default:
                return value
            case 'number':
                return parseInt( value, 10 )
            case 'boolean':
                return (value === 'true' || value === true ) ? true : false
       }
    }

    /**
     * Retrieves the label  transformed depending on what's defined on the configuration
     * @param key the key param to search the value transformation type
     * @param value the alue to be transformed
     */
     getTransformedLabel(key: string, value: string | number) {
        const formatType = get(this.configurationService.configuration, `filter.transform.${key}`, null)

        switch (formatType) {
            case 'currency':
                return this.currencyPipe.transform(value as number, {}, false, true)
            default:
                return value
        }
    }

    /**
     * Retrieves the value transformed depending on what's defined on the configuration
     * @param key the key param to search the value transformation type
     * @param value the alue to be transformed
     */
    getTransformedValue(key: string, value: string | number) {
        const formatType = get(this.configurationService.configuration, `filter.transform_value.${key}`, null)

        switch (formatType) {
            case 'datePast':
                return this.datePastPipe.transform(get(this.configurationService.configuration, `catalog.just_listed_days`, 30))
            default:
                return value
        }
    }
}
