import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core'
import { Subject } from 'rxjs'
import { UtilityService } from 'src/app/services/utility.service'
import { ThemeService } from 'src/app/services/theme.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { IFinanceInfoBoxConfiguration } from 'src/app/interfaces/components/component-finance-info-box.interface'
import { takeUntil } from 'rxjs/operators'

export const FinanceInfoBoxComponentDefaults: IFinanceInfoBoxConfiguration = {
    type: 'finance_info_box',
    display: true,
    items: [],
    styles: {},
}

export const FinanceInfoBoxComponentDevOpts: IComponentDevOptions = {
    config: {
        ...FinanceInfoBoxComponentDefaults,
    }
}

@Component({
    selector: 'ras-finance-info-box',
    styleUrls: ['./finance-info-box.component.scss'],
    templateUrl: './finance-info-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceInfoBoxComponent implements OnInit, OnDestroy {

    private $destroy = new Subject<void>()

    @Input() configuration: IFinanceInfoBoxConfiguration

    constructor(
        private ref: ChangeDetectorRef,
        private elRef: ElementRef,
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
        })
    }

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, FinanceInfoBoxComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }
}
