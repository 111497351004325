import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { map } from 'rxjs/operators'
import { MaintenanceService } from '../services/maintenance.service'
import { UtilityService } from '../services/utility.service'
import { environment } from './../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class MaintenanceGuard  {

    constructor(
        private maintenanceService: MaintenanceService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const clientCode = environment.client ?? UtilityService.getClientFromUrl()
        if (!clientCode) {
            return true
        }
        return this.maintenanceService.load(clientCode, state.url).pipe(
            map(() => !this.maintenanceService.doRedirectIfNeeded())
        )
    }
}
