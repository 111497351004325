import { Directive, ElementRef, OnInit, Input } from '@angular/core'

// Directives
import { LogService } from 'src/app/services/log.service'

// Interfaces
import { ISmartConsoleFunctions } from 'src/app/interfaces'


@Directive({
    selector: '[ras-lazy-background]'
})

/**
 * @description
 * This directive is used to lazy load background images.
 * @param defaultSource - The default background image to use.
 * @returns void
 * @type {Directive}
 */
export class LazyBackgroundDirective implements OnInit {
    @Input() defaultSource: string
    protected readonly debug: ISmartConsoleFunctions

    constructor(
        private el: ElementRef,
    ) {
        this.debug = LogService.startDebug('LazyBackgroundDirective', 'LazyBackgroundDirective')
    }

    ngOnInit(): void {
        if (!this.defaultSource) {
            this.debug.error('LazyBackgroundDirective: defaultSource is required')
            return
        }
        this.setIntersectionObserver()
    }

    setIntersectionObserver(): void {
        const lazyBackground = this.el.nativeElement

        if ('IntersectionObserver' in window) {
            const lazyBackgroundObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const element = entry.target as HTMLElement
                        element.style.backgroundImage = this.defaultSource
                        lazyBackgroundObserver.unobserve(element)
                    }
                })
            })

            lazyBackgroundObserver.observe(lazyBackground)
        }
    }
}
