import { Injectable } from '@angular/core'

import { of } from 'rxjs'

import { LocationService } from './../services/location.service'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { take } from 'rxjs/operators'


@Injectable({
    providedIn: 'root'
})
export class LocationResolver  {

    constructor( private locationService: LocationService, private configurationService: ConfigurationService ) {}

    resolve() {
        if( this.configurationService.getConfig('catalog.use_location') ) {
            setTimeout(() => this.locationService.get())
            return of(true)
        }
        return of()
    }
}
