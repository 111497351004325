
<div class="component__controls">
    <ng-container
        *ngFor="let section of sections">
        <div class="section section--collapsed">
            <div
                class="section__title"
                (click)="toggleSection($event)">
                {{section.title}}
                <i
                    (click)="$event.target.parentNode.click()"
                    class="fa fa-chevron-up chevron-collapsed"></i>
                <i
                    (click)="$event.target.parentNode.click()"
                    class="fa fa-chevron-down chevron-expanded"></i>
            </div>
            <div class="section__controls">
                <ng-container
                    *ngTemplateOutlet="controlsTemplate; context: { $implicit: section.controls }">
                </ng-container>
                <div class="section-advanced section-advanced--collapsed full-width">
                    <div
                        *ngIf="!!section.advancedControls"
                        class="section-advanced__title">
                        <div 
                            class="section-advanced__button"
                            (click)="toggleAdvancedSection($event)">
                            <i
                                (click)="$event.target.parentNode.click()"
                                class="fa fa-chevron-up chevron-collapsed"></i>
                            <i
                                (click)="$event.target.parentNode.click()"
                                class="fa fa-chevron-down chevron-expanded"></i>
                            Advanced
                        </div>
                    </div>
                    <div
                        *ngIf="!!section.advancedControls"
                        class="section-advanced__controls">
                        <ng-container
                            *ngTemplateOutlet="controlsTemplate; context: { $implicit: section.advancedControls }">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #controlsTemplate let-controls>
    <ng-container
        *ngFor="let control of controls"
        [ngSwitch]="control.type">
        <div
            class="control"
            [class.full-width]="control.options?.fullWidth"
            [class.comment]="control.type === controlTypes.comment">
            <!-- Comment -->
            <tool-comment
                *ngSwitchCase="controlTypes.comment"
                [content]="control.value"
                [options]="control.options">
            </tool-comment>

            <!-- Boolean -->
            <tool-boolean-selector
                *ngSwitchCase="controlTypes.boolean"
                [configPath]="fullConfigPath"
                [key]="control.key"
                [label]="control.label"
                [value]="control.value"
                [options]="control.options">
            </tool-boolean-selector>

            <!-- String -->
            <tool-text-input
                *ngSwitchCase="controlTypes.string"
                [configPath]="fullConfigPath"
                [key]="control.key"
                [label]="control.label"
                [value]="control.value"
                [options]="control.options">
            </tool-text-input>

            <!-- Menu Builder -->
            <tool-menu-builder
                *ngSwitchCase="controlTypes.menuBuilder"
                [configPath]="fullConfigPath"
                [key]="control.key"
                [navItems]="control.value"
                [options]="control.options">
            </tool-menu-builder>

            <!-- Color Picker -->
            <tool-color-picker
                *ngSwitchCase="controlTypes.colorPicker"
                [configPath]="fullConfigPath"
                [key]="control.key"
                [label]="control.label"
                [value]="control.value"
                [options]="control.options">
            </tool-color-picker>

             <!-- Currency Options -->
             <tool-currency-options
                *ngSwitchCase="controlTypes.currencyOptions"
                [options]="control.options">
            </tool-currency-options>

        </div>
    </ng-container>
</ng-template>
