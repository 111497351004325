<div *ngFor="let step of steps">
    <div *ngIf="show === step.key">

        <!-- List of links -->
        <div *ngIf="step.type === 'links'">
            <ul>
                <li *ngFor="let item of data[step.key]; let i = index" 
                    [class.hide]="i + 1 > configuration.itemsToShow && !isFullView">
                    <a href="javascript:void(0);" (click)="linkClicked(item, step.key)">{{item}}</a>
                </li>
            </ul>
            <div class="filter-actions-container">
                <button [class.invisible]="data[step.key].length <= configuration.itemsToShow"
                        mat-flat-button class="full-view-toggle is-color-primary apply-view-btn"
                        (click)="isFullView = !isFullView">
                    {{!isFullView ?  (configuration.filterOpenText || 'View More') : (configuration.filterCloseText || 'View Less')}}
                </button>
            </div>
        </div>

        <!-- List of checkboxes -->
        <div *ngIf="step.type === 'checkboxes'">
            <ul>
                <li *ngFor="let item of data[step.key]; let i = index"
                    [class.hide]="i + 1 > configuration.itemsToShow && !isFullView">
                    <mat-checkbox color="primary"
                                [(ngModel)]="item.selected">
                        {{item.name}}
                    </mat-checkbox>
                </li>
            </ul>
            <div class="filter-actions-container">
                <button [class.invisible]="data[step.key].length <= configuration.itemsToShow"
                        mat-flat-button class="full-view-toggle is-color-primary apply-view-btn"
                        (click)="isFullView = !isFullView">
                        {{!isFullView ?  (configuration.filterOpenText || 'View More') : (configuration.filterCloseText || 'View Less')}}
                </button>
                <button (click)="onCheckboxesApply(step.key)"
                        mat-flat-button class="is-color-primary apply-filter-btn">
                        APPLY
                </button>
            </div>
        </div>

    </div>
</div>
