import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, throwError } from 'rxjs'

import { LogService } from './log.service'

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private errorSubject = new BehaviorSubject<Error>(null)

    error$: Observable<Error> = this.errorSubject.asObservable()

    next(error: Error): void {
        this.errorSubject.next(error)
    }

    handleError(response: HttpErrorResponse, scope: 'menu' | 'table' | 'fatal' = 'fatal'): Observable<Error> {

        LogService.log(!!response ? JSON.stringify(response.error) : 'no response', 'error')

        let error: Error

        if (!!response && response.error instanceof Error) {
            error = response.error

        } else if (!!response && response.error instanceof ErrorEvent) {
            // if a client-side or network error occurred, convert it to an error
            error = new Error(response.error.message)

        } else if (!!response && response.status === 400) {
            // if the response is a bad request, use the error as-is
            error = response

        } else {
            // otherwise, use the generic error
            error = new Error('An error occurred. Try reloading the page. If you continue to see this error, please contact customer service.')
        }

        error.name = scope

        this.next(error)
        return throwError(error)
    }

    notAuthorized(): void {
        const error = new Error('Please log in to continue')
        error.name = 'Unauthorized'
        this.errorSubject.next(new HttpErrorResponse({ status: 401, error: error }))
    }
}
