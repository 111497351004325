<div
    *ngIf="configuration.display"
    class="form-container"
    [class.mt-3]="configuration.hasTopMargin"
    [class.is-bordered]="configuration.isBordered"
    [class.is-background-secondary-light]="configuration.isBackgroundSecondaryLight"
    [class.is-rounded]="!configuration.disableRounding"
    [class.is-shaded]="configuration.shadedContainer"
    [class.is-overlay-active]="isSuccess || isError"
    [attr.id]="configuration.id"
    [style]="configuration.containerStyles"
    [class]="configuration.cssClass">
    <div class="form-header" [style]="configuration.headerStyles">
        <h2 class="is-font-h5 form-title" [style]="configuration.titleStyles" [innerHTML]="configuration.title"></h2>
        <p class="is-font-h3 form-subtitle" [style]="configuration.subtitleStyles" [innerHTML]="configuration.subtitle"></p>
    </div>
    <div class="form-body" [formGroup]="form">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

        <!-- Form fields -->
        <div class="form-row" *ngFor="let item of configuration.fields">
            <div class="form-field" *ngFor="let field of item" [style]="field.fieldStyles" [class.hidden]="field.type==='hidden'" [class]="field.field_class">
                <label [for]="getFieldId(field.key)" *ngIf="field.label">{{ field.label }}</label>
                <ng-container [ngSwitch]="field.type">
                    <input *ngSwitchDefault
                           [formControlName]="field.key"
                           class="is-rounded-small is-bordered"
                           [type]="field.type"
                           [name]="field.key"
                           [attr.aria-required]="field.required"
                           [attr.aria-invalid]="form.get( field.key )?.invalid"
                           [attr.aria-label]="field.label || field.placeholder"
                           [placeholder]="field.placeholder || ''"/>
                    <mat-checkbox *ngSwitchCase="'checkbox'"
                                  [required]="field.required"
                                  [formControlName]="field.key">
                        {{ field.placeholder }}
                    </mat-checkbox>
                    <textarea *ngSwitchCase="'textarea'"
                              class="is-rounded-small is-bordered"
                              rows="3"
                              type="text"
                              [formControlName]="field.key"
                              [attr.aria-required]="field.required"
                              [attr.aria-label]="field.label"
                              [attr.aria-invalid]="form.get( field.key )?.invalid"
                              [name]="field.key">
                    </textarea>
                    <mat-select *ngSwitchCase="'dropdown'"
                                class="is-rounded-small is-bordered"
                                hideSingleSelectionIndicator
                                [placeholder]="field.placeholder"
                                [formControlName]="field.key"
                                [attr.aria-required]="field.required"
                                [attr.aria-label]="field.label"
                                [attr.aria-invalid]="form.get( field.key )?.invalid">
                        <mat-option *ngFor="let item of field.options "[value]="item.value">{{item.label}}</mat-option>
                    </mat-select>
                    <button *ngSwitchCase="'submit'"
                            class="is-rounded is-background-primary is-font-h3 is-font-bolder"
                            [disabled]="!form || form.invalid"
                            [style]="field.styles"
                            (click)="onSubmit()">
                        {{ field.content }}
                    </button>
                    <div *ngSwitchCase="'recaptcha'"></div>
                  </ng-container>

                  <div *ngIf="field.error" class="form-field-error" [class.show]="form.get( field.key )?.invalid && form.get( field.key ).touched">
                    {{ field.error ? field.error : 'The field is required' }}
                  </div>
            </div>
        </div>

        <!-- Success container -->
        <div
            class="form-overlay-container"
            [class.active]="isSuccess"
            [class.is-background-secondary-light]="configuration.isBackgroundSecondaryLight">
            <fa-icon
                *ngIf="configuration.includeSuccessCloseButton"
                class="is-bordered"
                [icon]="['fas','times']"
                (click)="onCloseOverlay()"
            ></fa-icon>
            <div [innerHTML]="configuration.messageSuccess"></div>
            <div
                *ngIf="configuration.formType === 'financing_quote' && !!financeData"
                class="finance-data">
                <div class="finance-data__header">
                    <div>
                        Low monthly financing available!
                    </div>
                    <div>
                        Estimated borrowing amount: {{configuration.financingCurrencySymbol}}{{financeData.est_borrow_amount_native}}
                    </div>
                </div>
                <div class="finance-data__row">
                    <div><span class="label">Term Length</span> (months)</div>
                    <div
                        *ngFor="let item of financeData.data">
                        {{item.length}}
                    </div>
                </div>
                <div class="finance-data__row">
                    <div><span class="label">Monthly payment</span> ({{configuration.financingCurrencyCode}})</div>
                    <div
                        *ngFor="let item of financeData.data">
                        {{configuration.financingCurrencySymbol}}{{item.payment_native}}
                    </div>
                </div>
                <div
                    *ngIf="!!configuration.financedBy"
                    class="finance-data__footer">
                    Financing facilitated by {{configuration.financedBy}}
                </div>
            </div>
            <div class="featured-data-container" *ngIf="configuration.messageSuccessDataConfiguration?.data_group && data.length > 0">
                 <!-- List container -->
                <div ras-list
                    [configuration]="configuration.messageSuccessDataConfiguration.list_configuration"
                    [isLoading]="isLoading"
                    [data]="data">
                </div>
            </div>
        </div>

        <!-- error container -->
        <div class="form-overlay-container is-background-secondary-light" [class.active]="isError">
            <fa-icon class="is-bordered" [icon]="['fas','times']" (click)="onCloseOverlay()"></fa-icon>
            <div [innerHTML]="configuration.messageError"></div>
        </div>
    </div>
    <!-- Footer -->
    <div class="form-footer" [innerHTML]="configuration.disclaimer" [style]="configuration.disclaimerStyles"></div>
    <!-- Custom css at the form level -->
    <div ras-inject-content [styles]="configuration.styles"></div>
</div>
