<ng-container *ngIf="isLoaded">

    <!-- Window Content -->
    <router-outlet #outlet [class]="appClasses"></router-outlet>

    <!-- Custom CSS -->
    <div ras-inject-content *ngIf="styles" [styles]="styles" ></div>

    <!-- Loading bar -->
    <ngx-loading-bar [color]="colorPrimary"
                    height="4px"
                    [includeSpinner]="false"></ngx-loading-bar>

    <!-- Initial blank loading container -->
    <div id="initial-loading-container" *ngIf="!isLoaded"></div>
</ng-container>

<ras-modal [configuration]="{
    modalId: 'app-modal'
}"></ras-modal>
