import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

interface IModalData {
    modalId: string
    content: any
    styles?: any
    params?: any
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private data: Array<IModalData> = []
    public $data = new Subject<Array<IModalData>>()

    constructor() {}

    addModal(modalData: IModalData): void {
        this.removeModal(modalData.modalId)
        this.data.push(modalData)
        this.$data.next(this.data)
    }

    removeModal(modalId: string): void {
        if (!this.data.find(modal => modal.modalId === modalId)) {
            return
        }
        this.data = this.data.filter(d => d.modalId !== modalId)
        this.$data.next(this.data)
    }
}
