import { Directive, ViewContainerRef } from '@angular/core'

/**
 * @description Used to specific the container reference to inject the dynamic components into it
 */
@Directive({
  selector: '[zoneHost]',
})
export class ZoneDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
