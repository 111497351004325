<div class="search-advanced-mobile-container">
    <h4>Advanced Search</h4>
    <fa-icon [icon]="['fas', 'times']" (click)="closePane()"></fa-icon>

    <mat-select id="select-category-mobile" class="is-rounded-small chevron-arrow" placeholder="Category"
        (selectionChange)="onCategoryChange($event.value)" *ngIf="categories" hideSingleSelectionIndicator>
        <mat-option *ngFor="let category of categories" [value]="category.name">{{ category.name }}</mat-option>
    </mat-select>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Location
            </mat-panel-title>
        </mat-expansion-panel-header>

        <input #zipCodeInput class="zip-code-field" type="text" placeholder="Zip Code" [(ngModel)]="zipcode" />

        <div class="divisor"></div>

        <mat-select class="is-rounded-small chevron-arrow"
            placeholder="Select Within"
            (selectionChange)="onLocationChange()"
            [(ngModel)]="distance"
            *ngIf="locations"
            hideSingleSelectionIndicator>
            <mat-option *ngFor="let location of locations" [value]="location.value">{{ location.name }}</mat-option>
        </mat-select>
    </mat-expansion-panel>

    <div class="divisor"></div>

</div>