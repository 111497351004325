import { environment } from 'src/environments/environment'
import { Injectable, OnDestroy } from '@angular/core'
import { Observable, BehaviorSubject, Subject } from 'rxjs'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { filter, map, takeUntil } from 'rxjs/operators'
import { Router } from '@angular/router'

import { ConfigurationService } from './configuration.service'

interface IMaintenanceDocument {
    clientCodes: Array<string>
    excludeClientCodes: boolean
    forceExecute: boolean
    includeWebshops: boolean
    is_in_maintenance: boolean
    limitToClientCodes: boolean
    triggerSignOut: boolean
    turnOnWalls: boolean
}

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService implements OnDestroy {
    private $destroy = new Subject<void>()

    private readonly maintenancePath = 'maintenance'
    private readonly defaultRedirect = '/home'
    private readonly ALL_CLIENTS_KEY: string = 'ALL_CLIENTS'

    private clientInMaintenance$ = new BehaviorSubject(null)
    private clientIsInMaintenance: boolean = null
    private clientCode: string = null

    private routeUrl: string

    constructor(
        private firestore: AngularFirestore,
        private router: Router,
        private configurationService: ConfigurationService,
    ) {
        this.handleDocChanges(this.clientInMaintenance$, (isInMaintenance: boolean) => {
            this.clientIsInMaintenance = isInMaintenance
        })
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.clientCode = this.configurationService.configuration.catalog.client_code
            this.load(this.clientCode, this.routeUrl)
        })
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    /**
     * Listens for updates to the local BehaviorSubject so it can redirect if needed.
     * Updates happen when a maintenance variable in the cloud is updated
     * @param docSubject$ Observable that gets updated when the cloud variable changes
     * @param trackBooleanCallback Used to update the local maintenance boolean since passing in the boolean made it lose its reference
     */
    handleDocChanges(
        docSubject$: BehaviorSubject<IMaintenanceDocument>,
        trackBooleanCallback: any
    ): void {
        docSubject$.pipe(
            takeUntil(this.$destroy),
            filter(data => !!data),
            map((docData: IMaintenanceDocument) => docData.is_in_maintenance)
        ).subscribe((isInMaintenance: boolean) => {
            trackBooleanCallback(isInMaintenance)
            this.doRedirectIfNeeded()
        })
    }

    /**
     * Loads the cloud variables relevant to maintenance for the loaded client
     * @param clientCode e.g. XYZ
     * @param routeUrl e.g. /home, /maintenance, /search/equipment
     * @returns an Observable for the guard to use
     */
    load(clientCode: string, routeUrl: string): Observable<boolean> {
        this.routeUrl = routeUrl.replace('/', '')
        const doc = this.firestore.collection('maintenance/')
            .doc(environment.firebaseEnv)

        // client maintenance doc
        this.watchDoc(doc, this.clientInMaintenance$)

        return this.checkMaintenanceEnabled()
    }

    /**
     * Listens for changes in the maintenance cloud variables and notifies the local BehaviorSubject
     * @param doc the firestore doc that holds the maintenance variable
     * @param docSubject$ the BehaviorSubject that will be notified when a change occurs to the doc
     */
    watchDoc(
        doc: AngularFirestoreDocument,
        docSubject$: BehaviorSubject<IMaintenanceDocument>,
    ): void {
        doc.valueChanges()
            .pipe(
                takeUntil(this.$destroy),
                map((docData: IMaintenanceDocument) => {
                    if (!docData) {
                        return {
                            is_in_maintenance: false,
                        }
                    }

                    if (docData.turnOnWalls && docData.includeWebshops) {
                        const clientCodes = docData.clientCodes.map(code => code.toLocaleLowerCase())

                        if (docData.excludeClientCodes && (clientCodes.includes(this.clientCode?.toLocaleLowerCase()) || clientCodes.includes(this.ALL_CLIENTS_KEY))) {
                            return {
                                ...docData,
                                is_in_maintenance: false,
                            }
                        } else if (docData.excludeClientCodes && !clientCodes.includes(this.clientCode?.toLocaleLowerCase())) {
                            return {
                                ...docData,
                                is_in_maintenance: true,
                            }
                        } else if (docData.limitToClientCodes && clientCodes.includes(this.clientCode?.toLocaleLowerCase())) {
                            return {
                                ...docData,
                                is_in_maintenance: true,
                            }
                        } else if (docData.limitToClientCodes && !clientCodes.includes(this.clientCode?.toLocaleLowerCase())) {
                            return {
                                ...docData,
                                is_in_maintenance: false,
                            }
                        } else {
                            return {
                                ...docData,
                                is_in_maintenance: true
                            }
                        }
                    }

                    if (!this.checkDocHasData(docData)) {
                        return {
                            ...docData,
                            is_in_maintenance: false,
                        }
                    }
                    return docData
                }),
            )
            .subscribe((docData: IMaintenanceDocument) => {
                docSubject$.next(docData)
            })
    }

    checkDocHasData(doc: IMaintenanceDocument): boolean {
        return !!doc && 'is_in_maintenance' in doc
    }

    /**
     * Determines if the page the user is on is appropriate to the maintenance setting
     * Redirects if they should be on a different page
     * @returns boolean true if a redirect was needed, otherwise false
     */
    doRedirectIfNeeded(): boolean {
        const isInMaintenance = this.clientIsInMaintenance
        if (isInMaintenance && this.routeUrl !== this.maintenancePath) {
            this.navToMaintenance()
            return true
        } else if (!isInMaintenance && this.routeUrl === this.maintenancePath) {
            this.navAwayFromMaintenance()
            return true
        }
        return false
    }

    navToMaintenance(): void {
        this.router.navigate([this.maintenancePath])
    }

    navAwayFromMaintenance(): void {
        this.router.navigate([this.defaultRedirect])
    }

    checkMaintenanceEnabled(): Observable<boolean> {
        return this.clientInMaintenance$.pipe(
            filter(clientData => !!clientData),
            map(clientData => clientData.is_in_maintenance)
        )
    }

}
