<div class="currency-list">
    <div class="currency-list__available">
        Available
        <div
            *ngFor="let item of currencies"
            class="currency-list__item">
            <input
                type="checkbox"
                class="control__enable-button"
                [checked]="checkCurrencySelected(item.code)"
                [disabled]="checkCurrencyDisabled(item.code)"
                (change)="processChecked($event, item.code)"
                id="currency-option-{{item.code}}" />
            <label for="currency-option-{{item.code}}">{{item.label}}</label>
        </div>
    </div>
    <div class="currency-list__selected">
        Selected
        <div
            *ngFor="let item of currencyList"
            class="currency-list__selected-item">
            <label for="default-{{item.code}}">{{item.char}} {{item.code}}</label>
        </div>
    </div>
    <div class="currency-list__default">
        Default
        <div
            *ngFor="let item of currencyList"
            class="currency-list__default-item">
            <input
                type="radio"
                id="default-{{item.code}}"
                class="control__default-currency"
                [checked]="currencyBase === item.code"
                name="default-currency"
                [value]="item.code"
                (change)="processDefaultCurrency($event)" />
        </div>
    </div>
</div>
