import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { combineLatest, forkJoin } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'

import { ConfigurationService } from 'src/app/services/configuration.service'
import { CurrencyResolver } from './currency.resolver'
import { LocationResolver } from './location.resolver'

@Injectable({
    providedIn: 'root'
})
export class ConfigurationResolver  {

    constructor(
        private configurationService: ConfigurationService,
        private locationResolver: LocationResolver,
        private currencyResolver: CurrencyResolver,
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.configurationService
            .get(route.data.isConfigTool)
            .pipe(
                take(1),
                // Beacuse resolvers happen asynchronously,
                // any resolvers that rely on configuration being loaded first
                // should go here instead of being added to the routing
                switchMap(() => forkJoin([
                    this.locationResolver.resolve(),
                    this.currencyResolver.resolve(),
                ]))
            )
    }
}
