import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { ICurrencySelectorConfiguration } from 'src/app/interfaces/components/component-currency-selector.interface'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { ConfigControlsComponent, ControlTypes, IComponentControl } from '../../controls/config-controls.component'
import { ConfigDirective } from '../config.directive'

@Component({
    selector: 'config-currency-selector',
    templateUrl: './config.currency-selector.html',
    styleUrls: ['./config.currency-selector.scss', '../config.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencySelectorConfigComponent extends ConfigDirective {
    basePath: string = null

    defaultConfiguration: ICurrencySelectorConfiguration

    controls: Array<IComponentControl> = []
    @ViewChild('configControls') configControls: ConfigControlsComponent

    constructor(
        protected configurationService: ConfigurationService,
    ) {
        super(configurationService)
    }

    async buildControls(): Promise<void> {
        this.addSection('General', [
            this.addControl('display', 'Display', ControlTypes.boolean),
            this.addControl('showArrowInButton', 'Show Arrow in Button', ControlTypes.boolean),
            this.addControl('showSymbolInButton', 'Show Symbol in Button', ControlTypes.boolean),
            this.addControl('showCodeInButton', 'Show Code in Button', ControlTypes.boolean),
            this.addControl('showFlagInList', 'Show Flag in List', ControlTypes.boolean),
            this.addControl('showSymbolInList', 'Show Symbol in List', ControlTypes.boolean),
            this.addControl('showCodeInList', 'Show Code in List', ControlTypes.boolean),
            this.addStyle('font-family', 'Font Family', ControlTypes.string),
            this.addStyle('font-size', 'Font Size', ControlTypes.string),
            this.addStyle('color', 'Font Color', ControlTypes.colorPicker),
            this.addStyle('background', 'Background Color', ControlTypes.colorPicker),
        ])
        this.addSection('Select Currencies', [
            this.addControl('', null, ControlTypes.currencyOptions, {
                fullWidth: true,
            }),
        ])
        this.addSection('Button Styles', [
            this.addStyle('button__text-transform', 'Text Transform', ControlTypes.string),
            this.addStyle('button__border', 'Border', ControlTypes.string),
            this.addStyle('button__border-radius', 'Border Radius', ControlTypes.string),
            this.addStyle('button__padding', 'Padding', ControlTypes.string),
            this.addStyle('button__width', 'Width', ControlTypes.string),
            this.addStyle('button__color', 'Font Color', ControlTypes.colorPicker),
            this.addStyle('button__font-size', 'Font Size', ControlTypes.string),
            this.addStyle('button__background', 'Background Color', ControlTypes.colorPicker),
            this.addStyle('button-char__padding', 'Char Padding', ControlTypes.string),
            this.addStyle('button-code__width', 'Code Width', ControlTypes.string),
            this.addStyle('button-code__padding', 'Code Padding', ControlTypes.string),
            this.addStyle('button-arrow__padding', 'Arrow Padding', ControlTypes.string),
            this.addStyle('button-arrow__border-left', 'Arrow Border Left', ControlTypes.string),
        ])
        this.addSection('List Styles', [
            this.addStyle('list__color', 'Font Color', ControlTypes.colorPicker),
            this.addStyle('list__font-size', 'Font Size', ControlTypes.string),
            this.addStyle('list__border', 'Border', ControlTypes.string),
            this.addStyle('list__border-radius', 'Border Radius', ControlTypes.string),
            this.addStyle('list__margin', 'Margin', ControlTypes.string),
            this.addStyle('list-item__background', 'Option Background Color', ControlTypes.colorPicker),
            this.addStyle('list-item__padding', 'Option Padding', ControlTypes.string),
            this.addStyle('list-item__text-transform', 'Option Text Transform', ControlTypes.string),
            this.addStyle('list-item__hover__color', 'Option Hover Color', ControlTypes.colorPicker),
            this.addStyle('list-item__hover__background', 'Option Hover Background Color', ControlTypes.colorPicker),
            this.addStyle('list-item__selected__color', 'Selected Color', ControlTypes.colorPicker),
            this.addStyle('list-item__selected__background', 'Selected Background Color', ControlTypes.colorPicker),
        ])

        this.configControls?.refresh()
    }
}
