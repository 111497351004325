import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { of,  } from 'rxjs'
import { catchError, shareReplay, tap } from 'rxjs/operators'

import { MatSnackBar } from '@angular/material/snack-bar'
import { LogService } from 'src/app/services/log.service'

import { environment } from 'src/environments/environment'
import { CurrencyService } from './currency.service'
import { UtilityService } from './utility.service'

@Injectable({
    providedIn: 'root'
})
export class ProposalService {
    readonly api = environment.data.api
    private equipmentId: string
    private url: string
    private currentCurrency: string
    private currencyUpdated: boolean = false

    constructor(
        private http: HttpClient,
        private snackbar: MatSnackBar,
        private currencyService: CurrencyService,
    ){
        this.currencyService.selectedCurrency.subscribe(data => {
            this.currentCurrency = data.code
            this.currencyUpdated = true
        })
    }

    getProposal( equipmentId: string, branchPhone: string ){
        if( this.equipmentId !== equipmentId || this.currencyUpdated ) {
            this.currencyUpdated = false
            const safeBranchPhone = !!branchPhone ? this.convertPhoneToNumbers(branchPhone) : null
            this.snackbar.open('Generating the equipment flyer...', null, { panelClass: 'proposal-snackbar' })
            const flyerUrl = `${ this.api }flyer/?equipment_number=${ equipmentId }${safeBranchPhone ? `&branch_phone=${safeBranchPhone}`: ''}&currency=${this.currentCurrency}`
            return this.http.get(flyerUrl, { headers: { 'trace-id': UtilityService.getUUID(`cat.******-******-******.proposals`)}})
                .pipe(
                    tap( ( data: { equipment_number: string, url: string } ) => {
                        if (data && data.url) {
                            this.url = data.url
                            this.equipmentId = equipmentId
                            this.snackbar.dismiss()
                        }
                        if( data && !data.url ) {
                            this.notifyError(flyerUrl)
                        }
                    }),
                    shareReplay(1),
                    catchError((error) => {
                        this.notifyError(flyerUrl)
                        return of(error)
                    })
                )
        } else {
            return of({
                equipment_number: this.equipmentId,
                url: this.url
            })
        }
    }

    private notifyError(flyerUrl: string) {
        LogService.log(`[Propsal(S)] Failed to retrieve flyer for: ${flyerUrl}`, 'error')
        this.snackbar.open('Equipment flyer not available', null, { panelClass: 'proposal-snackbar', duration: 3000 })
    }

    private convertPhoneToNumbers(phone: any) {
        let cleanTel = phone
        if ( typeof cleanTel === 'string' ) {
            const letterToNumMap = {
                a : '2',
                b : '2',
                c : '2',
                d : '3',
                e : '3',
                f : '3',
                g : '4',
                h : '4',
                i : '4',
                j : '5',
                k : '5',
                l : '5',
                m : '6',
                n : '6',
                o : '6',
                p : '7',
                q : '7',
                r : '7',
                s : '7',
                t : '8',
                u : '8',
                v : '8',
                w : '9',
                x : '9',
                y : '9',
                z : '9'
            }
            const matches = cleanTel.match( /[A-z]/gi ) || []
            matches.forEach( letter => cleanTel = cleanTel.replace( letter, letterToNumMap[letter.toLowerCase()] ) )
            cleanTel = cleanTel.replace(/\D/g,'').slice(-10)
        }
        return cleanTel
    }
}
