<fa-icon 
    *ngIf="configuration.display && configuration.download"
    title="{{configuration.downloadTitle}}"
    class="ras-btn-link"
    rasProposal
    [branchPhone]="data?.branch_phone"
    [equipmentId]="data?.equipment_number"
    [icon]="['fas', 'download']">
</fa-icon>

<fa-icon
    *ngIf="configuration.display"
    [icon]="['fas', 'share']"
    title="{{configuration.shareTitle}}"
    class="ras-btn-link"
    (click)="onShare()">
</fa-icon>
