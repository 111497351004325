import { DOCUMENT } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IHappyFoxChatComponentConfiguration } from 'src/app/interfaces/components/component-happy-fox-chat.interface'
import { ISmartConsoleFunctions } from 'src/app/interfaces'

// Services
import { LogService } from 'src/app/services/log.service'

@Component({
    selector: 'ras-happy-fox-chat',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HappyFoxChatComponent implements OnInit, IBaseComponent {

    @Input() configuration: IHappyFoxChatComponentConfiguration
    private readonly debug: ISmartConsoleFunctions

    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.debug = LogService.startDebug('general', 'HappyFoxChat')
    }

    ngOnInit(): void {
        if (!this.configuration.enabled) {
            return
        }
        this.initWidget()
        this.addCustomStyles()
    }

    initWidget(): void {
        (window as any).HFCHAT_CONFIG = {
            EMBED_TOKEN: this.configuration.token,
            ASSETS_URL: this.configuration.assetsUrl
        }
        const script = this.renderer2.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = (window as any).HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js'
        this.renderer2.appendChild(this.document.body, script)
    }

    addCustomStyles(): void {
        const self = this;
        (window as any).HFCHAT_CONFIG.onload = function() {
            const HappyFoxChat = this
            self.configuration.customStyles?.forEach((styleStr) => {
                HappyFoxChat.addCustomStyles(styleStr, (err: any) => {
                    if (err) {
                        self.debug.error('Failed to add custom styles. Error:', err)
                    }
                })
            })
        }
    }
}
