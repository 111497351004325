<div class="searchbox-container">
    <fa-icon *ngIf="!loading" class="search-advanced-mobile" [icon]="['fas', 'sliders-h']" (click)="openAdvanceSearch()"></fa-icon>
    <fa-icon *ngIf="loading" [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon>

    <mat-select class="select-search chevron-arrow" placeholder="Select Location" [matMenuTriggerFor]="menu" hideSingleSelectionIndicator #menuTrigger></mat-select>
    <mat-menu #menu="matMenu" class="advanced-search-location-menu">
        <div class="pop-over-location" (click)="$event.stopPropagation()">
            <input
                #zipCodeInput
                type="text"
                class="location-zip-code-field"
                placeholder="Zip Code"
                [(ngModel)]="zipcode"
                (click)="$event.stopPropagation()" />

            <mat-select class="select-search" placeholder="Select Within"
                *ngIf="distances"
                [(ngModel)]="distance"
                (selectionChange)="onLocationChange($event.value)"
                (click)="$event.stopPropagation()"
                hideSingleSelectionIndicator>
                <mat-option
                    *ngFor="let location of distances"
                    [value]="location.value">
                        {{location.name}}
                </mat-option>
            </mat-select>
        </div>
    </mat-menu>

    <div *ngIf="divText" class="div-text">{{divText}}</div>


    <mat-select class="select-search chevron-arrow" placeholder="Select Category"
        (selectionChange)="onCategoryChange($event.value)"
        [formControl]="formControl"
        panelClass="ras-select-panel"
        *ngIf="categories"
        hideSingleSelectionIndicator>
        <mat-option *ngFor="let category of categories" [value]="category.name">{{category.name}}</mat-option>
    </mat-select>
    <input #zipCodeInput
            ac-keydown
            type="text"
            [placeholder]="configuration.placeholder"
            [formControl]="formControl"
            [matAutocomplete]="auto"
            autocomplete="off"
            (keyup.escape)="zipCodeInput.blur(); clearControl()"/>

    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="onSelect($event)"
                        [displayWith]="displayValue"
                        class="search-autocomplete">
        <ng-container *ngIf="options && options.length">
            <mat-optgroup *ngFor="let group of options" [label]="getGroupLabel(group[0].key) + ':'">
                <mat-option *ngFor="let option of group; trackBy: tracker" [value]="option" class="ras-searchbox-item">
                    <div class="option-value">
                        <fa-icon *ngIf="configuration.icons[ option.key ]" [icon]="['fas', configuration.icons[ option.key ]]"></fa-icon>
                        <span class="result-field-value" [innerHTML]="option.label"></span>
                    </div>
                    <span class="option-count" *ngIf="option.count">{{ option.count }}</span>
                </mat-option>
            </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="options && !options.length && searchText">
            <mat-option disabled [innerHTML]="configuration.noResults"></mat-option>
        </ng-container>
    </mat-autocomplete>
    <div ras-inject-content *ngIf="configuration.inlineStyles" [styles]="configuration.inlineStyles"></div>
</div>