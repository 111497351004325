<div *ngIf="useLocation; else noLocation">
    <p class="filter-range-title">
        <span>{{configuration.label}}</span>
        <span *ngIf="maxValue === configuration.maxRange || maxValue === configuration.minRange; else milesTemplate">
            Everywhere
        </span>
        <ng-template #milesTemplate>
            <span>
                {{ maxValue | number }}
                <i *ngIf="maxValue === configuration.maxRange">+</i>
            </span>
            <span> {{ configuration.rangeType }} </span>
        </ng-template>
    </p>

    <ngx-slider [options]="options"
                [(value)]="maxValue"
                (userChange)="onChange( $event )"
                (userChangeEnd)="onChange( $event )">
    </ngx-slider>
</div>
<ng-template #noLocation>
    <div class="no-location-message">
        <fa-icon [icon]="['fas','exclamation-triangle']"></fa-icon>
        {{ configuration.noLocationMessage }}
    </div>
</ng-template>

