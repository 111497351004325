import { ErrorHandler } from '@angular/core'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'

// Interfaces
import { ILogConfig, TLoggerServiceLevel, TLogLevel, IRasLoggedUser, ISmartConsoleFunctions } from '../interfaces'

// Configs
import { environment } from '../../environments/environment'

let logger: (message: string, severity: TLoggerServiceLevel, callback?: (err: any, report: any) => void) => void
let setUserIdConfig: (userId: IRasLoggedUser) => void

const win = (window as any)
const saveToLocalStorage: boolean = win.hasOwnProperty('localStorage')
let debuggingLs: Record<string, boolean> = {}
if (saveToLocalStorage) {
    try {
        debuggingLs = JSON.parse(win.localStorage.getItem('ngx_debug-settings') || '{}')
    } catch (e) {
        debuggingLs = {}
    }
}

let showTime: boolean = get(debuggingLs, 'showTimeInLog', get(environment, 'debugging.showTimeInLog', false))
let showStack: boolean = get(debuggingLs, 'showStackInLog', get(environment, 'debugging.showStackInLog', false))
const debuggingOn: Record<string, boolean> = !!debuggingLs ? debuggingLs : {}

const initialize = (config: ILogConfig, apiKey: string, app: string): () => ErrorHandler => {
    errorOnCrap(config, 'logging config cannot be null or undefined', [ null, undefined ])
    errorOnCrap(config.factory, 'config factory cannot be null or undefined', [ null, undefined ])
    errorOnCrap(config.logger, 'config logger cannot be null or undefined', [ null, undefined ])
    errorOnCrap(config.initialize, 'config initialize cannot be null or undefined', [ null, undefined ])
    errorOnCrap(config.setUser, 'config setUserId cannot be null or undefined', [ null, undefined ])

    config.initialize(apiKey, app)
    logger = config.logger
    setUserIdConfig = config.setUser
    return config.factory
}

const log = (message: string, severity: TLoggerServiceLevel, callback?: (err: any, report: any) => void): void => {
    logger(message, severity, callback)
}

const setUser = (userId: IRasLoggedUser): void => {
    setUserIdConfig(userId)
}

const errorOnCrap = (value: any, message: string, compare?: any): boolean => {
    const doError = () => {
        message = message.replace(/(\$value)/gi, value).replace(/(\$compare)/gi, compare + '')
        log(message, 'error', () => { throw new Error(message) })
        return true
    }

    if (Array.isArray(compare)) {
        if ( compare.includes && compare.includes(value) ) {
            return doError()
        }
    } else if (value === compare) {
        return doError()
    }

    return false
}

const startDebug = (debugKey: string, namespace: string, alwaysReportLevel: TLogLevel = 'error'): ISmartConsoleFunctions => {
    debuggingOn[debugKey] = !(debugKey in debuggingOn) ? get(environment, 'debugging.' + debugKey, false) : debuggingOn[debugKey]
    const levels = [ 'debug', 'log', 'info', 'warning', 'error' ]
    const reportAt: number = levels.indexOf(alwaysReportLevel)
    const updateNamespace: (ns: string) => void = (ns: string) => namespace = ns
    const doLog = (level: TLogLevel, ...msg: Array<any>) => {
        let stack: Array<string>
        if (showStack) {
            stack = (new Error()).stack.split('\n') // .slice(2).map(line => line.replace(/(\s\s)/g, ''))
        }

        let cb: () => void = () => undefined
        if (typeof msg[msg.length - 1] === 'function') {
            cb = msg.pop()
        }

        if (debuggingOn[debugKey] || (levels.indexOf(level) >= reportAt)) {
            const time = showTime ? (new Date()).toLocaleTimeString() + ' ' : ''
            const shortLevel = level.substr(0, 3).toUpperCase()

            if (showStack) {
                switch (level) {
                    case 'debug':
                    case 'info':
                    case 'log':
                        msg.push('\n' + stack[2])
                        break
                    case 'warning':
                        msg.push('\n' + JSON.stringify(stack, null, '  '))
                        break
                }
            }

            // eslint-disable-next-line no-console
            console[level !== 'warning' ? level : 'warn'](`${time}[${shortLevel}:${namespace}]`, ...msg)
        }

        // Fallback on CB
        cb()
    }
    return {
        debug: (...msg: Array<any>) => doLog('debug', ...msg),
        log: (...msg: Array<any>) => doLog('log', ...msg),
        info: (...msg: Array<any>) => doLog('info', ...msg),
        warn: (...msg: Array<any>) => doLog('warning', ...msg),
        error: (...msg: Array<any>) => doLog('error', ...msg),
        enabled: () => debuggingOn[debugKey] === true,
        updateNamespace: (ns: string) => updateNamespace(ns)
    }
}

const flipSwitch = (debugKey: string, bool?: boolean): boolean => {
    const debuggingSwitches = get(environment, 'debugging', {})
    if (debugKey in debuggingSwitches) {
        debuggingOn[debugKey] = typeof bool === 'boolean' ? bool : !debuggingOn[debugKey]
        // eslint-disable-next-line no-console
        console.log(`Switch '${debugKey}' is now:`, debuggingOn[debugKey])
        saveToLocal()
        return debuggingOn[debugKey]
    }
}

const getSwitches = (): Record<string, (bool?: boolean) => void> => {
    const switches: Record<string, () => void> = {}
    Object.keys(debuggingOn).forEach(key => switches[key] = (bool?: boolean) => flipSwitch(key, bool))
    return switches
}

const showSwitches = (): Record<string, boolean> => omit(Object.assign(get(environment, 'debugging', {}), debuggingOn), 'showTimeInLog', 'showStackInLog')

const showSettings = (): Record<string, boolean> => ({ showTimeInLog: showTime, showStackInLog: showStack })

const showTimeInLog = (): boolean => {
    showTime = !showTime
    // eslint-disable-next-line no-console
    console.log(`Time display is now:`, showTime ? 'enabled' : 'disabled')
    debuggingOn.showTimeInLog = showTime
    saveToLocal()
    return showTime
}

const showStackInLog = (): boolean => {
    showStack = !showStack
    // eslint-disable-next-line no-console
    console.log(`Stack display is now:`, showStack ? 'enabled' : 'disabled')
    debuggingOn.showStackInLog = showStack
    saveToLocal()
    return showStack
}

const saveToLocal = (): void => {
    if (saveToLocalStorage) {
        win.localStorage.setItem('ngx_debug-settings', JSON.stringify(debuggingOn))
    }
}

win.debugging = {
    showSwitches,
    showStackInLog,
    getSwitches,
}

export const LogService: {
    initialize: (config: ILogConfig, apiKey: string, app: string) => () => ErrorHandler
    log: (message: any, severity: TLoggerServiceLevel, callback?: (err: any, report: any) => void) => void
    setUser: (userId: IRasLoggedUser) => void
    errorOnCrap: (value: any, message: string, compare?: any) => boolean
    startDebug: (debugKey: string, namespace: string, alwaysReportLevel?: TLogLevel) => ISmartConsoleFunctions
    flipSwitch: (debugKey: string, bool?: boolean) => boolean
    showTimeInLog: () => boolean
    showStackInLog: () => boolean
    showSwitches: () => Record<string, boolean>
    showSettings: () => Record<string, boolean>
} = {
    initialize, log, setUser, errorOnCrap, startDebug, flipSwitch,
    showTimeInLog, showSwitches, showSettings,
    showStackInLog
}
