import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

import { ConfigurationService } from 'src/app/services/configuration.service'
import { UtilityService } from 'src/app/services/utility.service'

interface IMetaItem {
    name: string
    content: string
    element: string
}

interface IMetaConfigs {
    default: Record<string, string>
    tags: Array<IMetaItem>
    meta_group: Array<Record<string, Array<IMetaItem>>>
    url: Array<{ path: string, meta_group: string }>
}

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    private currentUrl: string
    private metaConfigs: IMetaConfigs

    constructor( private meta: Meta,
                 private configurationService: ConfigurationService,
                 private titleService: Title
               ){
        this.configurationService
            .$configuration
            .subscribe((config) => {
                this.metaConfigs = this.configurationService.getConfig('catalog.seo', null)
            })
    }


    public update(path: string, data: any) {
        if (path.split('?')[0] !== this.currentUrl) {
            this.currentUrl = path
            this.updateMeta(path.split('?')[0], data)
        }
    }

    private updateMeta(path: string, data: any) {
        if (!this.metaConfigs) {
            this.metaConfigs = this.configurationService.getConfig('catalog.seo', null)
        }

        const key = this.metaConfigs.url.find(metakey => path.includes(metakey.path))

        this.metaConfigs.tags.forEach(metaItem => {
            this.updateContent( metaItem, {} )
        })

        if ( key && data[key.meta_group].getValue() ) {
            this.metaConfigs.meta_group[key.meta_group].forEach( ( metaTag: IMetaItem ) => {
               this.updateContent( metaTag, data[key.meta_group].getValue() )
            })
        }
    }

    private updateContent( metaTag: IMetaItem, storeData: any) {
        switch( metaTag.element ) {
            case 'meta':
                this.meta.updateTag({ name: metaTag.name, content: this.getMetaContent( metaTag.content, storeData, metaTag.name ) })
                break
            case 'title':
                this.titleService.setTitle( UtilityService.replaceTemplate( metaTag.content, storeData ) )
                break
        }
    }

    private getMetaContent( template: string, data, name = '') {
        if( name.includes('image') ) {
            if( data.images && data.images.length ) {
                data.img = data.images[0]
            } else {
                template = this.metaConfigs.default[name] ? this.metaConfigs.default[name] : template
            }
        }

        return UtilityService.replaceTemplate( template, data )
    }
}
