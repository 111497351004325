import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output, HostBinding, OnInit, OnDestroy } from '@angular/core'
import { union } from 'lodash-es'

// Pipes
import { RasCurrencyPipe } from 'src/app/pipes/ras-currency.pipe'
import { NumberPipe } from './../../../pipes/number.pipe'

import { UtilityService } from 'src/app/services/utility.service'
import { IListItemComponentConfiguration } from 'src/app/interfaces/components/component-list-item.interface'
import { Subject } from 'rxjs'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const ListItemComponentDefaults: IListItemComponentConfiguration = {
    type: 'list_item',
    display: true,
    hostClass: '',
    displayType: 'search-result-grid',
    defaultImage: 'https://catalog-assets.rousesales.com/_default/content/no-photo.jpeg',
    showDiscountedPrice: false,
    showDescription: false,
    showdataSerial: false,
    showdataNumber: false,
    showImage: false,
    showLikeable: false,
    isLiked: false,
    showLocation: false,
    showMeter: false,
    showMonthlyPayment: false,
    showPrice: false,
    showPriceFooter: false,
    showQuantityAtHeader: false,
    showQuantity: false,
    showQuantityLow: 0,
    labelPriceOnLowQuantity: '',
    quantityPriceOnLowQuantity: 0,
    showTitle: false,
    itemTitle: '',
    childConfigs: {},
}

export const ListItemComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ListItemComponentDefaults,
        itemTitle: '{{param1}} {{param2}}!',
        hostClass: 'pos-relative',
        showImage: true,
        showTitle: true,
        showPrice: true,
        showLocation: true,
        showMonthlyPayment: true,
        showQuantity: true,
    },
    data: {
        param1: 'Dynamic',
        param2: 'Title',
        quantity: 3,
        year: '2022',
        make: 'Make',
        model: 'Model',
        branch_city: 'City',
        branch_state: 'State',
        list_price: 12345,
        is_list_price_hidden: false,
        compare_at_price_multiplier: 1.5,
        lowest_monthly_payment: 234,
        detailed_description: 'Detailed description lorem ipsum.',
        meter: 345,
        data_number: 'data-number',
        serial_number: 'serial-number',
    },
    controls: [{
        key: 'displayType',
        type: 'select',
        options: [
            'search-result-grid',
            'search-result-list',
            'search-result-inline',
        ]
    }]
}

@Component({
    selector: '[ras-list-item]',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemComponent implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    @Input() data: any
    @Input() configuration: IListItemComponentConfiguration
    @Input() isLoading: boolean
    @Output() like = new EventEmitter()
    @Output() scrollHorizontalReset = new EventEmitter()

    @HostBinding('class') get cssClass() {
        return [
            this.configuration.hostClass,
            this.configuration.displayType,
        ].join(' ')
    }

    constructor(private currencyPipe: RasCurrencyPipe, private numberPipe: NumberPipe) { }

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, ListItemComponentDefaults)
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    getImgAltText(equipment: any): string {
        return UtilityService.getImgAltText(equipment)
    }

    replaceTemplate(template: string, item: any) {
        return UtilityService.replaceTemplate(template, item)
    }

    isLimitedSupply(equipment: any) {
        return this.configuration.labelPriceOnLowQuantity && equipment.quantity && equipment.quantity <= this.configuration.quantityPriceOnLowQuantity
    }

    onLike(isLike: boolean) {
        this.like.emit(isLike)
        return isLike
    }

    onUnsetNavigation() {
        this.scrollHorizontalReset.emit(true)
    }

    getFormatted( data: string | Array<string>, unionChar: string = '', prefixChar: string = '', type: string = '') {
        switch( typeof data ) {
            case 'string':
            case 'number':
                return `${ prefixChar }${ this.formatData( data, type ) }`
            case 'object':
                return union( data.map( item => prefixChar + this.formatData( item, type ) )).join( `${ unionChar }` )
        }
    }

    private formatData( data: string | number, type: string ) {
        if ( !type ) {
            return data
        }

        switch ( type ) {
            case 'money':
                return this.currencyPipe.transform( parseInt(data as string, 10), null, false, true )
            case 'number':
                return this.numberPipe.transform( data as number )
            default:
                return data
        }
    }
}
