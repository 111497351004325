import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,
    Input, OnDestroy, OnInit
} from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { SearchService } from 'src/app/services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IFeaturedChipsConfiguration, IFeaturedChipsItems } from 'src/app/interfaces/components/component-featured-chips.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { QueryService } from 'src/app/services/query.service'

export const FeaturedChipsComponentDefaults: IFeaturedChipsConfiguration = {
    type: 'featured_chips',
    display: true,
    items: [{
        image_src: 'https://catalog-assets.rousesales.com/sec/icons/low_hours.svg',
        key: 'is_low_hours',
        subtitle: '{{meter_vs_average|roundToPercentage}}% Better than Average',
        subtitle_class: '',
        title: 'Low Hours',
        title_class: ''
    },
    {
        image_src: 'https://catalog-assets.rousesales.com/sec/icons/warranty.svg',
        key: 'is_warranty_eligible',
        subtitle: '',
        subtitle_class: '',
        subtitle_link: '',
        title: 'Warranty Available',
        title_class: ''
    },
    {
        image_src: 'https://catalog-assets.rousesales.com/sec/icons/listing.svg',
        key: 'just_listed',
        subtitle_class: '',
        title: 'Just Listed',
        title_class: ''
    },
    {
        image_src: 'https://catalog-assets.rousesales.com/sec/icons/popular.svg',
        key: 'is_popular',
        subtitle: 'View all Popular',
        subtitle_class: '',
        subtitle_link: '/equipment/search',
        subtitle_link_params: {
            'equipment.filter_is_popular': true
        },
        title: 'Popular',
        title_class: ''
    },
    {
        image_src: 'https://catalog-assets.rousesales.com/sec/icons/financing.svg',
        key: 'is_financing_eligible',
        subtitle_class: '',
        title: 'Financing Available',
        title_class: ''
    }],
}

export const FeaturedChipsComponentDevOpts: IComponentDevOptions = {
    config: {
        ...FeaturedChipsComponentDefaults,
    },
    data: {
        is_low_hours: true,
        is_warranty_eligible: true,
        just_listed: true,
        is_popular: true,
        is_financing_eligible: true,
        meter_vs_average: 1.23,
    }
}

@Component({
    selector: 'app-featured-chips',
    templateUrl: './featured-chips.component.html',
    styleUrls: ['./featured-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedChipsComponent implements OnInit, OnDestroy, IBaseComponent {

    private $destroy = new Subject<void>()

    @Input() configuration: IFeaturedChipsConfiguration
    @Input() data: any
    @HostBinding('class') hostClass: string

    constructor(private searchService: SearchService, private ref: ChangeDetectorRef, private queryService: QueryService) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, FeaturedChipsComponentDefaults)
        if (this.configuration.dataGroup) {
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(takeUntil(this.$destroy))
                .subscribe(data => {
                    if (data) {
                        this.data = data
                        this.hostClass = !this.atLeastOneFeatured() ? 'hide' : ''
                        this.ref.markForCheck()
                    }
                })
        }
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    getParams(params: Record<string, string>) {
        return this.queryService.getCustomParams(params)
    }

    getImgAltText(item: IFeaturedChipsItems): string {
        return 'title' in item && !!item.title
            ? item.title
            : UtilityService.getImgAltText(item)
    }

    private atLeastOneFeatured() {
        return this.configuration.items.some(item => !!this.data[item.key])
    }
}
