import { Pipe, PipeTransform } from '@angular/core'

import { DATA_CONFIG } from '../config/data.config'

// this pipe is specific - to assets scale factors
@Pipe({
    name: 'percentRounded'
})
export class PercentRoundedPipe implements PipeTransform {
    transform(value: number): any {
        if (value !== undefined && value !== null) {
            const val = Math.round(value*1000) / 10
            return val + '%'
        }
        return DATA_CONFIG.nullString
    }
}
