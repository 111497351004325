import { takeUntil } from 'rxjs/operators'
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core'
import { Subject, Subscription } from 'rxjs'

// Services
import { SearchService } from '../../../services/search.service'
import { Router } from '@angular/router'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IEquipmentCatSubcatComponentConfiguration } from 'src/app/interfaces/components/component-equipment-cat-subcat.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ThemeService } from 'src/app/services/theme.service'

export const EquipmentCatSubcatComponentDefaults: IEquipmentCatSubcatComponentConfiguration = {
    type: 'equipment_cat_subcat',
    display: true,
    listFormatSize: 600,
    showFootnote: false,
    footnote: {
        header: null,
        content: null,
    },
    footnoteColspan: 2,
    viewAllBgUrl: 'https://images.rouseservices.com/ImageProcessor/get/getimage.aspx',
    bgImageSize: 'Normal',
    bgImageTransparent: false,
    bgImageShowInMobile: true,
    cols: 3,
    gutterSize: '0px',
    rowHeight: '375px',
    includeParentInChildren: true,
    styles: {}
}

export const EquipmentCatSubcatComponentDevOpts: IComponentDevOptions = {
    config: {
        ...EquipmentCatSubcatComponentDefaults,
        dataGroup: 'category-subcategory',
        footnote: {
            header: 'Component header info',
            content: 'Component content info'
        },
        showFootnote: true,
    },
    controls: [{
        key: 'bgImageSize',
        type: 'select',
        loadData: 'imageProcessorTypes',
    }, {
        key: 'dataGroup',
        type: 'select',
        options: [
            'category-subcategory',
            'supercategory-category',
        ],
        hint: 'Not every client supports each option'
    }]
}

@Component({
    selector: '[ras-equipment-cat-subcat]',
    styleUrls: ['./equipment-cat-subcat.component.scss'],
    templateUrl: './equipment-cat-subcat.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentCatSubcatComponent implements OnInit, OnDestroy, OnChanges, IBaseComponent {

    private $destroy = new Subject<void>()

    listFormat = false
    imageBaseUrl: string
    searchSubscription: Subscription

    @Input() configuration: IEquipmentCatSubcatComponentConfiguration
    @Input() data: any

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private configurationService: ConfigurationService,
        private themeService: ThemeService,
        private elRef: ElementRef,
    ) {
        this.configurationService.$configuration.pipe(
            takeUntil(this.$destroy)
        ).subscribe(() => {
            this.themeService.clearStylesOnElement(elRef)
            this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
            this.ref.markForCheck()
            this.ref.detectChanges()
        })
    }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, EquipmentCatSubcatComponentDefaults)
        this.themeService.setStylesOnElement(this.configuration.styles, this.elRef)
        this.imageBaseUrl = this.configurationService.getConfig('catalog.image_base_url', null)
        this.loadData()
        this.setFormat()
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !changes.configuration?.isFirstChange()
            && changes.configuration?.currentValue?.dataGroup !== changes.configuration?.previousValue?.dataGroup
        ) {
            this.reloadData()
        }
        if (
            !changes.configuration?.isFirstChange()
            && changes.configuration?.currentValue?.bgImageSize !== changes.configuration?.previousValue?.bgImageSize
        ) {
            this.data = this.data.map(d => ({
                ...d,
                imageUrl: this.buildImageUrl(d.image, changes.configuration.currentValue.bgImageSize, changes.configuration.currentValue.bgImageTransparent),
            }))
        }
        this.ref.markForCheck()
    }

    reloadData(): void {
        this.searchSubscription.unsubscribe()
        this.searchService.unsubscribeData()
        this.loadData()
    }

    loadData(): void {
        this.searchSubscription = this.searchService.requestData(this.configuration.dataGroup)
            .pipe(takeUntil(this.$destroy))
            .subscribe(response => {
                if (!!response) {
                    this.data = this.prepItems(response)
                    this.ref.detectChanges()
                }
            })
    }

    buildImageUrl(imageId: string, imageSize: string, imageTransparent: boolean): string {
        return imageId ? `url(${this.imageBaseUrl}?type=${imageSize}&guid=${imageId}&transparent=${imageTransparent})` : ''
    }

    /**
     * Transform incoming data into the format the component expects
     * @param response data from the backend
     * @returns object data that can be consumed by the html file
     */
    prepItems(response): any {
        const { bgImageSize, bgImageTransparent } = this.configuration
        switch (this.configuration.dataGroup) {
            case 'category-subcategory':
                return response.data.map(c => ({
                    name: c.category,
                    image: c.image,
                    imageUrl: this.buildImageUrl(c.image, bgImageSize, bgImageTransparent),
                    quantity: c.quantity,
                    filter: 'filter_category',
                    children: c.subcategories.map(s => ({
                        name: s.name,
                        quantity: s.quantity,
                        filter: 'filter_sub_category',
                    })),
                }))
            case 'supercategory-category':
                return response.data.map(c => ({
                    name: c.supercategory,
                    image: c.image,
                    imageUrl: this.buildImageUrl(c.image, bgImageSize, bgImageTransparent),
                    quantity: c.quantity,
                    filter: 'filter_type',
                    children: c.categories.map(s => ({
                        name: s.name,
                        quantity: s.quantity,
                        filter: 'filter_category',
                    })),
                }))
        }
    }

    @HostListener('window:resize', ['$event'])
    setFormat() {
        if (window.innerWidth <= this.configuration.listFormatSize) {
            this.listFormat = true
        } else {
            this.listFormat = false
        }
    }

    viewAll(): void {
        this.router.navigateByUrl('/equipment/search')
    }

    getSearchHref(filterKey: string, filterValue: string): string {
        const val = encodeURIComponent(filterValue)
        return `/equipment/search?equipment.${filterKey}=${val}`
    }

    /**
     * Footnote colspan can't be bigger than grid columns
     * @returns the smaller number between cols and footnoteColspan
     */
    getFootnoteColspan(): number {
        return Math.min(this.configuration.cols, this.configuration.footnoteColspan)
    }

    getBackgroundImageForMobile(imageUrl: string): string {
        if (!this.configuration.bgImageShowInMobile) {
            return ''
        }
        return imageUrl
    }
}
