<mat-select
    *ngIf="currencies"
    (selectionChange)="onChange($event)"
    [(value)]="selectedCurrency"
    panelClass="currency-selector-opts-panel"
    [disableOptionCentering]="true"
    hideSingleSelectionIndicator
    #selectRef>
    <mat-option
        [value]="opt.code"
        *ngFor="let opt of currencies">
        <span
            *ngIf="opt.flag"
            class="flag-icon flag-icon-{{opt.flag}}"></span>
        {{opt.char}} {{opt.code}}
    </mat-option>
</mat-select>