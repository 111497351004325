import { NgModule } from '@angular/core'
import { Routes, RouterModule, UrlSegment } from '@angular/router'

import { MaintenanceGuard } from './guards/maintenance.guard'
import { DevGuard } from './guards/dev.guard'
import { ConfigurationResolver } from './resolvers/configuration.resolver'
import { HercRedirectGuard } from './guards/herc-redirect.guard'

const RESOLVERS = {
    configResult: ConfigurationResolver,
}

const GUARDS: any = [
    MaintenanceGuard
]

export const matchHercLegacy = (url: Array<UrlSegment>) => {
    if (url[0].path === 'Sales' || url[0].path === 'Classifications') {
        return {
            consumed: url,
        }
    }
    return null
}

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'Sales',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        matcher: matchHercLegacy,
        canActivate: [HercRedirectGuard],
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        resolve: RESOLVERS,
        canActivate: GUARDS,
        data: {
            module: 'home',
        },
    },
    {
        path: 'dev',
        loadChildren: () => import('./modules/dev/dev.module').then(m => m.DevModule),
        resolve: RESOLVERS,
        canActivate: GUARDS.concat([DevGuard]),
        data: {
            module: 'dev',
        },
    },
    {
        path: 'finance',
        loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule),
        resolve: RESOLVERS,
        canActivate: GUARDS,
        data: {
            module: 'finance',
        },
    },
    {
        path: ':assetType/search',
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
        resolve: RESOLVERS,
        canActivate: GUARDS,
        data: {
            module: 'search',
        },
    },
    {
        path: ':assetType/detail',
        loadChildren: () => import('./modules/detail/detail.module').then(m => m.DetailModule),
        resolve: RESOLVERS,
        canActivate: GUARDS,
        data: {
            module: 'detail',
        },
    },
    {
        path: ':pageType',
        loadChildren: () => import('./modules/page/page.module').then(m => m.PageModule),
        resolve: RESOLVERS,
        canActivate: GUARDS,
        data: {
            module: 'page',
        },
    },
    {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full'
    }
]

// @dynamic
@NgModule({
    imports: [ RouterModule.forRoot(routes, {
    // enableTracing: true,
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager'
}) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
