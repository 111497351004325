import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { IFilterSelectMultitConfiguration } from 'src/app/interfaces/filters/filter-select-multi.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'

@Component({
    selector: 'ras-filter-multi-select',
    styles: [`
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        mat-select { padding: 5px 0; box-sizing: border-box; border-bottom: 1px solid grey }
        mat-label { position: absolute; top: 2px; left: 0; right: 0; z-index:9999; background: #FFF; padding: 4px 0;}
        .mat-mdc-select-placeholder { color: #000; }
        .select-placeholder-container {display: flex; align-items: center; }
        .select-placeholder-container > span { flex: 1; }
        .hide-dummy-option { display: none !important; }
        .selector-container { position: relative; margin: 5px 7px 12px 7px; }
    `],
    templateUrl: './filter-select-multi.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSelectMultiComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy =  new Subject<void>()

    @Input() configuration: IFilterSelectMultitConfiguration

    public dataMake = []
    public dataModel = []
    public selectedMake
    public selectedModel
    public isLoading

    constructor( private queryService: QueryService, private ref: ChangeDetectorRef ) {}

    ngOnInit(): void {
        this.isLoading = this.queryService.isLoading

        if ( this.queryService.hasParam( this.configuration.dataGroup, this.configuration.keyMake ) ) {
            this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.keyModel )
        }

        // Add the filter params to the state that request filters data to the backend
        this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.keyMake )

        this.queryService.paramsData
            .pipe(
                takeUntil(this.$destroy)
            )
            .subscribe( data => {

                if ( data ) {
                    // Populates the models with the value from the params state
                    this.selectedMake = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyMake )
                    this.selectedModel = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyModel )

                    // Populates the dropdowns with the available data POST:request{filters[key]}
                    this.dataMake = data[ this.configuration.keyMake ]
                    this.dataModel = data[ this.configuration.keyModel ]

                    this.ref.markForCheck()
                }
            })
    }

    ngOnDestroy(): void {
       this.$destroy.next()
       this.$destroy.complete()
    }

    onChange() {
        // Apply the selected make or remove it, if no Make is selected, remove the Model
        if( !this.selectedMake ) {
            this.queryService.removeParamToFetch( this.configuration.dataGroup, this.configuration.keyModel )
            this.queryService.remove(this.configuration.dataGroup, this.configuration.keyMake, null)
            this.selectedModel = null
        } else {
            this.queryService.addParamToFetch( this.configuration.dataGroup, this.configuration.keyModel )
            this.queryService.add(
                this.configuration.dataGroup,
                this.configuration.keyMake,
                this.selectedMake
            )
        }

        if( !this.selectedModel ) {
            this.queryService.remove(this.configuration.dataGroup, this.configuration.keyModel, null)
        } else {
            this.queryService.add(
                this.configuration.dataGroup,
                this.configuration.keyModel,
                this.selectedModel
            )
        }
    }
}
