import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { IHeaderBasic1Configuration } from 'src/app/components/headers-footers/header-basic-1/header-basic-1.interface'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { ConfigControlsComponent, ControlTypes, IComponentControl } from '../../controls/config-controls.component'
import { ConfigDirective } from '../config.directive'

@Component({
    selector: 'config-header-basic-1',
    templateUrl: './config.header-basic-1.html',
    styleUrls: ['./config.header-basic-1.scss', '../config.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBasic1ConfigComponent extends ConfigDirective {
    basePath: string = 'headers'

    defaultConfiguration: IHeaderBasic1Configuration

    controls: Array<IComponentControl> = []
    @ViewChild('configControls') configControls: ConfigControlsComponent

    constructor(
        protected configurationService: ConfigurationService,
    ) {
        super(configurationService)
    }

    async buildControls(): Promise<void> {
        this.addSection('General', [
            this.addControl('includeCurrencySelector', 'Include Currency Selector', ControlTypes.boolean, {
                fullWidth: true,
            }),
            this.addControl('breakpointMedium', 'Medium Breakpoint', ControlTypes.string, {
                help: 'The width that the menu converts from Mobile to Tablet'
            }),
            this.addControl('breakpointLarge', 'Large Breakpoint', ControlTypes.string, {
                help: 'The width that the menu converts from Tablet to Desktop'
            }),
            this.addStyle('font-family', 'Font Family', ControlTypes.string),
            this.addStyle('background', 'Background', ControlTypes.colorPicker),
            this.addStyle('anchor__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('anchor__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
        ])

        this.addSection('Top Bar Section', [
            this.addControl('includeHeaderTop', 'Include Top Bar Section', ControlTypes.boolean, {
                fullWidth: true,
            }),
            this.addControl('email.href', 'Email', ControlTypes.string),
            this.addControl('email.content', 'Email Display', ControlTypes.string),
            this.addControl('phone.href', 'Phone Number', ControlTypes.string, {
                help: 'The actual phone number to be called.  This should just be the actual numbers for the phone number.'
            }),
            this.addControl('phone.content', 'Phone Display', ControlTypes.string, {
                help: 'How the phone number is displayed.  This can look however you would like it to look.'
            }),
        ], [
            this.addStyle('top__background', 'Background Color', ControlTypes.colorPicker),
            this.addStyle('top__color', 'Non-Link Font Color', ControlTypes.colorPicker),
            this.addStyle('top__line-height', 'Font Line Height', ControlTypes.string),
            this.addStyle('top__font-size', 'Font Size', ControlTypes.string),
            this.addStyle('top-item__margin', 'Item Margin', ControlTypes.string),
            this.addStyle('top-icon__margin', 'Icon Margin', ControlTypes.string),
            this.addStyle('top-anchor__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('top-anchor__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
        ])

        this.addSection('Main Section - Styles', [], [
            this.addStyle('main__background', 'Background Color', ControlTypes.colorPicker),
            this.addStyle('main__color', 'Non-Link Font Color', ControlTypes.colorPicker),
            this.addStyle('main__justify-content', 'Justify Content', ControlTypes.string),
            this.addStyle('main__margin', 'Margin', ControlTypes.string),
            this.addStyle('main__padding', 'Padding', ControlTypes.string),
            this.addStyle('main__align-items', 'Align Items', ControlTypes.string),
            this.addStyle('main-anchor__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('main-anchor__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
            this.addComment('Extras'),
            this.addStyle('extra__order', 'Order of Extra Section', ControlTypes.string, {
                help: 'Order of the extra buttons relative to other things in the Main section.  Lower number moves it left, higher moves it right.'
            }),
            this.addStyle('extra__align-items', 'Align Items for Extra Section', ControlTypes.string),
        ])

        this.addSection('Main Section - Logo', [
            this.addControl('logo.src', 'Logo Source URL', ControlTypes.string, {
                fullWidth: true,
            }),
            this.addControl('logo.href', 'Click Link', ControlTypes.string, {
                fullWidth: true,
            }),
            this.addControl('logo.alt', 'Alt Text', ControlTypes.string, {
                help: 'This specifies an alternate text for an image, if the image cannot be displayed.  This is useful for things like SEO and screen readers.'
            }),
            this.addStyle('logo__width', 'Logo Width', ControlTypes.string, {
                help: `
                    Width of the logo.  The height will change relative to the width you set.
                    This can be overriden by the --small --medium and --large values to set the
                    size at certain breakpoints.
                `
            }),
        ], [
            this.addStyle('logo__order', 'Logo Order', ControlTypes.string, {
                help: 'Order of the logo relative to other things in the Main section.  Lower number moves it left, higher moves it right.'
            }),
            this.addStyle('logo__padding', 'Logo Padding', ControlTypes.string),
            this.addStyle('logo__width--small', 'Logo Width - Small', ControlTypes.string, {
                help: `Width of the logo at smallest breakpoint.`
            }),
            this.addStyle('logo__width--medium', 'Logo Width - Medium', ControlTypes.string, {
                help: `Width of the logo between smallest and largest breakpoint.`
            }),
            this.addStyle('logo__width--large', 'Logo Width - Large', ControlTypes.string, {
                help: `Width of the logo at largest breakpoint.`
            }),
        ])

        this.addSection('Bottom Section - Styles', [], [
            this.addStyle('bottom__background', 'Background Color', ControlTypes.colorPicker),
            this.addStyle('bottom__color', 'Non-Link Font Color', ControlTypes.colorPicker),
            this.addStyle('bottom__justify-content', 'Justify Content', ControlTypes.string),
            this.addStyle('bottom__margin', 'Margin', ControlTypes.string),
            this.addStyle('bottom__padding', 'Padding', ControlTypes.string),
            this.addStyle('bottom__align-items', 'Align Items', ControlTypes.string),
            this.addStyle('bottom-anchor__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('bottom-anchor__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
        ])

        this.addSection('Navigation Links', [
            // TODO add selector to change between main and bottom
            this.addComment('Navigation Menu Builder'),
            this.addControl('navItems', null, ControlTypes.menuBuilder, {
                fullWidth: true,
            }),
        ], [
            this.addControl('nav-items__order', 'Nav Items Section Order', ControlTypes.string, {
                help: 'Order of the nav items relative to other things in the Main section.  Lower number moves it left, higher moves it right.  This is not relevant when the nav section is set to bottom.'
            }),
            this.addStyle('nav-items__margin', 'Nav Items Section Margin', ControlTypes.string),
            this.addStyle('nav-item__margin', 'Link Margin', ControlTypes.string),
            this.addStyle('nav-item__padding', 'Link Padding', ControlTypes.string),
            this.addStyle('nav-item__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('nav-item__border-bottom', 'Link Bottom Border', ControlTypes.string),
            this.addStyle('nav-item__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
            this.addStyle('nav-item__hover__border-bottom', 'Link Hover Bottom Border', ControlTypes.string, {
                help: 'If you set this to a value and find the page is jumping on hover, try setting the non-hover border-bottom to a transparent version of the same size.'
            }),
            this.addStyle('nav-item__white-space', 'Link White Space', ControlTypes.string, {
                help: 'Determine what to do for white space in the link text.  Typically should be "nowrap" to keep the link from breaking to a new line.'
            }),
            this.addStyle('nav-group-items__top', 'Group Items - Top', ControlTypes.string),
            this.addStyle('nav-group-items__background', 'Group Items - Background Color', ControlTypes.colorPicker),
            this.addStyle('nav-group-items__padding', 'Group Items - Padding', ControlTypes.string),
            this.addStyle('nav-group-item__padding', 'Group Item - Padding', ControlTypes.string),
            this.addStyle('nav-group-item__text-align', 'Group Item - Text Align', ControlTypes.string),
        ])

        this.addSection('Extra Navigation Links', [
            // TODO add selector to change between main and bottom
            this.addComment('Extra Navigation Menu Builder'),
            this.addControl('extraNavItems', null, ControlTypes.menuBuilder, {
                fullWidth: true,
            }),
        ], [
            this.addControl('extra-nav-items__order', 'Extra Nav Items Section Order', ControlTypes.string, {
                help: 'Order of the extra nav items relative to other things in the Main section.  Lower number moves it left, higher moves it right.'
            }),
            this.addStyle('extra-nav-item__margin', 'Link Margin', ControlTypes.string),
            this.addStyle('extra-nav-item__padding', 'Link Padding', ControlTypes.string),
        ])

        this.addSection('Mobile', [], [
            this.addStyle('mobile__background', 'Background Color', ControlTypes.colorPicker),
            this.addStyle('mobile__color', 'Font Color', ControlTypes.colorPicker),
            this.addStyle('mobile-items__padding', 'Section Padding', ControlTypes.string),
            this.addStyle('mobile-items__margin', 'Section Margin', ControlTypes.string),
            this.addStyle('mobile-item__padding', 'Link Padding', ControlTypes.string),
            this.addStyle('mobile-anchor__color', 'Link Color', ControlTypes.colorPicker),
            this.addStyle('mobile-anchor__hover__color', 'Link Hover Color', ControlTypes.colorPicker),
            this.addStyle('mobile-anchor__hover__background', 'Link Background Color', ControlTypes.colorPicker),
        ])

        this.configControls?.refresh()
    }
}
