import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'rasBoolean',
})
export class RasBooleanPipe implements PipeTransform {

    transform(value: any, args?: string): any {
        if (!!args) {
            const options = args.split('|')
            return !!value ? options[0] : options[1]
        }

        return !!value.toString()
    }
}
