import { Injectable } from '@angular/core'
// Filters
import { FilterListComponent } from './../../filters/components/list/filter-list.component'
import { FilterDrilldownComponent } from '../../filters/components/drilldown/filter-drilldown.component'
import { RangePriceFilterComponent } from './../../filters/components/range-price/range-price.component'
import { FilterSelectMultiComponent } from '../../filters/components/select-multi/filter-select-multi.component'
import { ChipsComponent } from '../../filters/components/chips/chips.component'
import { SaveFiltersListComponent } from '../../filters/components/save-filters-list/save-filters-list.component'
import { RangeLocationComponent } from './../../filters/components/range-location/range-location.component'
import { FilterLocationComponent } from './../../filters/components/location/location-filter.component'
import { RangeMonthlyComponent } from '../../filters/components/range-monthly/range-monthly.component'
import { FilterMinMaxComponent } from '../../filters/components/min-max/min-max.component'
// Page
import { BannerComponent } from '../banner/banner.component'
import { HeroCarouselComponent } from '../hero-carousel/hero-carousel.component'
import { SeparatorComponent } from '../../filters/components/separator/separator.component'
import { BreadcrumbComponent } from './../breadcrumb/breadcrumb.component'
import { BrandSectionComponent } from '../brand-section/brand-section.component'
import { SearchComponent } from './../../filters/components/search/search.component'
import { FormComponent } from './../form/form.component'
import { ShareComponent } from './../share/share.component'
import { RenderContentComponent } from '../render-content/render-content.component'
import { FeaturedChipsComponent } from './../featured-chips/featured-chips.component'
// Detail
import { SummaryComponent } from '../summary/summary.component'
import { ListDetailsComponent } from '../list-details/list-details.component'
import { OneCallComponent } from '../one-call/one-call.component'
import { DetailCarouselComponent } from '../detail-carousel/detail-carousel.component'
// List
import { ListComponent } from '../list/list.component'
import { ItemsCarouselComponent } from '../items-carousel/items-carousel.component'
import { SearchAdvancedComponent } from '../../filters/components/search-advanced/search.advanced.component'
import { EquipmentCatSubcatComponent } from '../equipment-cat-subcat/equipment-cat-subcat.component'
import { HappyFoxChatComponent } from '../happy-fox-chat/happy-fox-chat.component'
import { BadgesContainerComponent } from '../badges-container/badges-container.component'
import { FinanceInfoBoxComponent } from '../finance-info-box/finance-info-box.component'
import { IframeComponent } from '../iframe/iframe.component'

/**
 * @description Maps the configuration response key to a specific Component
 * > Created as a servive so the same instance is used
 * > The key must match the value retrieved on the configuration response
 * @see ./zone.component.ts
 */
@Injectable({
    providedIn:'root'
})
export class BaseComponentMapping {
    public map = {
        // Home
        hero_carousel: HeroCarouselComponent,
        banner: BannerComponent,
        brand_section: BrandSectionComponent,
        equipment_cat_subcat: EquipmentCatSubcatComponent,
        // External components
        happy_fox_chat: HappyFoxChatComponent,
        // Listing components
        list_assets: ListComponent,
        items_carousel: ItemsCarouselComponent,
        // Filters
        filter_chips: ChipsComponent,
        searchbox: SearchComponent,
        search_advanced: SearchAdvancedComponent,
        filter_list: FilterListComponent,
        filter_drilldown: FilterDrilldownComponent,
        filter_multi_select: FilterSelectMultiComponent,
        filter_price_range: RangePriceFilterComponent,
        filter_save_list: SaveFiltersListComponent,
        filter_location: RangeLocationComponent,
        filter_location_zip: FilterLocationComponent,
        filter_monthly_range: RangeMonthlyComponent,
        filter_min_max: FilterMinMaxComponent,
        // Detail page components
        detail_summary: SummaryComponent,
        detail_list: ListDetailsComponent,
        one_call: OneCallComponent,
        iframe: IframeComponent,
        breadcrumb: BreadcrumbComponent,
        detail_carousel: DetailCarouselComponent,
        finance_info_box: FinanceInfoBoxComponent,
        form: FormComponent,
        share: ShareComponent,
        featured_chips: FeaturedChipsComponent,
        badges: BadgesContainerComponent,
        // Helpers
        separator: SeparatorComponent,
        render_content: RenderContentComponent,
    }
}
