import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core'
import {
    MatBottomSheetRef,
    MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet'
import { fromEvent } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'
@Component({
    selector: 'ras-filter-search-advanced-mobile',
    templateUrl: './search.advanced.mobile.component.html',
    styleUrls: ['./search.advanced.mobile.component.scss'],
})
export class SearchAdvancedMobileComponent implements OnInit, AfterViewInit {

    @ViewChild('zipCodeInput') input

    categories: Array<{ name: string }>
    locations: Array<{ name: string, value: number }>
    distance: number
    zipcode: string

    constructor(
        private bottomSheetRef: MatBottomSheetRef<SearchAdvancedMobileComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    ngAfterViewInit(): void {
        fromEvent(this.input.nativeElement, 'input')
          .pipe(
            map((event: Event) => (event.target as HTMLInputElement).value),
            debounceTime(3000),
            distinctUntilChanged())
          .subscribe(data => this.onZipCodeChange())
      }

    ngOnInit(): void {
        this.categories = this.data.categories
        this.locations = this.data.locations
    }

    onCategoryChange(category: string): void {
        this.bottomSheetRef.dismiss([{name: 'filter_category', value: category}])
    }

    onLocationChange(): void {
        if (this.zipcode) {
            this.bottomSheetRef.dismiss([{name: 'distance', value: this.distance}, {name: 'postal_code', value: this.zipcode}])
        }
    }

    onZipCodeChange(): void {
        if (this.distance) {
            this.bottomSheetRef.dismiss([{name: 'distance', value: this.distance}, {name: 'postal_code', value: this.zipcode}])
        }
    }

    closePane(): void {
        this.bottomSheetRef.dismiss()
    }
}
