<div
    #helpContainer
    class="control__help"
    [class.active]="active">
    <i
        class="fa fa-question-circle"
        (click)="showPopup()"></i>
    <div
        #helpContent
        class="help-content hide">
        <div class="help-content__title">
            <div class="help-content__label">{{key}}</div>
            <div class="help-content__close">
                <i
                    class="fa fa-close"
                    (click)="hidePopup()"></i>
            </div>
        </div>
        <div class="help-content__text">
            {{content}}
        </div>
    </div>
</div>
