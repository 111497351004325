
<div>
    <div class="swiper-wrap-container">
        <div class="swiper-button-prev swiper-button-prev-gallery gallery-prev">
            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor" transform-origin="center" transform="rotate(180)"></path></svg>
        </div>
        <swiper-container
            id="{{configuration.swiperId}}-images"
            class="swiper-container gallery-images"
            [hidden]="!configuration.display || !slides.length || configuration.isLoading"
            init="false"
            #imagesSwiper>
            <swiper-slide *ngFor="let slide of slides; let i = index">
                <div class="swiper-zoom-container" data-swiper-zoom="2">
                    <img *ngIf="slide === defaultNoImage && configuration.defaultImage; else defaultImage"
                        [src]="configuration.defaultImage" alt="No image available"/>
                    <ng-template #defaultImage>
                        <img ras-image [transparent]="true" [id]="slide" [alt]="getAltText(i, 'gallery')" [resize]="true" />
                    </ng-template>
                </div>
            </swiper-slide>
            <div slot="container-end">
                <div id="tip" class="tip">
                    <span *ngIf="!isZoomed"><fa-icon [icon]="['fas','search']"></fa-icon> Click to Zoom</span>
                    <span *ngIf="isZoomed"><fa-icon [icon]="['fas','arrows-alt']"></fa-icon> Drag to Move</span>
                </div>
            </div>
        </swiper-container>
        <div class="swiper-button-next swiper-button-next-gallery gallery-next">
            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor"></path></svg>
        </div>
    </div>

    <div class="swiper-wrap-container">
        <div class="swiper-button-prev swiper-button-prev-thumbs thumbs-prev">
            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor" transform-origin="center" transform="rotate(180)"></path></svg>
        </div>
        <swiper-container
            id="{{configuration.swiperId}}-thumbs"
            class="swiper-container gallery-thumbs"
            [hidden]="!configuration.display || slides.length <= 1 || configuration.isLoading || !configuration.showThumbs"
            init="false"
            #thumbsSwiper>
            <swiper-slide *ngFor="let slide of slides; let i = index">
                <img *ngIf="slide === defaultNoImage && configuration.defaultImage; else defaultImage"
                    [src]="configuration.defaultImage" alt="No image available"/>
                <ng-template #defaultImage>
                    <img ras-image [id]="slide" [alt]="getAltText(i, 'thumbnail')" [resize]="true" />
                </ng-template>
            </swiper-slide>
        </swiper-container>
        <div class="swiper-button-next swiper-button-next-thumbs thumbs-next">
            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor"></path></svg>
        </div>
    </div>
    <div *ngIf="!slides.length || configuration.isLoading" class="is-loading">
        <div class="inner"></div>
        <div *ngIf="configuration.showThumbs" class="thumbs-container">
            <span></span>
            <span></span>
        </div>
    </div>
</div>