import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser'

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: string): SafeHtml | SafeStyle {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value)
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value)
            default:
                throw new Error(
                    `Invalid type "${type}", availble types are: 'html' and 'style'`
                )
        }
    }
}
