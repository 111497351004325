<div
    class="footer-branding">
    <div 
        *ngIf="configuration.logo?.src"
        class="footer-logo">
        <a
            *ngIf="!!configuration.logo.href"
            href="{{configuration.logo.href}}">
            <img
                src="{{configuration.logo.src}}"
                alt="{{configuration.logo.alt}}" />
        </a>
        <img
            *ngIf="!configuration.logo.href"
            src="{{configuration.logo.src}}"
            alt="{{configuration.logo.alt}}" />
    </div>
    <div 
        *ngIf="configuration.newsletterDisplay"
        class="footer-newsletter">
        <div class="footer-newsletter-text">
            {{configuration.newsletterLabel}}
        </div>
        <form
            [formGroup]="form"
            (ngSubmit)="submitNewsletter()"
            class="footer-newsletter-form">
            <input
                formControlName="email"
                placeholder="{{configuration.newsletterPlaceholder}}" />
            <button type="submit">
                <i class="fa fa-chevron-right"></i>
            </button>
        </form>
        <div
            *ngIf="formError"
            class="footer-newsletter-error">
            {{formError}}
        </div>
        <div
            *ngIf="formSuccess"
            class="footer-newsletter-success">
            {{formSuccess}}
        </div>
    </div>
</div>
<ul
    *ngIf="configuration.navItems?.length > 0"
    class="footer-nav">
    <li
        *ngFor="let item of configuration.navItems;">
        <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
    </li>
</ul>
<div
    *ngIf="configuration.addressItems?.length > 0 || configuration.phone?.href"
    class="footer-location">
    <ul
        *ngIf="configuration.addressItems"
        class="footer-address">
        <li
            *ngFor="let item of configuration.addressItems">
            {{item}}
        </li>
    </ul>
    <div
        *ngIf="configuration.phone?.href && configuration.phone?.content"
        class="footer-phone">
        <a href="tel:{{configuration.phone.href}}">{{configuration.phone.content}}</a>
    </div>
    <ul
        *ngIf="!!configuration.socialMediaItems && configuration.socialMediaItems.length > 0"
        class="footer-social-media">
        <li
            *ngFor="let item of configuration.socialMediaItems">
            <a href="{{item.href}}">
                <i class="fa {{item.icon}}"></i>
            </a>
        </li>
    </ul>
</div>
<ul
    *ngIf="configuration.subNavItems?.length > 0"
    class="footer-sub-nav">
    <li
        *ngFor="let item of configuration.subNavItems;">
        <ng-container *ngTemplateOutlet="navItem; context: { $implicit: item }"></ng-container>
    </li>
</ul>
<div
    *ngIf="configuration.copyright"
    class="footer-copyright"
    [innerHtml]="getCopyright()">
</div>
<div
    *ngIf="!!configuration.poweredByLogo"
    class="footer-powered-by">
    <a
        *ngIf="configuration.poweredByLink"
        href="{{configuration.poweredByLink}}">Powered By
        <img src="{{configuration.poweredByLogo}}" />
    </a>
    <img
        *ngIf="!configuration.poweredByLink"
        src="{{configuration.poweredByLogo}}" />
</div>

<ng-template #navItem let-item>
    <ng-container
        [ngSwitch]="item.type">
        <a 
            *ngSwitchCase="'a'"
            class="footer-nav-item"
            href="{{item.href}}">
            {{item.content}}
        </a>
        <a 
            *ngSwitchCase="'a-ng'"
            class="footer-nav-item"
            [routerLink]="item.routerLink">
            {{item.content}}
        </a>
        <span
            *ngSwitchDefault
            class="footer-nav-item">
            {{item.content}}
        </span>
    </ng-container>
</ng-template>