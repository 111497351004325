import { NavigationExtras, Router } from '@angular/router'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { BehaviorSubject, of, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators'
import get from 'lodash-es/get'
import startCase from 'lodash-es/startCase'

// Services
import { SearchService } from 'src/app/services/search.service'
import { UtilityService } from '../../../../services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { ISearchItem, ISearchboxConfiguration } from 'src/app/interfaces/components/component-search.interface'
import { IFilterFieldIndex, IFilterSearchOption } from '../../../../interfaces'

@Component({
    selector: 'ras-filter-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()
    private requestData = new BehaviorSubject<string>( '' )

    @Input() configuration: ISearchboxConfiguration

    @HostBinding('style') get styles() { return this.configuration.styles }

    loading: boolean = false
    formControl = new UntypedFormControl()
    options: Array<ISearchItem>
    searchText: string = ''
    inlineStyles: string
    limit = 20

    constructor( private searchService: SearchService, private ref: ChangeDetectorRef, private route: Router ) {}

    ngOnInit(): void {
        this.limit = this.configuration.limit
        this.formControl.valueChanges
            .pipe(
                takeUntil( this.$destroy ),
                distinctUntilChanged(),
                debounceTime( 500 ),
                tap( () => {
                    this.loading = true
                    this.ref.markForCheck()
                }),
                switchMap( value => {
                    this.searchText = value
                    if( !value || UtilityService.isNullBlankOrUndefined( value ) || value.length < 2  ) {
                        return of([])
                    }
                    return this.searchService.search( value, this.limit )
                })
            )
            .subscribe( ( data: any ) => {
                this.setResults( data )
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()

        this.requestData.next('')
        this.requestData.complete()
    }

    onSelect( event: MatAutocompleteSelectedEvent ): void {
        const el = get(event, 'option')
        const selection: ISearchItem = event.option.value
        let url = this.configuration.url
        let urlOptions: NavigationExtras = {
            queryParams: { [ `${this.configuration.dataGroup}.${selection.key}` ]: selection.value },
            queryParamsHandling: 'merge'
        }

        if( this.configuration.urlCustom && this.configuration.urlCustom[ selection.key ] ) {
            url = UtilityService.replaceTemplate(this.configuration.urlCustom[ selection.key ], selection )
            urlOptions = {}
        }

        this.route.navigate( [ url ], urlOptions)

        if (!get(selection, 'column')) {
            this.clearControl()
            return
        }

        if (!!el) {
            el.focus()
        }

        // clear out the search box
        this.clearControl()
    }

    clearControl(): void {
        this.formControl.setValue(undefined)
    }

    getGroupLabel( key: string ) {
        return this.configuration.labels[key] ? this.configuration.labels[key] : key
    }

    getFilterLabel( key: string ) {
        return key.replace('filter_', '')
    }

    setResults( results: Array<ISearchItem> ): void {
        const resultsKeys = Object.keys( results )
        this.options = resultsKeys
            .map( key => results[ key ]
                    .map( item => ({ ...item, key, label: this.getLabel(item.value) })))
            .filter( group => group.length )
        this.loading = false

        this.ref.markForCheck()
    }

    getLabel( value: string ) {
        const searchRegEx: RegExp = new RegExp(`${this.searchText}`, 'ig')
        return value.replace( searchRegEx, `<strong>${this.searchText}</strong>` )
    }

    fixCase( val: string ): string {
        return startCase(val)
    }

    displayValue(selection: IFilterFieldIndex): string | undefined {
        return !!selection ? (!!selection.value ? selection.value.toString() : 'null') : undefined
    }

    tracker(index: number, item: IFilterSearchOption): string {
        return item.column + ':' + item.value
    }
}
