import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { QueryService } from 'src/app/services/query.service'
import { LocationService } from 'src/app/services/location.service'

// Interfaces
import { IFilterLocationConfiguration } from 'src/app/interfaces/filters/filter-location.interface'
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'

@Component({
    selector: 'ras-filter-location',
    styles: [`
        mat-select { padding: 5px 0; box-sizing: border-box; border-bottom: 1px solid grey }
        mat-label { position: absolute; top: 2px; left: 0; right: 0; z-index:9999; background: #FFF; padding: 4px 0;}
        mat-form-field { width: 100%; }
        mat-form-field input { width: 100%; }
        .mat-mdc-select-placeholder { color: #000; }
        .selector-container { position: relative; margin: 5px 7px 12px 7px; }
        .filter-actions-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    `],
    templateUrl: './location-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterLocationComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy =  new Subject<void>()

    @Input() configuration: IFilterLocationConfiguration

    public dataDistance
    public selectedPostalCode: UntypedFormControl = new UntypedFormControl('', Validators.compose([
        Validators.pattern('[a-zA-Z0-9 ]{0,10}')
    ]))
    public selectedDistance

    constructor(
        private queryService: QueryService,
        private locationService: LocationService,
        private ref: ChangeDetectorRef, ) {}

    ngOnInit(): void {
        this.queryService.paramsData
            .pipe( takeUntil(this.$destroy) )
            .subscribe( data => {
                if ( data ) {
                    // Populates the models with the value from the params state
                    this.selectedPostalCode.setValue(this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyPostalCode))
                    this.selectedDistance = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyDistance)
                    this.dataDistance = this.configuration.distances
                    this.ref.markForCheck()
                }
            })
    }

    ngOnDestroy(): void {
       this.$destroy.next()
       this.$destroy.complete()
    }

    onSubmit() {
        const location = this.locationService.get()
        if (!(this.selectedPostalCode.value || location?.coords?.latitude) || !this.selectedDistance) {
            return
        }

        this.queryService.add(
            this.configuration.dataGroup,
            this.configuration.keyPostalCode,
            this.selectedPostalCode.value,
        )
        this.queryService.add(
            this.configuration.dataGroup,
            this.configuration.keyDistance,
            this.selectedDistance,
        )
    }

    onPostalCodeChanged(event) {
        if (this.selectedPostalCode.value?.length >= 10) {
            return false
        }
    }
}
