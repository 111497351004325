import { ElementRef, Injectable } from '@angular/core'

@Injectable()
export class ThemeService {

    constructor() {}

    setStyleOnElement(cssKey: string, value, elRef: ElementRef): void {
        elRef.nativeElement.style.setProperty(`--${cssKey}`, value)
    }

    setStylesOnElement(styles: any, elRef: ElementRef): void {
        if (!styles) {
            return
        }
        Object.entries(styles).forEach(([key, value]: [string, string]) => {
            this.setStyleOnElement(key, value, elRef)
        })
    }

    clearStylesOnElement(elRef: ElementRef): void {
        elRef.nativeElement.style = ''
    }

}
