import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe } from '@angular/common'

/**
 * Transform an integer into a past date
 * with the value from today's date
 *
 * @example
 * {{ 15 | datePast }}
 */
@Pipe({
    name: 'datePast'
})
export class DatePastPipe implements PipeTransform {
    constructor( private datePipe: DatePipe ) {}
    transform(value: any): any {
        const daysToSubstract = parseInt(value, 10)
        const currentDate = new Date()
        return this.datePipe.transform(currentDate.setDate(currentDate.getDate() - daysToSubstract), 'yyyy-MM-dd')
    }
}
