<div class="control__container">
    <div class="control__header">
        <label class="control__label">{{label ? label : key}}</label>
        <tool-help
            [key]="key"
            [content]="options?.help"></tool-help>
    </div>
    <div class="control__inputs">
        <input
            #enabledButton
            class="control__enable-button"
            type="checkbox"
            [checked]="enabled"
            (change)="processEnable($event)" />
        <input
            #textInput
            class="text-input"
            [value]="currentValue"
            (change)="processChange($event)" />
    </div>
</div>