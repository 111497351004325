<!-- Main Summary container -->
<div class="summary-container summary-fixed" [class.is-active]="isFixed" *ngIf="configuration.display && !isLoading && data">
    <div class="summary-container-inner">
        <!-- Title -->
        <div class="is-border-color-primary">
            <h2 class="is-font-h1 sticky-title" *ngIf="configuration.fieldTitle; else equipmentTitle">
                {{ replaceTemplate(configuration.fieldTitle) }}
            </h2>
            <ng-template #equipmentTitle>
                <h2 class="is-font-h1 sticky-title" *ngIf="configuration.showTitle && ( data.year || data.make || data.model )">
                    {{ data.year }} {{ data.make }} {{ data.model }}
                    <span class="sticky-featured-tag is-bordered is-rounded-small arrow-right" *ngIf="data.is_low_hours">
                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                        Low Hours
                    </span>
                </h2>
            </ng-template>
            <p class="summary-sub-category is-color-opacity-secondary is-font-sm" *ngIf="configuration.labelStickySubtitle">
                {{ parseTemplate( configuration.labelStickySubtitle ) }}
            </p>
        </div>

        <div class="is-border-color-primary price-container" *ngIf="configuration.showPrice && !data.is_list_price_hidden && data.list_price">

            <p class="is-font-h2 is-font-600" *ngIf="isLimitedSupply( data ); else priceTemplate">
                <span class="price-raw">
                    {{ configuration.labelPriceOnLowQuantity }}
                </span>
            </p>

            <ng-template #priceTemplate>
                <p class="is-font-h2 is-font-600" [class.price-has-discount]="data.compare_at_price_multiplier > 1 && configuration.showDiscountedPrice" >
                    <span class="price-discount" *ngIf="!data.is_list_price_hidden && data.list_price && data.compare_at_price_multiplier > 1 && configuration.showDiscountedPrice">
                        {{ getFormatted( (data.list_price * data.compare_at_price_multiplier).toString() , ' - ', '', 'money') }}
                    </span>
                    <span class="price-raw" *ngIf="!data.is_list_price_hidden && data.list_price; else contactForPricing">
                        {{ data.list_price | rasCurrency: data : true }}
                    </span>
                </p>
            </ng-template>

            <ng-template #contactForPricing>
                <span>
                    Contact for pricing
                </span>
            </ng-template>

            <!-- Meter: Hours and miles -->
            <p class="is-color-opacity-secondary is-font-sm meter-container" *ngIf="configuration.labelStickyMeterMiles || configuration.labelStickyMeterHours">
                <span *ngIf="!!( data | rasMeter )">
                    {{ ( data | rasMeter ) }}
                </span>
            </p>

            <!-- Years for subcategory fixed header -->
            <div class="is-border-color-primary year-container-fixed" *ngIf="configuration.showModelYear && data.year">
                <p class="is-color-opacity-secondary is-font-sm">
                    {{ getFormatted( data.year, ' - ') }}
                </p>
            </div>

             <!-- Get started sticky header -->
            <div class="summary-get-started-container">
                <button mat-stroked-button class="is-background-primary is-rounded" (click)="onScrollToForm()">Get Started</button>
            </div>
        </div>
    </div>
</div>

<div class="summary-container" *ngIf="configuration.display && !isLoading && data; else loadingTemplate">
    <div class="summary-container-inner">

        <!-- Title -->
        <div class="is-border-color-primary">
            <p class="summary-updated is-color-tertiary is-font-sm is-font-600" *ngIf="configuration.showLastUpdate && data.listing_date && configuration.labelLastUpdate">
                {{ replaceTemplate( configuration.labelLastUpdate ) }}
            </p>
            <h1 class="is-font-h1" *ngIf="configuration.fieldTitle; else equipmentTitle">{{ replaceTemplate(configuration.fieldTitle) }}</h1>
            <ng-template #equipmentTitle>
                <h1 class="is-font-h1" *ngIf="configuration.showTitle && ( data.year || data.make || data.model )"> {{ data.year }} {{ data.make }} {{ data.model }}</h1>
            </ng-template>
            <h2 class="is-color-opacity-secondary is-font-h5 is-font-500 mt-1" *ngIf="configuration.labelSubTitle">
                {{ replaceTemplate( configuration.labelSubTitle ) }}
            </h2>
        </div>

        <!-- Price -->
        <div class="is-border-color-primary price-container" *ngIf="configuration.showPrice && !data.is_list_price_hidden && data.list_price">

            <p class="is-font-h2 is-font-600" *ngIf="isLimitedSupply( data ); else priceTemplate">
                <span class="price-raw">
                    {{ configuration.labelPriceOnLowQuantity }}
                </span>
            </p>

            <ng-template #priceTemplate>
                <p class="is-font-h2 is-font-600 flex-buttons" [class.price-has-discount]="data.compare_at_price_multiplier > 1 && configuration.showDiscountedPrice" >
                    <span class="price-discount" *ngIf="!data.is_list_price_hidden && data.list_price && data.compare_at_price_multiplier > 1 && configuration.showDiscountedPrice">
                        {{ getFormatted( (data.list_price * data.compare_at_price_multiplier).toString() , ' - ', '', 'money') }}
                    </span>
                    <span class="price-raw" *ngIf="!data.is_list_price_hidden && data.list_price; else contactForPricing">
                        {{ data.list_price | rasCurrency: data : true }}
                    </span>
                    <ng-template #contactForPricing>
                        <span>
                            Contact for pricing
                        </span>
                    </ng-template>
                    <button *ngIf="data.is_financing_eligible && configuration.srcFinancingAvailable && configuration.financingAvailableText"
                        mat-stroked-button
                        (click)="onOpenModal()"
                        class="is-background-primary is-rounded pull-right mt-n1 is-color-white"
                        target="_blank">
                            {{ configuration.financingAvailableText }}
                    </button>
                </p>
            </ng-template>

            <p class="is-font-sm mt-1 monthly-payment-container" *ngIf="data.lowest_monthly_payment">
                <span class="is-color-opacity-secondary">
                    Finance for as low as {{ data.lowest_monthly_payment | rasCurrency : data: false : true }} / month
                </span>
                <a *ngIf="configuration.labelMonthlyPaymentApply" [href]="configuration.linkMonthlyPaymentApply" target="_blank" [ngClass]="configuration.classMonthlyPaymentApply" class="summary-apply-now is-font-700">
                    {{ configuration.labelMonthlyPaymentApply }}
                </a>
            </p>
        </div>

        <!-- Make -->
        <div class="is-border-color-primary make-container" *ngIf="configuration.showMake && data.make && configuration.labelMake">
            <p class="is-color-opacity-secondary is-font-sm mb-1">
                {{ replaceTemplate( configuration.labelMake ) }}
            </p>
            <div class="is-font-h2 is-font-600 make-container-inner">
                <div class="make-container-items">
                    <ng-container *ngIf="data.make && isArray(data.make)">
                        <span *ngFor="let make of data.make; let i = index" [class.hide]="( i + 1 > configuration.limitMake ) && !isMakeFullView">
                            <i>,</i>
                            {{ make }}
                        </span>
                    </ng-container>
                    <span *ngIf="data.make && !isArray(data.make)">
                        {{ data.make }}
                    </span>
                </div>
                <span class="is-font-sm is-font-700 make-view-all is-color-primary" *ngIf="isArray(data.make) && data.make.length > configuration.limitMake" (click)="isMakeFullView = !isMakeFullView">
                    {{ !isMakeFullView ? 'View more': 'View less' }}
                </span>

            </div>

        </div>

        <!-- Year -->
        <div class="is-border-color-primary year-container" *ngIf="configuration.showModelYear && data.year">
            <p class="is-color-opacity-secondary is-font-sm mb-1">
                {{ replaceTemplate( configuration.labelModelYear ) }}
            </p>
            <p class="is-font-h2 is-font-600">{{ getFormatted( data.year, ' - ') }}</p>
        </div>

        <!-- Location & Equipment # & Serial # -->
        <div class="is-border-color-primary summary-details-container" *ngIf="data.branch_state || data.branch_city || data.equipmentId || data.serialId">
            <p class="is-font-sm mb-1" *ngIf="configuration.showLocation && ( data.address1 || data.branch_city || data.branch_state || data.branch_postal_code )">
                <strong>Location</strong>
                <span class="is-color-opacity-secondary is-text-capitalized text-right" *ngIf="!!configuration.linkGoogleMaps">
                    <a
                        [href]="buildGoogleMapsHref(data)"
                        [innerHtml]="buildAddress(data)"
                        target="_blank"></a>
                </span>
                <span class="is-color-opacity-secondary is-text-capitalized text-right" *ngIf="!configuration.linkGoogleMaps">
                    <span [innerHtml]="buildAddress(data)"></span>
                </span>
            </p>
            <p class="is-font-sm mb-1" *ngIf="configuration.showHours && data.branch_hours_of_operation">
                <strong>Hours</strong>
                <span
                    class="is-color-opacity-secondary text-right"
                    [innerHtml]="buildHours(data.branch_hours_of_operation)"></span>
            </p>
            <p class="is-font-sm mb-1" *ngIf="configuration.showEmail && email">
                <strong>Email</strong>
                <a class="is-color-opacity-secondary" href="mailto: {{email}}">{{ email }}</a>
            </p>
            <p class="is-font-sm mb-1" *ngIf="configuration.showEquipmentNumber && (data.equipment_number || data.display_equipment_number)">
                <strong>Equipment #</strong>
                <span class="is-color-opacity-secondary">{{ data.display_equipment_number ?? data.equipment_number }}</span>
            </p>
            <p class="is-font-sm mb-1" *ngIf="configuration.showEquipmentSerial && data.serial_number">
                <strong>Serial #</strong>
                <span class="is-color-opacity-secondary">{{ data.serial_number }}</span>
            </p>
        </div>
    </div>
</div>

<!-- Loading placeholder template -->
<ng-template #loadingTemplate >
    <div class="summary-container" *ngIf="configuration.display">
            <div class="summary-container-inner">
            <div class="is-border-color-primary">
                <div class="is-loading"></div>
            </div>
            <div class="is-loading-container is-border-color-primary">
                <span></span>
                <span></span>
            </div>
            <div class="is-border-color-primary">
                <div class="is-loading"></div>
            </div>
            <div class="is-loading-container is-border-color-primary">
                <span></span>
            </div>
        </div>
    </div>
</ng-template>
