import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Services
import { ConfigurationService } from 'src/app/services/configuration.service'
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { IRangeMonthlyConfiguration } from 'src/app/interfaces/filters/filter-range-monthly.interface'

@Component({
    selector: '#ras-range-monthly',
    templateUrl: './range-monthly.component.html',
    styleUrls: ['./range-monthly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeMonthlyComponent implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    @Input() configuration: IRangeMonthlyConfiguration

    public isLoading: BehaviorSubject<boolean>

    public isFinancing = false
    public minValueFinancing: number = 0
    public maxValueFinancing: number = 15000
    public minValueFinancingSelected: number = null
    public maxValueFinancingSelected: number = null

    constructor( private ref: ChangeDetectorRef,
                 private queryService: QueryService,
                 private configurationService: ConfigurationService ) {}


    ngOnInit() {
        this.isLoading = this.queryService.isLoading
        this.maxValueFinancing = this.configuration.financingMaxValue
        this.minValueFinancing = this.configuration.financingMinValue

        this.queryService.addParamToFetch(this.configuration.dataGroup, this.configuration.keyFinancing)
        this.queryService.addParamToFetch(this.configuration.dataGroup, this.configuration.keyFinancingPrice)

        this.queryService.paramsData
            .pipe( takeUntil(this.$destroy) )
            .subscribe(params => {
                if (!!params && !!params[this.configuration.keyFinancing]) {

                    this.handleParamsFinancing()

                    this.ref.markForCheck()
                }
            })
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    onChangeFinancingToggle( event: boolean ) {
        if (event) {
            this.queryService.add( this.configuration.dataGroup, this.configuration.keyFinancing, 'true' )
        } else {
            this.queryService.remove( this.configuration.dataGroup, this.configuration.keyFinancing, 'true' )
        }
    }

    onChangeFinancing( event: { max: number, min: number } ) {
        this.minValueFinancingSelected = event.min
        this.maxValueFinancingSelected = event.max

        if( event.max === this.configuration.financingMaxValue ) {
            this.queryService.removeParamKey( this.configuration.dataGroup, this.configuration.keyFinancingPriceMax )
        } else {
            this.queryService.add( this.configuration.dataGroup, this.configuration.keyFinancingPriceMax, event.max.toString() )
        }

        if( event.min === this.configuration.financingMinValue ) {
            this.queryService.removeParamKey( this.configuration.dataGroup, this.configuration.keyFinancingPriceMin )
        } else {
            this.queryService.add( this.configuration.dataGroup, this.configuration.keyFinancingPriceMin, event.min.toString() )
        }

        this.ref.detectChanges()
    }

    /**
     * Applies the logic to handle the params for the monthly pricing valye
     * @param params Object with all params
     */
    private handleParamsFinancing() {

        const minCurrent = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyFinancingPriceMin) as string
        const maxCurrent = this.queryService.getParamValue(this.configuration.dataGroup, this.configuration.keyFinancingPriceMax) as string

        this.minValueFinancingSelected = !!minCurrent ? ~~minCurrent : this.minValueFinancing
        this.maxValueFinancingSelected = !!maxCurrent ? ~~maxCurrent : this.maxValueFinancing

        this.isFinancing = this.queryService.hasParam(this.configuration.dataGroup, this.configuration.keyFinancing)
    }

}
