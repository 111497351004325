import { Injectable } from '@angular/core'

/**
 * Stores resolved data, so it is accessible by other instances without the need of re calculate the output
 * Add any property needed to be stored
 */
@Injectable({
  providedIn: 'root'
})
export class ResolvedStore {

    // Stores the width size as the key with a value of the image type to use
    public imageSizes = {}
}
