import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Subject } from 'rxjs'
import { DOCUMENT } from '@angular/common'

// Services
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { IBurgerMenuConfiguration } from 'src/app/interfaces/components/component-burger-menu.interface'

export const BurgerMenuComponentDefaults: IBurgerMenuConfiguration = {
    type: 'burger_menu',
    display: true,
    hostClass: '',
    menuSelector: '',
}

export const BurgerMenuComponentDevOpts: IComponentDevOptions = {
    config: {
        ...BurgerMenuComponentDefaults,
    },
}

@Component({
    selector: 'ras-burger-menu',
    templateUrl: './burger-menu.component.html',
    styleUrls: ['./burger-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerMenuComponent implements OnInit, OnDestroy, IBaseComponent {
    private $destroy = new Subject<void>()

    @ViewChild('button', {read: ViewContainerRef}) button: ViewContainerRef
    @ViewChild('burgerBar', {read: ViewContainerRef}) burgerBar: ViewContainerRef
    @HostBinding('class') hostClass: string

    @Input() configuration: IBurgerMenuConfiguration

    isMenuOpen: boolean = false
    menuElement: Element
    menuOpenClass: string = 'active'

    constructor(
        private elRef: ElementRef,
        @Inject(DOCUMENT) private document: HTMLDocument,
    ) {}

    @HostListener('window:resize') onResizeWindow() {
        if (this.isMenuOpen) {
            this.closeMenu()
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (
            !this.elRef.nativeElement.contains(event.target)
            && !this.menuElement?.contains(event.target)
        ) {
            this.closeMenu()
        }
    }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, BurgerMenuComponentDefaults)
        const { hostClass, menuSelector } = this.configuration
        this.hostClass = hostClass
        this.menuElement = this.document.querySelector(menuSelector)
        if (!this.menuElement) {
            console.error(`BurgerMenu :: Unable to find menu at selector: ${menuSelector}`)
        }
    }

    ngOnDestroy(): void {
        this.$destroy?.next()
        this.$destroy?.complete()
    }

    closeMenu(): void {
        if (!this.menuElement) {
            return
        }
        this.menuElement.classList.remove(this.menuOpenClass)
        this.burgerBar.element.nativeElement.classList.remove(this.menuOpenClass)
        this.isMenuOpen = false
    }

    openMenu(): void {
        if (!this.menuElement) {
            return
        }
        this.menuElement.classList.add(this.menuOpenClass)
        this.burgerBar.element.nativeElement.classList.add(this.menuOpenClass)
        this.isMenuOpen = true
    }

    toggleMenu(): void {
        this.isMenuOpen ? this.closeMenu() : this.openMenu()
    }

    onClick(): void {
        this.toggleMenu()
    }
}
