<div class="selector-container">
    <mat-form-field>
        <input matInput
                [formControl]="selectedPostalCode"
                type="text"
                placeholder="Zip Code"
                (keypress)="onPostalCodeChanged($event)"
                autocomplete="off"/>
    </mat-form-field>
</div>

<div class="selector-container">
    <mat-select placeholder="Select Within" [(ngModel)]="selectedDistance" hideSingleSelectionIndicator>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let distance of dataDistance" [value]="distance.value">
            {{distance.label}}
        </mat-option>
    </mat-select>
</div>

<div class="filter-actions-container">
    <button [class.invisible]="true"
            mat-flat-button class="full-view-toggle is-color-primary apply-view-btn">
    </button>
    <button (click)="onSubmit()"
            mat-flat-button class="is-color-primary apply-filter-btn">
            APPLY
    </button>
</div>