import { ChangeDetectionStrategy, Component, Input, AfterViewInit, ChangeDetectorRef, HostBinding, Inject, OnChanges, SimpleChanges, OnInit } from '@angular/core'
import { DOCUMENT, ViewportScroller } from '@angular/common'

// Services
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IRenderContentConfiguration } from 'src/app/interfaces/components/component-render-content.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const RenderContentComponentDefaults: IRenderContentConfiguration = {
    type: 'render_content',
    display: true,
    class: '',
    id: '',
    content: [],
}

export const RenderContentComponentDevOpts: IComponentDevOptions = {
    config: {
        ...RenderContentComponentDefaults,
        content: [
            {
                element: 'div',
                content: '[div] div content',
            },
            {
                element: 'span',
                content: '[span] span content',
            },
            {
                element: 'p',
                content: '[p] p content',
            },
            {
                element: 'ul',
                childs: [
                    {
                        element: 'li',
                        content: '[li] List example inside [ul]'
                    },
                ]
            },
            {
                element: 'ul',
                styles: 'list-style-type: circle; padding: 0 0 0 15px;',
                childs: [
                    {
                        element: 'li',
                        content: '[li] Another list inside a different [ul]'
                    },
                    {
                        element: 'li',
                        content: '[a]',
                        childs: [
                            {
                                element: 'a',
                                href: 'https://www.rouseservices.com',
                                content: 'All Lowercase Off-site anchor tag with an [icon] ',
                                styles: 'text-transform: lowercase;',
                                childs: [
                                    {
                                        element: 'icon',
                                        icon: 'arrow-right',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        element: 'li',
                        content: '[a-ng]',
                        childs: [
                            {
                                element: 'a-ng',
                                link: '/home',
                                content: 'All Caps Same-site anchor tag',
                                styles: 'text-transform: uppercase;'
                            },
                        ]
                    },
                    {
                        element: 'li',
                        content: '[img]',
                        childs: [
                            {
                                element: 'img',
                                src: 'https://catalog-assets.rousesales.com/xyz/logos/rouse_white_logo.png',
                                alt: 'Image Alt Text',
                                styles: 'background-color: blue; padding: 3px 5px; width: 10%; height: 10%;',
                                loading: 'lazy',
                            },
                        ]
                    },
                    {
                        element: 'li',
                        content: '[button]',
                        childs: [
                            {
                                element: 'button',
                                content: 'Button content',
                            }
                        ]
                    },
                    {
                        element: 'li',
                        content: '[icon]',
                        childs: [
                            {
                                element: 'icon',
                                icon: 'font-awesome-logo-full',
                            }
                        ]
                    },
                ]
            },
            {
                element: 'raw',
                content: '<div style="background-color: #CCC; margin: 5px; padding: 5px;">[raw] Raw HTML parsed from a string! <br /> Unsafe content stripped. <script>alert("uh oh!")</script></div>'
            },
            {
                element: 'page-jump',
                link: '#test_form',
                content: 'Page jump to dev page form at id #test_form'
            },
            {
                element: 'div',
                styles: 'width: 150px; margin: 15px; background-color: #333; padding: 15px; color: #FFF;',
                content: '[currency-selector]',
                childs: [
                    {
                        element: 'currency-selector'
                    }
                ]
            }
        ]
    },
}

@Component({
    selector: '[app-render-content]',
    templateUrl: './render-content.component.html',
    styleUrls: ['./render-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderContentComponent implements OnInit, OnChanges {
    @Input() configuration: IRenderContentConfiguration

    @HostBinding('class') hostClass: string
    @HostBinding('attr.id') hostId: string

    constructor( private ref: ChangeDetectorRef,
                 @Inject(DOCUMENT) private document,
                 private viewport: ViewportScroller ) { }

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, RenderContentComponentDefaults)
        this.hostClass = this.configuration.class ? this.configuration.class : null
        this.hostId = this.configuration.id ? this.configuration.id : null
        this.ref.detectChanges()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.configuration?.currentValue?.class !== changes.configuration?.previousValue?.class) {
            this.hostClass = changes.configuration.currentValue.class
        }
        if (changes.configuration?.currentValue?.id !== changes.configuration?.previousValue?.id) {
            this.hostId = changes.configuration.currentValue.id
        }
    }

    onPageJump(link: string) {
        if (!link) {
            return
        }

        const element = this.document.querySelector(link)

        if(element) {
            this.viewport.scrollToPosition([0, element.offsetTop - 30 ])
        }
    }

}
