import { Directive, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core'

import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { CurrencyService } from 'src/app/services/currency.service'

import { ProposalService } from '../../services/proposal.service'

@Directive({
    selector: '[rasProposal]'
})
export class ProposalDirective implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()
    private $download = new Subject<void>()
    private pdfUrl: string
    private equipmentNumber: string
    private currencyUpdated: boolean = false
    @Input() equipmentId: string
    @Input() branchPhone: string

    @HostListener('click') onCLick() {
       this.$download.next()
    }

    constructor(
        private proposalService: ProposalService,
        @Inject( Window ) private window: Window,
        private currencyService: CurrencyService,
    ) {}

    ngOnInit(): void {
        this.$download
            .pipe(
                takeUntil( this.$destroy ),
                debounceTime( 500 )
            )
            .subscribe( () => this.downloadProposal() )

        this.currencyService.selectedCurrency
            .pipe(takeUntil(this.$destroy))
            .subscribe(data => {
                if (data) {
                    this.currencyUpdated = true
                }
            })
    }
    ngOnDestroy(){
        this.$destroy.next()
        this.$destroy.complete()
    }

    private downloadProposal() {
        if( this.equipmentId !== this.equipmentNumber || this.currencyUpdated ) {
            this.currencyUpdated = false
            this.proposalService.getProposal( this.equipmentId, this.formatPhone(this.branchPhone) )
            .pipe(
                takeUntil( this.$destroy )
            )
            .subscribe(( pdf: any ) =>  {
                if( pdf && pdf.url ) {
                    this.equipmentNumber = this.equipmentId
                    this.pdfUrl = pdf.url
                    this.openProposal()
                }
            })
        } else {
            this.openProposal()
        }
    }

    private openProposal() {
        this.window.open( this.pdfUrl, '_blank')
    }

    private formatPhone(branchPhone: string) {
        if (!branchPhone) {
            return
        }

        if(branchPhone.length === 11 && branchPhone.startsWith('1')) {
            return branchPhone.substring(1)
        }

        return branchPhone
    }

}
