import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { SwiperContainer } from 'swiper/element'

// Services
import { UtilityService } from 'src/app/services/utility.service'
import { SearchService } from 'src/app/services/search.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IItemsCarouselConfiguration } from 'src/app/interfaces/components/component-iterms-carousel.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { QueryService } from 'src/app/services/query.service'

export const ItemsCarouselComponentDefaults: IItemsCarouselConfiguration = {
    type: 'items_carousel',
    display: true,
    cssClass: '',
    id: '',
    items: [],
    itemsConfiguration: {
        showImage: true,
        showTitle: true,
        showLocation: true,
        showPrice: true,
        showMonthlyPayment: true,
        showMeter: true,
    },
    link: '/equipment/detail/{{equipment_number}}',
    sliderConfiguration: {
        freeMode: false,
        shortSwipes: true,
    },
    addLastItem: null,
    header: null,
}

export const ItemsCarouselComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ItemsCarouselComponentDefaults,
        dataGroup: 'featured-equipment',
        addLastItem: {
            limit: 5,
            cssClass: 'home-featured-list-last',
            link: {
                url: '/equipment/search',
                params: {
                    'equipment.is_featured': true,
                },
            },
            content: [
                {
                    element: 'div',
                    class: 'home-featured-list-container',
                    childs: [
                        {
                            element: 'icon',
                            icon: 'search',
                        },
                        {
                            element: 'div',
                            content: 'View All Featured Equipment',
                        },
                    ],
                },
            ],
        },
        header: {
            title: 'Featured Machines',
            link: {
                params: {
                    'equipment.is_featured': true,
                },
                url: '/equipment/search',
                label: 'View All',
            }
        },
    },
}

@Component({
    selector: '[ras-items-carousel]',
    templateUrl: './items-carousel.component.html',
    styleUrls: ['./items-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsCarouselComponent implements IBaseComponent, OnInit, AfterViewInit, OnDestroy {
    private $destroy = new Subject<void>()
    private readonly isIE: boolean = UtilityService.isIE()

    public isLoading
    public sliderConfiguration: any = {}
    public index
    public link = {
        baseUrl: null,
        field: null
    }

    @Input() configuration: IItemsCarouselConfiguration
    @Input() items: Array<any> = []

    @ViewChild('itemsContainer') itemsContainer: ElementRef
    @ViewChild('slider', { static: false }) swiper: ElementRef<SwiperContainer>
    @HostBinding('class') get parentClass() { return (this.isIE ? 'is-ie ' : '') + (this.items.length ? '' : 'hide') }

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,
        private queryService: QueryService,
    ) {}

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, ItemsCarouselComponentDefaults)
        // Create carousel with data from the data group
        if (this.configuration.dataGroup && !this.items.length) {
            this.searchService.requestData(this.configuration.dataGroup)
                .pipe(takeUntil(this.$destroy))
                .subscribe(data => {
                    this.items = data.data ? data.data : []
                    this.isLoading = this.searchService.isLoading(this.configuration.dataGroup)
                    this.ref.markForCheck()
                })
        } else {
            this.items = this.configuration.items ? this.configuration.items : []
        }

        // Set configuration
        this.setConfiguration()
    }

    ngAfterViewInit(): void {
        Object.assign(this.swiper.nativeElement, this.sliderConfiguration)
        this.swiper.nativeElement.initialize()
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    getLink(data: any) {
        return UtilityService.replaceTemplate(this.configuration.link, data)
    }

    getParams(data: any, link?: boolean) {
        if (link) {
            if (this.configuration.linkParams) {
                return this.queryService.getCustomParams(this.createParams(data, this.configuration.linkParams))
            }
            return this.queryService.getCustomParams({})
        } else {
            return this.queryService.getCustomParams(data)
        }
    }

    onUnsetNavigation() {
        if (this.itemsContainer) {
            this.itemsContainer.nativeElement.scrollLeft = 0
        }
    }

    private setConfiguration(): void {
        const overrideConfiguration = (this.configuration.sliderConfiguration) ? this.configuration.sliderConfiguration : {}
        this.sliderConfiguration = {
            spaceBetween: 30,
            autoHeight: false,
            freeMode: true,
            shortSwipes: false,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next-items',
                prevEl: '.swiper-button-prev-items',
            },
            autoplay: this.items.length === 1 ? false : {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            watchOverflow: true,
            breakpoints: {
                600: {
                    slidesPerView: 2,
                },
                767: {
                    slidesPerView: 3,
                }
            },
            ...overrideConfiguration
        }
    }

    private createParams(data: any, mainParams: any) {
        const params = {}
        Object.keys(mainParams).forEach(paramKey => params[paramKey] = data[mainParams[paramKey]])
        return params
    }
}
