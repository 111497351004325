import { Inject, Injectable } from '@angular/core'
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router'

import { distinctUntilChanged, filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class CanonicalUrlService {

    constructor( @Inject( Window ) private window, private router: Router ) {}

    init() {
        this.router.events
            .pipe(
                filter( ( event: Event|RouterEvent ) => event instanceof NavigationEnd ),
                distinctUntilChanged( ( event: RouterEvent, event2: RouterEvent ) => event.url === event2.url ),
            )
            .subscribe( () => {
                // remove existing canonical link tags from head using for of loop
                const head = document.getElementsByTagName('head')[0]
                const links = head.getElementsByTagName('link')
                for (const item of Object.keys(links)) {
                    if (links[item]?.rel === 'canonical') {
                        head.removeChild(links[item])
                        break
                    }
                }

                // add new canonical link tag to head
                const link = document.createElement('link')
                link.rel = 'canonical'
                link.href = window.location.origin + window.location.pathname
                document.head.appendChild(link)
            })
    }

}
