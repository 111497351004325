<ul>
    <li *ngFor="let item of items; let i = index">
        <mat-checkbox color="primary"
                      [class.hide]="i + 1 > configuration.itemsToShow && !isFullView"
                      [(ngModel)]="item.selected"
                      (ngModelChange)="instantApply ? onChange() : null">
        {{ getName(item.name) }}
        </mat-checkbox>
    </li>

</ul>
<div class="filter-actions-container">
    <button [class.invisible]="items.length <= configuration.itemsToShow"
            mat-flat-button class="full-view-toggle is-color-primary apply-view-btn"
            (click)="isFullView = !isFullView">
            {{!isFullView ?  (configuration.filterOpenText || 'View More') : (configuration.filterCloseText || 'View Less')}}
    </button>
    <button *ngIf="!instantApply"
            (click)="onChange()"
            mat-flat-button class="is-color-primary apply-filter-btn">
            APPLY
    </button>
</div>
