import { ConfigurationService } from 'src/app/services/configuration.service'
import { takeUntil } from 'rxjs/operators'
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import get from 'lodash-es/get'
import set from 'lodash-es/set'

// Services
import { QueryService } from 'src/app/services/query.service'
import { SearchService } from './../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'

// Pipes
import { MoneyPipe } from './../../../pipes/money.pipe'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IListComponentConfiguration } from 'src/app/interfaces/components/component-list.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const ListComponentDefaults: IListComponentConfiguration = {
    type: 'list_assets',
    display: true,
    id: '',
    cssClass: '',
    displayOptions: [
        {
            icon: 'th-large',
            value: 'search-result-grid',
        },
        {
            icon: 'align-left',
            value: 'search-result-list',
        }
    ],
    displayType: 'search-result-grid',
    listTitle: '',
    hide: false,
    showHeader: false,
    showResultsCount: false,
    resultsCountLabel: 'Results',
    showPagination: false,
    showPaginationSize: false,
    showPaginationSizeAtTop: false,
    pagination: {
        pagesAvailable: 3,
        sizesAvailable: [
            12,
            24,
            48,
            96
        ],
        sortField: 'equipment_number'
    },
    showSort: false,
    sortOptions: {
        'list_price@asc': {
            field: 'list_price',
            direction: 'asc',
            label: 'Price Low to High'
        },
        'list_price@desc': {
            field: 'list_price',
            direction: 'desc',
            label: 'Price High to Low'
        },
        'branch_city@asc': {
            field: 'branch_city',
            direction: 'asc',
            label: 'City A - Z'
        },
        'branch_city@desc': {
            field: 'branch_city',
            direction: 'desc',
            label: 'City Z - A'
        }
    },
    link: '/equipment/detail/{{equipment_number}}',
    linkParams: {},
    defaultParams: {},
    listLink: '',
    listLinkLabel: '',
    listLinkParams: {},
    listPreviousLabel: 'Previous',
    listNextLabel: 'Next',
    labelTopPagination: '',
    noResults: {
        title: 'Sorry, no results found for this search.',
        subtitle: 'Please update your filters and try again or <a href="/">click here</a> to begin another search.',
        styles: {},
        title_styles: '',
        subtitle_styles: '',
    },
    addLastItem: null,
    childConfigs: {},
}

export const ListComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ListComponentDefaults,
        dataGroup: 'category',
        resultsCountLabel: 'Results Label',
        pagination: {
            sortField: 'quantity',
            sizesAvailable: [
                5, 10, 15
            ],
            pagesAvailable: 5,
            direction: 'desc',
        },
        listTitle: 'List Title',
        listLink: '/equipment/search',
        listLinkLabel: 'List Link Label',
        listLinkParams: {
            'equipment.is_featured': true,
        },
        link: '/equipment/search',
        linkParams: {
            'equipment.filter_category': 'category'
        },
        labelTopPagination: 'Top Pagination Label',
        listPreviousLabel: 'Previous Label',
        listNextLabel: 'Next Label',
        addLastItem: {
            link: '/equipment/search',
            limit: 1,
            content: [
                {
                    element: 'div',
                    childs: [
                        {
                            element: 'icon',
                            icon: 'search',
                        },
                        {
                            content: 'Looking for Something Else?',
                            element: 'div',
                        }
                    ]
                }
            ]
        },
        showHeader: true,
        showResultsCount: true,
        showPagination: true,
        showPaginationSize: true,
        showSort: true,
        childConfigs: {
            list_item: {
                itemTitle: '{{category}} [{{quantity}}]',
                showImage: true,
                showTitle: true,
                showPrice: true,
                showLocation: true,
                showMonthlyPayment: true,
                showQuantity: true,
            }
        },
    }
}

@Component({
    selector: '[ras-list]',
    styleUrls: ['./list.component.scss'],
    templateUrl: './list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit, OnDestroy, OnChanges, IBaseComponent {

    private readonly DIRECTION_CHAR = '@'
    private $destroy = new Subject<void>()

    @Input() configuration: IListComponentConfiguration
    @Input() isLoading: boolean
    @Input() data: any

    @ViewChild('anchorTop') anchorTop: ElementRef
    @ViewChild('header') header: ElementRef
    @ViewChild('itemsContainer') itemsContainer: ElementRef

    @HostBinding('class') get cssClass() {
        return `
            ${this.configuration.hide && this.data && !this.data.length ? 'hide' : ''}
            ${this.configuration.cssClass ? this.configuration.cssClass : ''}
            `
    }

    @HostBinding('attr.id') get id() { return this.configuration.id ? this.configuration.id : null }

    public readonly isIE: boolean = UtilityService.isIE()
    public canGoPrevious = true
    public canGoNext = true
    public sort: Array<string> = []
    public displayType = 'search-result-grid'
    public location = ''

    public pagination = {
        index: 0,
        size: 30,
        sizesAvailable: [],
        sort: '',
        totalPages: 1,
        pages: [],
        totalItems: null
    }

    public link = {
        baseUrl: null,
        field: null
    }

    searchSubscription: Subscription

    constructor(private searchService: SearchService,
                private ref: ChangeDetectorRef,
                private queryService: QueryService,
                private moneyPipe: MoneyPipe,
                private configurationService: ConfigurationService) {}

    ngOnInit(): void {
        this.configuration = UtilityService.populateConfigDefaults(this.configuration, ListComponentDefaults)
        this.assignConfiguration()
        this.buildLinkData()

        if (!!this.configuration.dataGroup) {
            this.loadData()
        }
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    loadData(): void {
        this.isLoading = true

        // Request data of type equipment
        this.searchSubscription = this.searchService.requestData(this.configuration.dataGroup)
            .pipe(takeUntil(this.$destroy))
            .subscribe(data => {
                if (!!data) {
                    this.isLoading = false
                    this.data = data.data
                    this.formatData(data.pagination)
                    this.ref.detectChanges()
                    this.ref.markForCheck()
                }
            })
    }

    reloadData(): void {
        this.searchSubscription.unsubscribe()
        this.searchService.unsubscribeData()
        this.loadData()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !changes.configuration.isFirstChange()
            && changes.configuration?.currentValue?.dataGroup !== changes.configuration?.previousValue?.dataGroup
        ) {
            this.reloadData()
        }
    }

    onPaginationSize(size: number) {
        this.scrollToHeader()

        if(this.pagination.index > 0) {
            this.queryService.add(
                `${this.configuration.dataGroup}:pagination`,
                'page',
                '0'
            )
        }

        this.queryService.add(
            `${this.configuration.dataGroup}:pagination`,
            'size',
            size.toString()
        )

    }

    onPaginationNavigation(incremental: number) {
        let index = this.pagination.index + incremental
        index = (index >= 0) ? index : 1

        this.scrollToHeader()

        this.queryService.add(
            `${this.configuration.dataGroup}:pagination`,
            'page',
            index.toString()
        )

    }

    onPaginationPage(page: number) {
        if (page === this.pagination.index) {
            return
        }

        this.scrollToHeader()

        this.queryService.add(
            `${this.configuration.dataGroup}:pagination`,
            'page',
            page.toString()
        )

    }

    onPaginationSort(sort: string) {
        const [field, direction] = sort.split(this.DIRECTION_CHAR)
        this.queryService.add(
            `${this.configuration.dataGroup}:pagination`,
            'field',
            field
        )

        this.queryService.add(
            `${this.configuration.dataGroup}:pagination`,
            'direction',
            direction
        )
    }

    onUnsetNavigation() {
        if (this.itemsContainer) {
            this.itemsContainer.nativeElement.scrollLeft = 0
        }
    }

    getImgAltText(equipment: any): string {
        return UtilityService.getImgAltText(equipment)
    }

    getParams(data: any) {
        if (this.configuration.linkParams) {
            return this.queryService.getCustomParams(this.createParams(data, this.configuration.linkParams))
        }
        return this.queryService.getCustomParams({})
    }

    getParamsListLink() {
        if (this.data && this.data.length && this.configuration.listLinkParams) {
            return this.queryService.getCustomParams(this.createParams(this.data[0], this.configuration.listLinkParams))
        }
        return this.queryService.getCustomParams({})
    }

    getPrice(price: number | Array<number>) {
        if (Array.isArray(price)) {
            return price.map(value => '$' + this.moneyPipe.transform(value)).join(' - ')
        } else {
            return this.moneyPipe.transform(price)
        }
    }

    replaceTemplate(template: string, item: any) {
        return UtilityService.replaceTemplate(template, item)
    }

    onChangeDisplayType( type: string ) {
        this.displayType = type
        set(this.configuration, 'childConfigs.list_item.displayType', type)
    }

    buildLinkData() {
        if (this.configuration.link) {
            const splitted = this.configuration.link.split('{{')
            const baseUrl = splitted[0] ? splitted[0] : this.configuration.link
            const field = splitted[1] ? splitted[1].replace('}}', '') : null

            this.link.baseUrl = baseUrl
            this.link.field = field
        }
    }

    getLink(data: any) {
        if (data && this.configuration.link && data[this.link.field]) {
            return [this.link.baseUrl, data[this.link.field]]
        } else if (this.configuration.link) {
            return [this.configuration.link]
        } else {
            return
        }
    }

    private createParams(data: any, mainParams: any) {
        const params = {}
        Object.keys(mainParams).forEach(paramKey => params[paramKey] = data[mainParams[paramKey]])
        return params
    }

    private scrollToHeader() {
        this.anchorTop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    /**
     * Assign the component state from the configuration
     */
    private assignConfiguration() {
        this.displayType = get(this.configuration, ['displayOptions', '0', 'value'], this.displayType)
        this.sort = Object.keys(this.configuration.showSort ? this.configuration.sortOptions : {})
        this.pagination.sizesAvailable = get(this.configuration, 'pagination.sizesAvailable', [30])

        // Register default values for pagination size
        this.queryService.addDefaultParam(`${this.configuration.dataGroup}:pagination`, 'size', this.pagination.sizesAvailable[0])
        this.queryService.addDefaultParam(`${this.configuration.dataGroup}:pagination`, 'field', get(this.configuration, 'pagination.sortField'))
        this.queryService.addDefaultParam(`${this.configuration.dataGroup}:pagination`, 'direction', get(this.configuration, 'pagination.direction'))

        // Register default values provided by the configuration
        if (this.configuration.defaultParams) {
            Object.keys(this.configuration.defaultParams).forEach((key: string) => {
                this.queryService.addDefaultParam(this.configuration.dataGroup, key, this.configuration.defaultParams[key])
            })
        }
    }

    private formatData(data) {
        let size = this.pagination.sizesAvailable[0]
        let index = 0
        let totalPages = 1
        let direction
        let field
        let totalItems = 0

        // Set from the params
        if (data) {
            size = data.size ? data.size : size
            index = data.index ? data.index : index
            totalPages = data.total_pages ? data.total_pages : totalPages
            totalItems = data.total_items ? data.total_items : totalItems
            // Important: note that the convention needs to match the configuration
            // @example: {field}{DIRECTION_CHAR}{direction}: notice {DIRECTION_CHAR} char determines the direction
            if (data.direction && data.field) {
                direction = data.direction
                field = data.field
                this.pagination.sort = `${field}${this.DIRECTION_CHAR}${direction}`
            }
        }

        // Set to the state
        this.pagination.size = size
        this.pagination.index = index
        this.pagination.totalPages = totalPages
        this.pagination.pages = this.getPaginationPages(totalPages, index)
        this.pagination.totalItems = totalItems

        this.canGoPrevious = !(index > 0)
        this.canGoNext = !(index < totalPages - 1)
    }


    private getPaginationPages(totalPages: number, currentPageIndex: number) {
        const pagesAvailables = get(this.configuration, 'pagination.pagesAvailable', 3)

        if (totalPages <= pagesAvailables) {
            return [...Array(totalPages).keys()].map(key => key)
        }

        const pages = []
        let startIndex = Math.min(totalPages + 1 - pagesAvailables, Math.max(0, currentPageIndex - 1))
        const endIndex = Math.min(startIndex + pagesAvailables, totalPages)
        while (startIndex < endIndex) {
            pages.push(startIndex++)
        }
        return pages
    }
}
