import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

import { LogService } from 'src/app/services/log.service'

interface IFaviconConfiguration {
    href: string
    rel: string
    sizes?: string
    type?: string
}

@Injectable({
    providedIn: 'root'
})
export class FaviconService {
    private readonly defaultIcon = 'image/x-icon' // Default icon Rel attribute value
    private readonly defaultIconId = 'ras-icon' // Default icon element ID

    constructor(@Inject(Window) private window,
                @Inject(DOCUMENT) private document: HTMLDocument) { }

    init(favicons: Array<IFaviconConfiguration>) {
        if(!favicons) {
            LogService.log('[Favicon(S)]: No favicons provided', 'error')
        } else {
            favicons.forEach( icon => (icon.type && icon.type === this.defaultIcon) ? this.loadDefaultIcon(icon) : this.loadIcon(icon) )
        }
    }

    private loadDefaultIcon( icon: IFaviconConfiguration ) {
        const defaultIcon: HTMLElement = document.getElementById(this.defaultIconId)
        this.setIcon(defaultIcon, icon)
    }

    private loadIcon( icon: IFaviconConfiguration ) {
        const link: HTMLLinkElement = this.document.createElement('link')
        this.document.head.appendChild(this.setIcon(link, icon))
    }

    private setIcon(element: HTMLElement, icon: IFaviconConfiguration) {
        if(icon.sizes) {
            element.setAttribute('sizes', icon.sizes)
        }

        if(icon.type) {
            element.setAttribute('type', icon.type)
        }

        if(icon.rel) {
            element.setAttribute('rel', icon.rel)
        } else {
            LogService.log('[Favicon(S)]: Missing favicon REL', 'error')
        }

        if(icon.href) {
            element.setAttribute('href', icon.href)
        } else {
            LogService.log('[Favicon(S)]: Missing favicon URL', 'error')
        }

        return element
    }

}
