<div class="info-box">
    <ng-container
        *ngFor="let item of configuration.items">
        <ng-container
            [ngSwitch]="item.type">
            <div
                *ngSwitchCase="'title'"
                class="title">
                {{item.data.content}}
            </div>
            <div
                *ngSwitchCase="'body'"
                class="body">
                {{item.data.content}}
            </div>
            <div
                *ngSwitchCase="'html'"
                class="body">
                <ng-container *ngTemplateOutlet="buildHtml; context: { $implicit: item.data.content }"></ng-container>
            </div>
            <div
                *ngSwitchCase="'phone'"
                class="contact">
                <div class="label">{{item.data.label}}</div>
                <div class="data">
                    <a href="tel:{{item.data.tel}}">{{item.data.display}}</a>
                </div>
            </div>
            <div
                *ngSwitchCase="'email'"
                class="contact">
                <div class="label">{{item.data.label}}</div>
                <div class="data">
                    <a href="mailto:{{item.data.address}}">{{item.data.address}}</a>
                </div>
            </div>
            <div
                *ngSwitchCase="'button'"
                class="button">
                <a
                    [href]="item.data.href"
                    [target]="!!item.data.target ? item.data.target : ''">{{item.data.label}}</a>
            </div>
            <div
                *ngSwitchCase="'spacer'"
                class="spacer">
                &nbsp;
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #buildHtml let-items>
    <ng-container
        *ngFor="let item of items">
        <ng-container
            *ngTemplateOutlet="(item | hasProp: 'type') ? tagBuilder : justText; context: { $implicit: item }">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #tagBuilder let-item>
    <ng-container
        [ngSwitch]="item.type">
        <a
            *ngSwitchCase="'a'"
            [href]="item.href">
            {{item.display}}
        </a>
    </ng-container>
</ng-template>

<ng-template #justText let-text>
    <span [innerHtml]="text"></span>
</ng-template>