import { Injectable } from '@angular/core'
import { CollapsableComponent } from './../../filters/components/collapsable/collapsable.component'

/**
 * @description Maps the wrapper components, splitted from the base-component to break the circular dependency
 * > Created as a servive so the same instance is used
 * > The key must match the value retrieved on the configuration response
 * @see ./zone.component.ts
 */
@Injectable({
    providedIn:'root'
})
export class WrapperComponentMapping {
    public map = {
        // Containers
        collapsable: CollapsableComponent
    }
}
