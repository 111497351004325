import { Injectable } from '@angular/core'
import { ExternalComponent } from './external/external.component'
import { FooterBasic1Component } from './footer-basic-1/footer-basic-1.component'
import { FooterCustom1Component } from './footer-custom-1/footer-custom-1.component'
import { HeaderBasic1Component } from './header-basic-1/header-basic-1.component'

@Injectable({
    providedIn:'root'
})
export class HeadersFootersComponentMapping {
    public map = {
        external: ExternalComponent,
        header_basic_1: HeaderBasic1Component,
        footer_basic_1: FooterBasic1Component,
        footer_custom_1: FooterCustom1Component,
    }
}
