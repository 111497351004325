<div class="selector-container">
    <mat-select placeholder="Make" [(ngModel)]="selectedMake" (ngModelChange)="onChange()" hideSingleSelectionIndicator>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let make of dataMake" [value]="make">
            {{make}}
        </mat-option>
    </mat-select>

    <mat-label *ngIf="!selectedMake && !selectedModel && isLoading | async">
        <div class="select-placeholder-container">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
    </mat-label>
</div>

<div class="selector-container">
    <mat-select placeholder="Model" [(ngModel)]="selectedModel" (ngModelChange)="onChange()" [disabled]="!selectedMake" hideSingleSelectionIndicator>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let model of dataModel" [value]="model">
            {{model}}
        </mat-option>
    </mat-select>

    <mat-label *ngIf="!selectedModel && selectedMake && isLoading | async">
        <div class="select-placeholder-container">
            <span>Loading...</span>
            <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
    </mat-label>
</div>

