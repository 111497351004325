<div class="chips-header-container mb-2">

    <p *ngIf="configuration.titleLabel && ( items.length || defaultItems.length )" class="mt-0 mb-0 is-font-600">
        {{ configuration.titleLabel }}
    </p>

    <!-- Chips save and clear section -->
    <div>
        <button *ngIf="configuration.isClearable && items.length"
                class="ras-btn-link is-color-secondary is-font-600"
                tabindex="0"
                (click)="onClearAll()">
            Clear All
        </button>
        <button *ngIf="configuration.isSaveEnabled && items.length"
                #saveButton
                tabindex="0"
                class="ras-btn-link is-color-primary is-font-600"
                (click)="onOpenDialog()">
            Save
        </button>
    </div>
</div>

<!-- Save filter menu -->
<ng-template #menu>
    <mat-dialog-content>
        <div class="chips-save-container" (click)="$event.stopPropagation()">
            <div class="chips-save-header" (click)="$event.stopPropagation()">
                <p class="is-font-h3 mt-0 mb-1">Custom Filters</p>
                <p class="is-color-secondary mt-0 mb-3 is-font-sm">Provide a name for your Quick Filter:</p>
            </div>
            <div class="chips-save-content" (click)="$event.stopPropagation()" [formGroup]="saveFilterForm">
                <mat-form-field>
                    <mat-label>Filter name</mat-label>
                    <input tabindex="0"
                           style="margin-top:20px"
                           matInput
                           placeholder="Type the name"
                           #filterNameInput
                           formControlName="filterName"
                           (keyup.enter)="onSaveFilters()">
                    <mat-error>
                        Enter a valid filter name
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="chips-save-footer mt-3" (click)="$event.stopPropagation()">
                <button tabindex="0"
                        mat-button
                        class="is-color-secondary ras-btn-link ml-2"
                        (click)="onCancelFilters()">
                    Cancel
                </button>
                <button class="ras-btn-link"
                        tabindex="0"
                        mat-button
                        (click)="onSaveFilters()"
                        [disabled]="!saveFilterForm.dirty"
                        [class.is-color-primary]="saveFilterForm.dirty"
                        [class.is-color-secondary]="!saveFilterForm.dirty">
                    Save
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<!-- Chips items -->
<ul role="listbox">
    <li *ngFor="let item of defaultItems"
        class="default-chip filter-chip"
        role="option"
        [attr.aria-label]="getAriaLabel( item.label, 'Filtering by:' )"
        [attr.aria-disabled]="!configuration.isDefaultsClearable"
        [class.disabled]="!configuration.isDefaultsClearable">
            {{ item.label }}
        <span (click)="onRemoveChip(item.group, item.key, item.value)"
              (keydown.enter)="onRemoveChip(item.group, item.key, item.value)"
              [attr.aria-label]="getAriaLabel( item.label, 'Remove filter:' )">
            x
        </span>
    </li>
    <li *ngFor="let item of items"
        class="filter-chip"
        role="option"
        [attr.aria-label]="getAriaLabel( item.label, 'Filtering by:' )">
        {{ item.label }}
        <span (click)="onRemoveChip(item.group, item.key, item.value)"
              (keydown.enter)="onRemoveChip(item.group, item.key, item.value)"
              tabindex="0"
              [attr.aria-label]="getAriaLabel( item.label, 'Remove filter:' )">
            x
        </span>
    </li>
</ul>
