import { ComponentFactoryResolver, Injectable } from '@angular/core'

// Services
import { ConfigurationService } from 'src/app/services/configuration.service'
import { LogService } from 'src/app/services/log.service'
import { UtilityService } from 'src/app/services/utility.service'

// Components
import { BlankComponent } from '../blank/blank.component'
import { WrapperComponentMapping } from './wrapper-component.mapping'
import { BaseComponentMapping } from './base-component.mapping'
import { BaseComponent } from './base.component'
import { HeadersFootersComponentMapping } from '../../headers-footers/base-headers-footers.mapping'
import { ZoneDirective } from './zone.directive'
import { HeaderComponent } from '../header/header.component'
import { InjectContentComponent } from '../inject-content/inject-content.component'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'

// Any component that can be configured via the NG configuration tool
const updatedComponents = ['form']

@Injectable( {providedIn: 'root'} )
export class RenderService {

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private componentMap: BaseComponentMapping,
        private headersFootersMap: HeadersFootersComponentMapping,
        private wrapperComponent: WrapperComponentMapping,
        private configurationService: ConfigurationService,
    ) { }

    public header(structureId: string, zoneHost: ZoneDirective) {
        const { templateKey, configKey } = this.configurationService.getConfig(`structures.${structureId}.header`) ?? {}
        if (!!templateKey && !!configKey) {
            const headerComponent = this.headersFootersMap.map[templateKey]
            if (headerComponent) {
                const headersConfigsAvailable = this.configurationService.configuration.headers
                const componentInstance = new BaseComponent(headerComponent)
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInstance.component)
                const componentRef = zoneHost.viewContainerRef.createComponent<IBaseComponent>(componentFactory)
                const headerConfig = headersConfigsAvailable[configKey]
                if (!!headerConfig) {
                    componentRef.instance.configuration = headerConfig
                }
            }
        } else {
            // support for old headers, REMOVE when all old headers have been converted
            const componentInstance = new BaseComponent(HeaderComponent)
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInstance.component)
            zoneHost.viewContainerRef.createComponent<IBaseComponent>(componentFactory)
        }
    }

    public footer(structureId: string, zoneHost: ZoneDirective) {
        const { templateKey, configKey } = this.configurationService.getConfig(`structures.${structureId}.footer`) ?? {}
        if (!!templateKey && !!configKey) {
            const footerComponent = this.headersFootersMap.map[templateKey]
            if (footerComponent) {
                const footersConfigsAvailable = this.configurationService.configuration.footers
                const componentInstance = new BaseComponent(footerComponent)
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInstance.component)
                const componentRef = zoneHost.viewContainerRef.createComponent<IBaseComponent>(componentFactory)
                const footerConfig = footersConfigsAvailable[configKey]
                if (!!footerConfig) {
                    componentRef.instance.configuration = footerConfig
                }
            }
        } else {
            // support for old footers, REMOVE when all old footers have been converted
            const componentInstance = new BaseComponent(InjectContentComponent)
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInstance.component)
            const componentRef = zoneHost.viewContainerRef.createComponent<InjectContentComponent>(componentFactory)
            const footerConfig = this.configurationService.getConfig(`custom.footer`)
            if (!!footerConfig) {
                componentRef.instance.setHtml(footerConfig.replace('{{year}}', new Date().getFullYear().toString()))
            }
        }
    }

    /**
     *
     * @param structureId Id of the structure from the configuration object
     * @param zoneId Id of the zone from the configuration object
     * @param zoneHost Reference of the host that will have the reference to inject the rendered components to it
     */
    public zone( structureId: string, zoneId: string, zoneHost: ZoneDirective ) {
        try {
            const componentsInZone = this.configurationService.getConfig(`structures.${structureId}.${zoneId}`)

            if (componentsInZone) {
                componentsInZone.forEach( componentKey => {

                    // Render the components within the specific zone
                    const config = this.configurationService.configuration.components[componentKey]
                    const componentType = config.type
                    if (updatedComponents.find(c => c === componentType)) {
                        this.updatedComponent(componentKey, zoneHost)
                    } else {
                        this.component(componentKey, zoneHost)
                    }
                })
            }

        } catch( error ) { LogService.log( `[Render(S)] Zone not rendered: ${zoneId}`, 'info' ) }
    }


    public component( componentKey: string, zoneHost: ZoneDirective, fromContainer = false ) {
        try {

            const componentsAvailable = this.configurationService.configuration.components
            const baseComponent: any = this.getConfiguration( componentsAvailable, UtilityService.objectToCamelCase( componentsAvailable[ componentKey ] ) )

            if ( baseComponent.isCollapsable && !fromContainer ) {
                // My main component instance will be the Key component defined as collapsable_wrapper within the configuration
                const componentInstance = new BaseComponent( this.wrapperComponent.map[ baseComponent.collapsableWrapper ] )
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory( componentInstance.component )
                const viewContainerRef = zoneHost.viewContainerRef
                const componentRef = viewContainerRef.createComponent<IBaseComponent>( componentFactory )
                componentRef.instance.configuration = { ...baseComponent, componentKey }
            }
            else if ( this.componentMap.map[ baseComponent.type ] ) {
                const componentInstance = new BaseComponent( this.componentMap.map[ baseComponent.type ] )
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory( componentInstance.component )
                const viewContainerRef = zoneHost.viewContainerRef
                const componentRef = viewContainerRef.createComponent<IBaseComponent>( componentFactory )
                componentRef.instance.configuration = this.getConfiguration( componentsAvailable, baseComponent )
            } else {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory( BlankComponent )
                const viewContainerRef = zoneHost.viewContainerRef
                const componentRef = viewContainerRef.createComponent<BlankComponent>( componentFactory )
                componentRef.instance.message = `The component ${ baseComponent.type } is currently not supported`
            }

        } catch( error ) { LogService.log( `[Render(S)] Component not available: ${componentKey}`, 'info' ) }
    }

    /**
     * "Updated component" refers to components that can be configured in the configuration tool.  For components to be configurable
     * in the new tools, it must meet the following:
     * --- No client configurations for this component use the "extends" functionality.
     * --- Client config attributes must be in the same case that the componet uses in the ts file (usually camel case) and no longer
     *     uses the UtilityService.objectToCamelCase function to convert e.g. some_attribute to someAttribute.
     * TODO: need to add support for isCollapsable components
     */
    public updatedComponent(configKey: string, zoneHost: ZoneDirective): void {
        const config = this.configurationService.configuration.components[configKey]
        const component = this.componentMap.map[config.type]
        if (!component) {
            console.error(`Could not find component for configKey [${configKey}]`)
            return
        }
        const componentInstance = new BaseComponent(component)
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentInstance.component)
        const componentRef = zoneHost.viewContainerRef.createComponent<IBaseComponent>(componentFactory)
        componentRef.instance.configuration = config
    }

    private getConfiguration( componentsAvailable, baseComponent ) {
        if ( baseComponent.extends && componentsAvailable[ baseComponent.extends ] ) {
            return {
                ...UtilityService.objectToCamelCase( componentsAvailable[ baseComponent.extends ] ),
                ...baseComponent
            }
        }
        return baseComponent
    }
}
