import { Injectable } from '@angular/core'
import cloneDeepWith from 'lodash-es/cloneDeepWith'
import get from 'lodash-es/get'
import isDate from 'lodash-es/isDate'
import isEqual from 'lodash-es/isEqual'
import isObject from 'lodash-es/isObject'
import isObjectLike from 'lodash-es/isObjectLike'
import transform from 'lodash-es/transform'
import camelCase from 'lodash-es/camelCase'

// Interfaces
import { IApiFilterShape, IFilterDao } from '../interfaces'

// Services
import { LogService } from './log.service'

const utilityDebug = LogService.startDebug('utility', 'Utility(S)')

const domainClientMap = {
    'usedequip.bestline.com': 'best',
    'used.cisco-equipment.com': 'cis',
    'used.cooperequipment.ca': 'coop',
    'used.eqdepot.com': 'eqd',
    'used.hercrentals.com': 'herc',
    'equipment.kiewit.com': 'kie',
    'used.siteprorentals.com': 'stp',
    'used.sunstateequip.com': 'sec',
    'equipment.bd-er.com': 'bder',
    'equipment.renterg.com': 'emrg',
}

@Injectable()
export class UtilityService {

    static parseObjectTemplate(objectTemplate: any, replacements: Array<{ find: string, replace: string }>): any {
        const regExList = replacements.map(templateSet => new RegExp('{{' + templateSet.find + '}}', 'g'))

        return cloneDeepWith(objectTemplate, val => {

            if (typeof val === 'string') {
                replacements.forEach((templateSet, i) => {
                    val = val.replace(regExList[i], templateSet.replace)
                })
            }

            return isObjectLike(val) ? undefined : val
        })
    }

    static deepDiff(object1, object2): Array<string> {
        if (!isObject(object1) || isDate(object1)) {
            // special case dates
            return object1
        }
        // @ts-ignore: Error on the transform type
        return transform(object1, (result, value, key) => {
            const obj2Val = get(object2, key)
            if (!isEqual(value, obj2Val)) {

                if (this.isNullBlankOrUndefined(value) && this.isNullBlankOrUndefined(obj2Val)) {
                    return
                }
                // @ts-ignore: Error on result
                result[key] = isObject(value) && isObject(obj2Val)
                    ? this.deepDiff(value, obj2Val)
                    : value
            }
        })
    }

    static isNullBlankOrUndefined(o, exclude: Array<undefined | null> = []): boolean {
        return (
            (!exclude.includes(undefined) && o === undefined)
            || (!exclude.includes(null) && o == null)
            || (typeof o === 'string' && o === '')
        )
    }

    static isIE(): boolean {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
    }

    static filtersToJsonLogic(filters: Array<IFilterDao>): IApiFilterShape {
        const req = {and: []}
        filters.forEach((f: IFilterDao) => {
            const fObj = req.and
            switch (f.type) {
                case 'boolean':
                    fObj.push({'===': [{var: f.field}, f.values[0]]})
                    break
                case 'exists':
                    fObj.push({'!=': [{var: f.field}, null]})
                    break
                case 'matches-multi':
                case 'matches-single':
                    fObj.push({in: [{var: f.field}, f.values]})
                    break
                case 'range':
                    if (f.values.hasOwnProperty(0) && f.values[0] !== undefined) {
                        fObj.push({'>=': [{var: f.field}, f.values[0]]})
                    }
                    if (f.values.hasOwnProperty(1) && f.values[1] !== undefined) {
                        fObj.push({'<=': [{var: f.field}, f.values[1]]})
                    }
                    break
                case 'search':
                    fObj.push({in: [{var: f.field}, f.values[0]]})
                    break
                default:
            }

            if (!Object.keys(fObj).length) {
                return
            }
        })
        return req.and.length > 0 ? req : {}
    }

    static stripTags(str: string): string {
        if (typeof str !== 'string' || UtilityService.isNullBlankOrUndefined(str)) {
            return str
        }

        // Convert <br /> to \n
        str = str.replace(/<br ?[\/]?>/g, '\n')

        // Strip all other tags
        str = str.replace(/<[^>]*>/g, '')

        // convert \n to <br />
        str = str.replace(/\n/g, '<br />')

        return str
    }

    static truncateClean(text: string, maxLength: number): string {
        const words: Array<string> = text.split(' ')
        const previewArr: Array<string> = [words[0]]
        let newLen: number = words[0].length
        for (let i = 1; i < words.length; i++) {
            const word = words[i]
            const totalLen = newLen + 1 + word.length
            if (totalLen <= maxLength) {
                previewArr.push(word)
            } else {
                return previewArr.join(' ')
            }
            newLen = previewArr.join(' ').length
        }
        return previewArr.join(' ')
    }

    static displayTimeRemaining(time: number, segments: number = 1): [string, string] | Array<[string, string]> {
        const now = Math.floor((new Date()).getTime() / 1000)
        const remainder = time - now
        if (remainder < 0) {
            return ['Expired.', null]
        }

        const minute = 60
        const hour = 60 * minute
        const day = 24 * hour

        const days = Math.floor(remainder / day)
        const hours = Math.floor((remainder - (days * day)) / hour)
        const minutes = Math.floor((remainder - (days * day) - (hours * hour)) / minute)
        const seconds = Math.floor(remainder - (days * day) - (hours * hour) - (minutes * minute))

        const displaySegments: Array<[string, string]> = []

        if (days > 0) {
            displaySegments.push([days.toString(), 'day' + (days !== 1 ? 's' : '')])
        }
        if (hours > 0) {
            displaySegments.push([hours.toString(), 'hour' + (hours !== 1 ? 's' : '')])
        }
        if (minutes > 0) {
            displaySegments.push([minutes.toString(), 'minute' + (minutes !== 1 ? 's' : '')])
        }
        if (seconds > 0) {
            displaySegments.push([seconds.toString(), 'second' + (seconds !== 1 ? 's' : '')])
        }

        return displaySegments.slice(0, segments)
    }

    static stripSlash(url) {
        if (url.substr(url.length - 1, 1) === '/') {
            url = url.substr(0, url.length - 1)
        }
        return url
    }

    static objectToCamelCase(obj: any) {
        if (!obj) {
            return
        }
        const newObject = {}
        Object.keys(obj).forEach(objectKey => newObject[camelCase(objectKey)] = obj[objectKey])
        return newObject
    }

    /**
     * Replaces the template mostaches by the value of the key
     * @param template template with the raw format
     * @param data object containing all the properties that might be replaced on the template
     * @example replaceTemplate('It is  a {{make}} {{model}}', {make: 'Volvo', model: 'XC60'}) => 'It is a Volvo XC60'
     */
    static replaceTemplate(template: string, data: any) {
        return template
            .split(/[{{(*$)}}]+/)
            .map(item => data.hasOwnProperty(item) ? data[item] : item)
            .join('')
    }

    static getImgAltText(item: any): string {
        if ('subcategory_id' in item) {
            return 'Subcategory - ' + item.sub_category
        } else if ('category_id' in item) {
            return 'Category - ' + item.category
        } else if ('equipment_number' in item) {
            return `Detail Photo - ${
                [item.year || '', item.make || '', item.model || '']
                    .join(' ')
                    .replace(/\s{2,}/g, ' ')
            }`
        } else if ('details' in item) {
            return item.details
        }

        return ''
    }

    static populateConfigDefaults(configuration: any, defaults: any): any {
        if (!configuration) {
            configuration = {}
        }
        Object.keys(defaults).forEach(key => {
            if (!configuration.hasOwnProperty(key) || configuration[key] === undefined) {
                configuration[key] = defaults[key]
            }
        })
        return configuration
    }

    static getClientFromUrl(): string {
        const pieces = window.location.host.split('-catalog') // splits on something like xyz-catalog.develop...
        if (pieces.length > 1) {
            return pieces[0]
        }
        if (window.location.host in domainClientMap) {
            return domainClientMap[window.location.host]
        }
        console.error('Unable to determine client from url')
        return null
    }

    /**
     * Returns a unique key based on the pattern provided.
     * @param [pattern] String
     */
    static getUUID(pattern: string = '******-******-******-******'): string {
        return pattern.replace(/(\*)/g, c => {
        const rnd = Math.random() * 16 | 0
        return (c === '*' ? rnd : (rnd & 0x3 | 0x8)).toString(16)
        })
    }
}

