<div class="searchbox-container">

    <fa-icon *ngIf="!loading" [icon]="['fas', 'search']"></fa-icon>
    <fa-icon *ngIf="loading" [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon>

    <input #autoCompleteInput
            ac-keydown
            type="text"
            [placeholder]="configuration.placeholder"
            [formControl]="formControl"
            [matAutocomplete]="auto"
            autocomplete="off"
            (keyup.escape)="autoCompleteInput.blur(); clearControl()"/>

    <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete"
                        (optionSelected)="onSelect($event)"
                        [displayWith]="displayValue"
                        class="search-autocomplete">
        <ng-container *ngIf="options && options.length">
            <mat-optgroup *ngFor="let group of options" [label]="getGroupLabel(group[0].key) + ':'">
                <mat-option *ngFor="let option of group; trackBy: tracker" [value]="option" class="ras-searchbox-item">
                    <div class="option-value">
                        <fa-icon *ngIf="configuration.icons[ option.key ]" [icon]="['fas', configuration.icons[ option.key ]]"></fa-icon>
                        <span class="result-field-value" [innerHTML]="option.label"></span>
                    </div>
                    <span class="option-count" *ngIf="option.count">{{ option.count }}</span>
                </mat-option>
            </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="options && !options.length && searchText">
            <mat-option disabled [innerHTML]="configuration.noResults"></mat-option>
        </ng-container>


    </mat-autocomplete>
    <div ras-inject-content *ngIf="configuration.inlineStyles" [styles]="configuration.inlineStyles"></div>
</div>
