import { Injectable } from '@angular/core'

@Injectable()
export class SchemaAppService {
  init(config: any): void {
    const { accountId, outputCache } = config

    const node1 = document.createElement('script')
    node1.innerHTML = `window.schema_highlighter={accountId: "${accountId}", outputCache: ${outputCache}}`
    document.getElementsByTagName('head')[0].appendChild(node1)

    const node2 = document.createElement('script')
    node2.src = 'https://cdn.schemaapp.com/javascript/highlight.js'
    node2.async = true
    document.getElementsByTagName('head')[0].appendChild(node2)
  }
}
