import { HttpClient } from '@angular/common/http'
import { ChangeDetectionStrategy, ChangeDetectorRef, Compiler, Component, ComponentRef, Inject, Injector, Input, NgModule, NgModuleRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'
import { ConfigurationService } from 'src/app/services/configuration.service'
import { UtilityService } from 'src/app/services/utility.service'
import { environment } from 'src/environments/environment'
import { IExternalConfiguration } from './external.interface'
import { DOCUMENT } from '@angular/common'
import { declarations, imports } from 'src/app/module-configurations'

export const ExternalComponentDefaults: IExternalConfiguration = {
    type: 'external',
    display: true,
    id: '',
    includeCss: false
}

export const ExternalComponentDevOpts: IComponentDevOptions = {
    config: {
        ...ExternalComponentDefaults,
    },
}

@Component({
    selector: 'ras-external',
    template: `<div #vc></div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalComponent implements OnInit, OnDestroy {
    private $destroy = new Subject<void>()

    @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef

    @Input() configuration: IExternalConfiguration

    private cmpRef: ComponentRef<any>

    constructor(
        private compiler: Compiler,
        private injector: Injector,
        private moduleRef: NgModuleRef<any>,
        private http: HttpClient,
        private configurationService: ConfigurationService,
        private ref: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) {}

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, ExternalComponentDefaults)
        const { id, includeCss } = this.configuration
        if (!id) {
            console.error(`ID is required for external component.`)
            return
        }
        const clientCode = this.configurationService.getConfig('catalog.client_code', '').toLowerCase()
        const timestamp = this.configurationService.getConfig('catalog.timestamp', '0')
        const headerPath = `${environment.data.assetsUrl}${clientCode}/components/${id}/`
        this.http.get(`${headerPath}${id}.html?v=${timestamp}`, {
            responseType: 'text',
        }).pipe(
            takeUntil(this.$destroy),
        ).subscribe((template) => {
            const comp = Component({
                template,
            })(class {
                data: any
                ngOnInit() {}
            })

            const mod = NgModule({
                declarations: [comp].concat(declarations),
                imports: imports,
            })(class {})

            this.compiler.compileModuleAndAllComponentsAsync(mod).then((factories) => {
                const f = factories.componentFactories[0]
                this.cmpRef = f.create(this.injector, [], null, this.moduleRef)
                this.cmpRef.instance.name = `dynamic-component-${id}`
                this.vc.insert(this.cmpRef.hostView)
            })
            this.ref.markForCheck()
        })
        if (includeCss) {
            const link: HTMLLinkElement = this.document.createElement('link')
            link.setAttribute('rel', 'stylesheet')
            link.setAttribute('href', `${headerPath}styles.css?v=${timestamp}`)
            this.document.head.appendChild(link)
        }
    }

    ngOnDestroy(): void {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
        this.$destroy?.next()
        this.$destroy?.complete()
    }
}
