import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { environment } from 'src/environments/environment'

// UI/Theme
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatDividerModule } from '@angular/material/divider'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatRippleModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatMenuModule } from '@angular/material/menu'

// Services
import { StorageService } from './services/storage.service'
import { ResolvedStore } from './services/resolved.store'
import { ErrorService } from './services/error.service'
import { ProposalService } from './services/proposal.service'
import { SeoService } from './services/seo.service'
import { AnalyticsService } from './services/analytics.service'
import { FaviconService } from './services/favicon.service'
import { TagManagerService } from './services/tag-manager.service'
import { ThemeService } from './services/theme.service'
import { ReCaptchaService } from './services/recaptcha.service'
import { SchemaAppService } from './services/schema-app.service'

// Components
import { InjectContentComponent } from './components/page/inject-content/inject-content.component'
import { HeaderComponent } from './components/page/header/header.component'
import { HeroCarouselComponent } from './components/page/hero-carousel/hero-carousel.component'
import { CollapsableComponent } from './components/filters/components/collapsable/collapsable.component'
import { ListComponent } from './components/page/list/list.component'
import { EquipmentCatSubcatComponent } from './components/page/equipment-cat-subcat/equipment-cat-subcat.component'
import { ChipsComponent } from './components/filters/components/chips/chips.component'
import { FilterListComponent } from './components/filters/components/list/filter-list.component'
import { FilterDrilldownComponent } from './components/filters/components/drilldown/filter-drilldown.component'
import { FilterSelectMultiComponent } from './components/filters/components/select-multi/filter-select-multi.component'
import { BaseComponentMapping } from './components/page/core/base-component.mapping'
import { SeparatorComponent } from './components/filters/components/separator/separator.component'
import { RangeSliderFilterComponent } from './components/filters/components/range-slider/range-slider.component'
import { RangePriceFilterComponent } from './components/filters/components/range-price/range-price.component'
import { SummaryComponent } from './components/page/summary/summary.component'
import { ListDetailsComponent } from './components/page/list-details/list-details.component'
import { ListDetailsItemComponent } from './components/page/list-details/list-details-item.component'
import { OneCallComponent } from './components/page/one-call/one-call.component'
import { BreadcrumbComponent } from './components/page/breadcrumb/breadcrumb.component'
import { BrandSectionComponent } from './components/page/brand-section/brand-section.component'
import { SaveFiltersListComponent } from './components/filters/components/save-filters-list/save-filters-list.component'
import { DetailCarouselComponent } from './components/page/detail-carousel/detail-carousel.component'
import { FormComponent } from './components/page/form/form.component'
import { ShareComponent } from './components/page/share/share.component'
import { SearchComponent } from './components/filters/components/search/search.component'
import { SearchAdvancedComponent } from './components/filters/components/search-advanced/search.advanced.component'
import { SearchAdvancedMobileComponent } from './components/filters/components/search-advanced/search.advanced.mobile.component'
import { RenderElementComponent } from './components/page/render-element/render-element.component'
import { RenderContentComponent } from './components/page/render-content/render-content.component'
import { RangeLocationComponent } from './components/filters/components/range-location/range-location.component'
import { FilterLocationComponent } from './components/filters/components/location/location-filter.component'
import { RangeMonthlyComponent } from './components/filters/components/range-monthly/range-monthly.component'
import { FeaturedChipsComponent } from './components/page/featured-chips/featured-chips.component'
import { BadgeComponent } from './components/page/badge/badge.component'
import { BadgesContainerComponent } from './components/page/badges-container/badges-container.component'
import { BannerComponent } from './components/page/banner/banner.component'
import { ListItemComponent } from './components/page/list-item/list-item.component'
import { ItemsCarouselComponent } from './components/page/items-carousel/items-carousel.component'
import { HappyFoxChatComponent } from './components/page/happy-fox-chat/happy-fox-chat.component'
import { CurrencySelectorComponent } from './components/page/currency-selector/currency-selector.component'
import { CurrencySelectorV2Component } from './components/page/currency-selector-v2/currency-selector.component'
import { FilterMinMaxComponent } from './components/filters/components/min-max/min-max.component'
import { BurgerMenuComponent } from './components/page/burger-menu/burger-menu.component'
import { FinanceInfoBoxComponent } from './components/page/finance-info-box/finance-info-box.component'
import { ModalComponent } from './components/page/modal/modal.component'
import { ModalLinkComponent } from './components/page/modal-link/modal-link.component'

// Pipes
import { MoneyPipe } from './pipes/money.pipe'
import { NumberPipe } from './pipes/number.pipe'
import { PercentPipe } from './pipes/percent.pipe'
import { PercentRoundedPipe } from './pipes/percent-rounded.pipe'
import { RasDatePipe } from './pipes/date.pipe'
import { RasBooleanPipe } from './pipes/boolean.pipe'
import { RasSearchHighlightPipe } from './pipes/search-highlight.pipe'
import { EllipsisPipe } from './pipes/elipsis.pipe'
import { RasMeterPipe } from './pipes/meter.pipe'
import { RasTemplatePipe } from './pipes/ras-template.pipe'
import { RasPhonePipe } from './pipes/ras-phone.pipe'
import { DatePastPipe } from './pipes/datePast.pipe'
import { SafePipe } from './pipes/safe.pipe'
import { RasCurrencyPipe } from './pipes/ras-currency.pipe'
import { HasPropPipe } from './pipes/hasProp.pipe'

// Directives
import { AutocompleteDirective } from './directives/autocomplete-keypress/autocomplete-keypress.directive'
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive'
import { ZoneDirective } from './components/page/core/zone.directive'
import { ImageDirective } from './directives/image/image.directive'
import { ProposalDirective } from './directives/proposal/proposal.directive'
import { LazyBackgroundDirective } from './directives/lazy-background/lazy-background.directive'
import { MatTabsModule } from '@angular/material/tabs'

// Headers/footers
import { HeaderBasic1Component } from './components/headers-footers/header-basic-1/header-basic-1.component'
import { FooterBasic1Component } from './components/headers-footers/footer-basic-1/footer-basic-1.component'

// Config
import { HeaderBasic1ConfigComponent } from './components/tools/config/header-basic-1/config.header-basic-1'
import { FormConfigComponent } from './components/tools/config/form/config.form'
import { CurrencySelectorConfigComponent } from './components/tools/config/currency-selector/config.currency-selector'

// Config Tools
import { ConfigControlsComponent } from './components/tools/controls/config-controls.component'
import { CommentToolComponent } from './components/tools/controls/comment/comment'
import { BooleanSelectorToolComponent } from './components/tools/controls/boolean-selector/boolean-selector'
import { TextInputToolComponent } from './components/tools/controls/text-input/text-input'
import { MenuBuilderToolComponent } from './components/tools/controls/menu-builder/menu-builder'
import { ColorPickerToolComponent } from './components/tools/controls/color-picker/color-picker'
import { HelpToolComponent } from './components/tools/controls/help/help'
import { CurrencyOptionsToolComponent } from './components/tools/controls/currency-options/currency-options'
import { FooterCustom1Component } from './components/headers-footers/footer-custom-1/footer-custom-1.component'
import { IframeComponent } from './components/page/iframe/iframe.component'

export const components: Array<any> = [
    // Directives
    AutocompleteDirective,
    ClickOutsideDirective,
    ZoneDirective,
    ImageDirective,
    ProposalDirective,
    LazyBackgroundDirective,

    // Page Components
    InjectContentComponent,
    HeaderComponent,
    HeroCarouselComponent,
    ListComponent,
    EquipmentCatSubcatComponent,
    ListDetailsComponent,
    ListItemComponent,
    ListDetailsItemComponent,
    SummaryComponent,
    OneCallComponent,
    IframeComponent,
    BreadcrumbComponent,
    BrandSectionComponent,
    DetailCarouselComponent,
    FormComponent,
    ShareComponent,
    SearchComponent,
    SearchAdvancedComponent,
    SearchAdvancedMobileComponent,
    RenderElementComponent,
    RenderContentComponent,
    FeaturedChipsComponent,
    BadgeComponent,
    BadgesContainerComponent,
    BannerComponent,
    ItemsCarouselComponent,
    HappyFoxChatComponent,
    CurrencySelectorComponent,
    CurrencySelectorV2Component,
    BurgerMenuComponent,
    FinanceInfoBoxComponent,
    ModalComponent,
    ModalLinkComponent,

    // New Filters
    CollapsableComponent,
    SeparatorComponent,
    ChipsComponent,
    FilterListComponent,
    FilterDrilldownComponent,
    FilterSelectMultiComponent,
    RangeSliderFilterComponent,
    RangePriceFilterComponent,
    RangeMonthlyComponent,
    RangeLocationComponent,
    FilterLocationComponent,
    SaveFiltersListComponent,
    FilterMinMaxComponent,

    // Pipes
    MoneyPipe,
    NumberPipe,
    PercentPipe,
    PercentRoundedPipe,
    RasBooleanPipe,
    RasDatePipe,
    RasSearchHighlightPipe,
    EllipsisPipe,
    RasMeterPipe,
    RasTemplatePipe,
    RasPhonePipe,
    SafePipe,
    DatePastPipe,
    RasCurrencyPipe,
    HasPropPipe,

    // Header/Footer templates
    HeaderBasic1Component,
    FooterBasic1Component,
    FooterCustom1Component,

    // Config
    HeaderBasic1ConfigComponent,
    FormConfigComponent,
    CurrencySelectorConfigComponent,

    // Config Tools
    ConfigControlsComponent,
    CommentToolComponent,
    BooleanSelectorToolComponent,
    TextInputToolComponent,
    MenuBuilderToolComponent,
    ColorPickerToolComponent,
    HelpToolComponent,
    CurrencyOptionsToolComponent,
]

export const materialModules: Array<any> = [
    MatAutocompleteModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRippleModule,
    MatGridListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    DragDropModule,
    MatMenuModule,
]

export const declarations: Array<any> = [
    ...components,
]

export const imports: Array<any> = [
    // Modules
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,

    // Material UI
    ...materialModules,
]

export const exports: Array<any> = [
    // Modules
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,

    // Pipes
    MoneyPipe,
    NumberPipe,
    PercentPipe,
    PercentRoundedPipe,
    RasBooleanPipe,
    RasDatePipe,
    RasSearchHighlightPipe,
    DatePastPipe,
    DatePipe,
    RasCurrencyPipe,

    // Components
    ...components,
    ...materialModules
]

export const providers: Array<any> = [
    // Services
    ResolvedStore,
    ErrorService,
    StorageService,
    BaseComponentMapping,
    ProposalService,
    SeoService,
    AnalyticsService,
    TagManagerService,
    FaviconService,
    ThemeService,
    ReCaptchaService,
    SchemaAppService,

    // Pipes
    MoneyPipe,
    NumberPipe,
    PercentPipe,
    PercentRoundedPipe,
    RasBooleanPipe,
    RasDatePipe,
    RasSearchHighlightPipe,
    RasMeterPipe,
    DatePastPipe,
    DatePipe,
    CurrencyPipe,
    RasCurrencyPipe
]
