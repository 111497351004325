import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { StorageService } from './services/storage.service'

import { declarations, imports, exports, providers } from './module-configurations'
import { RenderService } from './components/page/core/render.service'
import { ZoneComponent } from './components/page/core/zone.component'

@NgModule({
    declarations: declarations.concat([
        ZoneComponent,
    ]),
    imports: imports,
    exports: exports.concat([
        ZoneComponent,
    ]),
    providers: providers.concat([
        RenderService,
    ]),
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoreModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks( far, fas, fab )
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                StorageService,
            ],
        }
    }
}
