import { Injectable } from '@angular/core'
import { LogService } from './log.service'
import { ConfigurationService } from '../services/configuration.service'

declare global {
  interface Window { grecaptcha: any }
}

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

    constructor(
        private configurationService: ConfigurationService,
    ) { }

  init() {
    LogService.log('ReCaptchaService.init()', 'info')
    this.loadReCaptcha()
  }

  private loadReCaptcha() {
    LogService.log('ReCaptchaService.loadReCaptcha()', 'info')
    const recaptchaKey = this.configurationService.getConfig( 'catalog.recaptcha_site_key', null )

    if (!recaptchaKey) {
        LogService.log('ReCaptchaService.loadReCaptcha() - No key found', 'warning')
      return
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
    script.async = true
    document.body.appendChild(script)
    // this adds an object "grecaptcha" to the global window object
  }

  execute(action: string): PromiseLike<string> {
    const recaptchaKey = this.configurationService.getConfig( 'catalog.recaptcha_site_key', null )
    action = action.replace(/-+/g, '/')
    return window.grecaptcha.execute(recaptchaKey, { action })
  }
}