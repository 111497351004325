import '@angular/compiler'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as Sentry from '@sentry/angular'
import { register } from 'swiper/element/bundle'

register()

if (environment.production || environment.buildForProd) {
    enableProdMode()
}

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import './zone-flags'
import 'zone.js'

Sentry.init({
    dsn: 'https://33978be2792a4701b5679a4effe8b9b9@o1157537.ingest.sentry.io/6534549' ,
    environment: environment.releaseStage,
    integrations: [
        Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: [
        // enable only if specifically testing
        // TODO: environment variables + traceSampleRate switch
        // 'localhost',
        'https://*.beta.rouseservices.com/api',
        'https://*.develop.rouseservices.com/api',
        'https://*.stage.rouseservices.com/api',
        'https://*.rouseservices.com/api',
    ],
    beforeSend: (event, hint) => {
        const error = hint.originalException as Error
        if (error && error.message) {
            if (
                error.message.includes('performance is not defined') ||
                error.message.includes(`Cannot read property 'then' of undefined`) ||
                error.message.includes(`(invoca-latest)`)
            ) {
                return null
            }
        }
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: environment.traceSampleRate,
})

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .catch(err => console.error(err))
